import ContentContainer from '../components/ui/ContentContainer'
import { ReactComponent as Questioning } from '../assets/logo/questioning.svg'
import { Box, Typography } from '@mui/material'
import Link from '../components/ui/Link'
import Breadcrumbs from '../components/Breadcrumbs'
import useTranslate from '../utils/translations/useTranslate'
import { muiTheme } from '../theme'

const Page404 = () => {
  const { t } = useTranslate()

  return (
    <div style={{ width: '100%' }}>
      <ContentContainer background={muiTheme.palette.background.paper}>
        <Breadcrumbs />
        <Box
          sx={{
            minHeight: 450,
            pt: 1,
            pb: 2,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h4" gutterBottom>
            {t('pages.page404.description')}
          </Typography>
          <Link href="/contacts" sx={{ mb: 6 }}>
            {t('pages.page404.link')}
          </Link>
          <Questioning width="125" />
        </Box>
      </ContentContainer>
    </div>
  )
}

export default Page404
