import { ReactComponent as HoldingSlovakMap } from '../../../assets/logo/holdingSlovakMap.svg'
import { ReactComponent as WithBook } from '../../../assets/logo/withBook.svg'
import { VOLUNTEERING_SLOVAKIA_DIV_ID } from '../VolunteerSlovakia'
import { EDUCATION_DIV_ID } from '../Educational'
import { EventType as EventTypeContainer } from '../EventTypes'
import TextInput from '../../ui/TextInput'
import { Select } from '../../ui/Select'
import DatePicker from '../../ui/DatePicker'
import useFetchData from '../../../hooks/useFetchData'
import { Country, EventType } from '../../../types/codetables'
import LoadingOverlay from '../../LoadingOverlay'
import useTranslate from '../../../utils/translations/useTranslate'

type Props = {
  searchType: 'volunteer' | 'education'
  text: string
  setText: (text: string) => void
  from: string
  setFrom: (from: string) => void
  to: string
  setTo: (to: string) => void
  countries: string[]
  setCountries: (countries: string[]) => void
  types: string[]
  setTypes: (types: string[]) => void
  age: string
  setAge: (age: string) => void
}

const SearchForm = ({
  searchType,
  text,
  setText,
  from,
  setFrom,
  to,
  setTo,
  countries,
  setCountries,
  types,
  setTypes,
  age,
  setAge,
}: Props) => {
  const { t } = useTranslate()
  const isVolunteerSearchType = searchType === 'volunteer'

  const {
    data: countriesData,
    isLoading: isLoadingCountries,
    isError: isErrorCountries,
  } = useFetchData<Country[]>('/v1/events/countries')

  const {
    data: eventTypesData,
    isLoading: isLoadingEventTypes,
    isError: isErrorEventTypes,
  } = useFetchData<EventType[]>('/v1/events/event-categories')

  if (isLoadingCountries || isLoadingEventTypes) {
    return <LoadingOverlay />
  }

  if (!countriesData || !eventTypesData || isErrorCountries || isErrorEventTypes) {
    return <>{t('errors.loading')}</>
  }

  return (
    <>
      <div style={{ pointerEvents: 'none', display: 'flex', justifyContent: 'center' }}>
        <EventTypeContainer
          targetId={isVolunteerSearchType ? VOLUNTEERING_SLOVAKIA_DIV_ID : EDUCATION_DIV_ID}
          img={isVolunteerSearchType ? <HoldingSlovakMap /> : <WithBook />}
          title={
            isVolunteerSearchType
              ? t('pages.events.eventTypes.volunteer')
              : t('pages.events.eventTypes.education1')
          }
          subtitle={
            isVolunteerSearchType
              ? t('pages.events.eventTypes.volunteerSlovakia')
              : t('pages.events.eventTypes.education2')
          }
          withButton
        />
      </div>

      <TextInput
        type="input"
        label={t('pages.events.search.query')}
        onChange={(e) => setText((e.target as HTMLInputElement).value)}
        value={text}
      />
      <DatePicker
        label={t('pages.events.search.from')}
        onChange={(e) => setFrom(e as string)}
        value={from}
      />
      <DatePicker
        label={t('pages.events.search.to')}
        onChange={(e) => setTo(e as string)}
        value={to}
      />
      {!isVolunteerSearchType && (
        <Select
          onChange={(e) => setCountries(e.target.value as string[])}
          options={countriesData.map((country) => ({
            id: country.id,
            name: country.name,
          }))}
          value={countries}
        >
          {t('pages.events.search.countries')}
        </Select>
      )}

      <Select
        onChange={(e) => setTypes(e.target.value as string[])}
        options={eventTypesData.map((type) => ({
          id: type.id,
          name: type.abbr,
        }))}
        value={types}
      >
        {t('pages.events.search.eventTypes')}
      </Select>
      <TextInput
        type="number"
        label={t('pages.events.search.age')}
        onChange={(e) => setAge((e.target as HTMLInputElement).value)}
        value={age}
      />
    </>
  )
}

export default SearchForm
