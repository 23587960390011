import { FormControl, FormHelperText, TextField } from '@mui/material'
import styled from 'styled-components'
import { muiTheme } from '../../theme'
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker'

type DateChangeHandler = (date: unknown, keyboardInputValue?: string | undefined) => void

type Props = {
  className?: string
  onChange: DateChangeHandler
  label?: string
  value?: string
  error?: string
  required?: boolean
}

const StyledTextField = styled(TextField)`
  & > .Mui-focused.MuiInputLabel-root {
    color: ${muiTheme.palette.secondary.main} !important;
    background: ${muiTheme.palette.background.paper};
  }

  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: ${muiTheme.palette.secondary.main};
    }
  }
`

const StyledFormControl = styled(FormControl)`
  width: 100%;
`

const DatePicker = ({ className, onChange, label, value, error, required }: Props) => {
  return (
    <StyledFormControl error={Boolean(error)}>
      <MuiDatePicker
        className={className}
        label={
          <>
            {label}
            {required ? ' *' : null}
          </>
        }
        value={value || null}
        onChange={onChange}
        renderInput={(params) => <StyledTextField {...params} />}
      />
      <FormHelperText>{error}</FormHelperText>
    </StyledFormControl>
  )
}

export default DatePicker
