import { useLocation } from 'react-router-dom'
import Link from './ui/Link'
import { Breadcrumbs as MUIBreadcrumbs, Typography, Box } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import useTranslate from '../utils/translations/useTranslate'

const PATH_TO_TITLE_KEY: Record<string, string> = {
  '': 'breadcrumbs.Home',
  '/contacts': 'breadcrumbs.Contacts',
  '/events': 'breadcrumbs.Events',
  '/events/search': 'breadcrumbs.EventsSearch',
  '/events/search/volunteering-slovakia': 'breadcrumbs.EventsSearchVolunteeringSlovakia',
  '/events/search/volunteering-abroad': 'breadcrumbs.EventsSearchVolunteeringAbroad',
  '/events/search/education': 'breadcrumbs.EventsSearchEducation',
  '/events/:id': 'breadcrumbs.EventDetail',
  '/about-us': 'breadcrumbs.AboutUs',
  '/about-us/our-team': 'breadcrumbs.AboutUsOurTeam',
  '/about-us/partners': 'breadcrumbs.AboutUsPartners',
  '/about-us/history': 'breadcrumbs.AboutUsHistory',
  '/about-us/organization': 'breadcrumbs.AboutUsOrganization',
  '/media/blog': 'breadcrumbs.MediaBlogs',
  '/media/blog/:id': 'breadcrumbs.MediaBlogDetail',
  '/media/press-releases': 'breadcrumbs.MediaPressReleases',
  '/media/press-releases/:id': 'breadcrumbs.MediaPressReleaseDetail',
  '/media/publications': 'breadcrumbs.MediaPublications',
  '/media/publications/:id': 'breadcrumbs.MediaPublicationDetail',
  '/media/annual-reports': 'breadcrumbs.MediaAnnualReports',
  '/media/annual-reports/:id': 'breadcrumbs.MediaAnnualReportDetail',
  '/media/logos': 'breadcrumbs.MediaLogos',
  '/frequently-asked-questions': 'breadcrumbs.FrequentlyAskedQuestions',
  '/my-account': 'breadcrumbs.MyAccount',
  '/my-bag': 'breadcrumbs.MyBag',
  '/help-us': 'breadcrumbs.HelpUs',
  '/help-us/dobromat': 'breadcrumbs.HelpUsDobromat',
  '/help-us/2-percenta': 'breadcrumbs.HelpUs2Percenta',
}

const getConfig = (pathname: string) => {
  const breadcrumbPaths = pathname.split('/')

  let pathPart = ''

  return breadcrumbPaths.map((path) => {
    if (path) {
      pathPart = pathPart.concat('/').concat(path)
    }

    return { titleKey: PATH_TO_TITLE_KEY[pathPart], path: pathPart }
  })
}

const Breadcrumbs = () => {
  const { pathname } = useLocation()
  const breadcrumbs = getConfig(pathname)
  const { t } = useTranslate()

  return (
    <Box sx={{ paddingY: 1 }}>
      <MUIBreadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        {breadcrumbs.map(({ titleKey, path }, index) => {
          if (!titleKey) {
            return null
          }

          if (index === breadcrumbs.length - 1) {
            return (
              <Typography key={index} color="text.primary">
                {t(titleKey)}
              </Typography>
            )
          }

          return (
            <Link key={index} color="inherit" href={path || '/'}>
              {t(titleKey)}
            </Link>
          )
        })}
      </MUIBreadcrumbs>
    </Box>
  )
}

export default Breadcrumbs
