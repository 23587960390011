import { Box } from '@mui/material'
import Breadcrumbs from '../components/Breadcrumbs'
import EventsSearchList from '../components/events/search/EventsSearchList'
import PlatoSearch from '../components/events/search/PlatoSearch'
import ContentContainer from '../components/ui/ContentContainer'
import { muiTheme } from '../theme'

type Props = {
  type: 'volunteer' | 'education'
  location?: 'local' | 'abroad'
}

const EventsSearch = ({ type, location }: Props) => {
  if (type === 'volunteer' && location === 'abroad') {
    return (
      <div style={{ width: '100%' }}>
        <ContentContainer background={muiTheme.palette.background.paper}>
          <Breadcrumbs />
          <PlatoSearch />
        </ContentContainer>
      </div>
    )
  }

  return (
    <div style={{ width: '100%' }}>
      <ContentContainer background={muiTheme.palette.background.paper}>
        <Breadcrumbs />
        <Box sx={{ minHeight: 450, paddingTop: 4, marginBottom: 8 }}>
          <EventsSearchList type={type} />
        </Box>
      </ContentContainer>
    </div>
  )
}

export default EventsSearch
