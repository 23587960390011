import styled from 'styled-components'
import { useCallback, useState } from 'react'

import { ReactComponent as WithBag } from '../../../assets/logo/withBag.svg'
import { VOLUNTEERING_ABROAD_DIV_ID } from '../VolunteerAbroad'
import { EventType } from '../EventTypes'
import TextInput from '../../ui/TextInput'
import { Select } from '../../ui/Select'
import Button from '../../ui/Button'
import DatePicker from '../../ui/DatePicker'
import useTranslate from '../../../utils/translations/useTranslate'
import { Typography } from '@mui/material'

const Wrapper = styled.div`
  padding: 40px 0 80px 0;
  width: 100%;
  display: flex;
  min-height: 600px;
`

const MOCK_COUNTRIES = [
  { id: '1', name: 'Slovakia' },
  { id: '2', name: 'Czech republic' },
  { id: '3', name: 'UK' },
]
const MOCK_EVENT_TYPES = ['EDU', 'ENVI', 'RENO']

const PlatoSearch = () => {
  const { t } = useTranslate()
  const [text, setText] = useState<string>('')
  const [from, setFrom] = useState<string>('')
  const [to, setTo] = useState<string>('')
  const [countries, setCountries] = useState<string[]>([])
  const [types, setTypes] = useState<string[]>([])
  const [age, setAge] = useState<string>('')

  const handleSearchPlato = useCallback(() => {
    const searchUrl = new URL('https://frontend.workcamp-plato.org/searchresult.352.aspx')
    searchUrl.searchParams.append('code', text)
    searchUrl.searchParams.append('startDate', from)
    searchUrl.searchParams.append('endDate', to)
    searchUrl.searchParams.append('age', age)
    searchUrl.searchParams.append('platoorgid', process.env.REACT_APP_PLATO_ORG_ID || '')

    const appendedArrayParams = `&countries=${countries.join(',')}&types=${types.join(',')}`

    window.open(`${searchUrl.href}${appendedArrayParams}`, '_blank')
  }, [text, from, to, countries, types, age])

  return (
    <Wrapper>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h4">{t('pages.events.searchPlato.title')}</Typography>
        <Typography color="secondary" sx={{ mb: 4 }} gutterBottom>
          {t('pages.events.searchPlato.description')}
        </Typography>

        <div style={{ pointerEvents: 'none', alignSelf: 'flex-start', marginBottom: 10 }}>
          <EventType
            targetId={VOLUNTEERING_ABROAD_DIV_ID}
            img={<WithBag />}
            title={t('pages.events.eventTypes.volunteer')}
            subtitle={t('pages.events.eventTypes.volunteerAbroad')}
            withButton
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            gap: 10,
          }}
        >
          <TextInput
            type="input"
            label={t('pages.events.searchPlato.code')}
            onChange={(e) => setText((e.target as HTMLInputElement).value)}
            value={text}
          />
          <DatePicker
            label={t('pages.events.searchPlato.from')}
            onChange={(e) => setFrom(e as string)}
            value={from}
          />
          <DatePicker
            label={t('pages.events.searchPlato.to')}
            onChange={(e) => setTo(e as string)}
            value={to}
          />
          <Select
            multiple
            onChange={(e) => setCountries(e.target.value as string[])}
            options={MOCK_COUNTRIES.map((country) => ({
              id: country.id,
              name: country.name,
            }))}
            value={countries}
          >
            {t('pages.events.searchPlato.countries')}
          </Select>
          <Select
            multiple
            onChange={(e) => setTypes(e.target.value as string[])}
            options={MOCK_EVENT_TYPES.map((type) => ({
              id: type,
              name: type,
            }))}
            value={types}
          >
            {t('pages.events.searchPlato.eventTypes')}
          </Select>
          <TextInput
            type="number"
            label={t('pages.events.searchPlato.age')}
            onChange={(e) => setAge((e.target as HTMLInputElement).value)}
          />
        </div>
        <br />
        <Button onClick={handleSearchPlato}>{t('pages.events.searchPlato.submit')}</Button>
      </div>
    </Wrapper>
  )
}

export default PlatoSearch
