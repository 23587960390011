import styled from 'styled-components'
import { ReactComponent as HoldingSlovakMap } from '../../assets/logo/holdingSlovakMap.svg'
import { ReactComponent as WithBag } from '../../assets/logo/withBag.svg'
import { ReactComponent as WithBook } from '../../assets/logo/withBook.svg'
import { ReactComponent as Questioning } from '../../assets/logo/questioning.svg'
import { VOLUNTEERING_SLOVAKIA_DIV_ID } from './VolunteerSlovakia'
import { EDUCATION_DIV_ID } from './Educational'
import { DONT_KNOW_DIV_ID } from './Unknown'
import { VOLUNTEERING_ABROAD_DIV_ID } from './VolunteerAbroad'
import Button from '../ui/Button'
import useTranslate from '../../utils/translations/useTranslate'
import { Typography } from '@mui/material'

const Wrapper = styled.div`
  padding: 30px 0;
  width: 100%;
`

const EventTypeWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
`

type EventTypeProps = {
  targetId: string
  img: React.ReactNode
  title: string
  subtitle: string
  searchLink?: string
  withButton?: boolean
}

const StyledEventType = styled.a`
  text-decoration: initial;
  color: initial;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }
`

export const EventType = ({
  targetId,
  img,
  title,
  subtitle,
  searchLink,
  withButton = false,
}: EventTypeProps) => {
  const { t } = useTranslate()

  return (
    <div>
      <StyledEventType href={`#${targetId}`}>
        <div>{img}</div>
        <div style={{ alignItems: 'flex-start', marginTop: 8 }}>
          <Typography>{title || <br />}</Typography>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 'bold',
            }}
          >
            {subtitle}
          </Typography>
        </div>
      </StyledEventType>
      <div
        style={{
          pointerEvents: 'initial',
          alignSelf: 'flex-end',
          marginTop: 10,
          marginBottom: 18,
          marginLeft: 10,
        }}
      >
        {searchLink && <Button href={searchLink}>{t('pages.events.eventTypes.searchLink')}</Button>}
        {withButton && (
          <Button color="info" href="/events">
            {t('pages.events.eventTypes.changeLink')}
          </Button>
        )}
      </div>
    </div>
  )
}

const EventTypes = () => {
  const { t } = useTranslate()

  return (
    <Wrapper>
      <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
        {t('pages.events.eventTypes.title')}
      </Typography>
      <EventTypeWrapper>
        <EventType
          targetId={VOLUNTEERING_SLOVAKIA_DIV_ID}
          img={<HoldingSlovakMap />}
          title={t('pages.events.eventTypes.volunteer')}
          subtitle={t('pages.events.eventTypes.volunteerSlovakia')}
          searchLink={`/events/search/${VOLUNTEERING_SLOVAKIA_DIV_ID}`}
        />
        <EventType
          targetId={VOLUNTEERING_ABROAD_DIV_ID}
          img={<WithBag />}
          title={t('pages.events.eventTypes.volunteer')}
          subtitle={t('pages.events.eventTypes.volunteerAbroad')}
          searchLink={`/events/search/${VOLUNTEERING_ABROAD_DIV_ID}`}
        />
        <EventType
          targetId={EDUCATION_DIV_ID}
          img={<WithBook />}
          title={t('pages.events.eventTypes.education1')}
          subtitle={t('pages.events.eventTypes.education2')}
          searchLink={`/events/search/${EDUCATION_DIV_ID}`}
        />
        <EventType
          targetId={DONT_KNOW_DIV_ID}
          img={<Questioning width="74" height="102" />}
          title=""
          subtitle={t('pages.events.eventTypes.dontKnowYet')}
        />
      </EventTypeWrapper>
    </Wrapper>
  )
}

export default EventTypes
