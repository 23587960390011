import { MenuItem, MenuList } from '@mui/material'
import useTranslate from '../../utils/translations/useTranslate'

const Menu = () => {
  const { t } = useTranslate()

  return (
    <MenuList
      sx={{
        mx: 2,
        p: 2,
        // todo: Na malej obrazovke zobrazit hore, inak fixed position vpravo
        // position: 'fixed',
        boxShadow:
          '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);',
      }}
    >
      <MenuItem component="a" href="/media/blog">
        {t('media.menu.blog')}
      </MenuItem>
      <MenuItem component="a" href="/media/press-releases">
        {t('media.menu.pressReleases')}
      </MenuItem>
      <MenuItem component="a" href="/media/publications">
        {t('media.menu.publications')}
      </MenuItem>
      <MenuItem component="a" href="/media/annual-reports">
        {t('media.menu.annualReports')}
      </MenuItem>
      <MenuItem component="a" href="/media/logos">
        {t('media.menu.logos')}
      </MenuItem>
    </MenuList>
  )
}

export default Menu
