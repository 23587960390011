import { Chip, Grid, Typography } from '@mui/material'
import { useCallback } from 'react'
import styled from 'styled-components'
import useEventApplicationContext from '../../../contexts/useEventApplicationContext'
import useFetchData from '../../../hooks/useFetchData'
import { EventItem, EventDetail } from '../../../types/event'
import { formatDate } from '../../../utils/formatDate'
import Button from '../../ui/Button'
import Drawer from '../../ui/Drawer'
import Link from '../../ui/Link'
import LinkIcon from '@mui/icons-material/Link'
import LoadingOverlay from '../../LoadingOverlay'
import useTranslate from '../../../utils/translations/useTranslate'

type EventDetailModalProps = {
  eventId: string
  onClose: () => void
}

type EventDetailContentProps = {
  event?: EventDetail
  isLoading: boolean
  isError: boolean
}

const TitleWrapper = styled(Typography)`
  display: flex;
  align-items: center;
  gap: 5px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const EventDetailContent = ({ event, isLoading, isError }: EventDetailContentProps) => {
  const { isInBag, add, remove } = useEventApplicationContext()
  const { t } = useTranslate()

  const handleAddToBag = useCallback(() => {
    if (!event) {
      return
    }

    if (isInBag(event.eventType, event.id)) {
      remove(event.eventType, event.id)
    } else {
      add(event as EventItem)
    }
  }, [event, add, remove, isInBag])

  if (isLoading) {
    return <LoadingOverlay />
  }

  if (!event || isError) {
    return <>{t('errors.loading')}</>
  }

  return (
    <>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item sm={8}>
          <Typography color="text.secondary">{event.country}</Typography>
          <Typography variant="body2">
            {formatDate(event.from)} - {formatDate(event.to)}
          </Typography>
          <div>
            {event.types.map((type) => (
              <Chip key={type} label={type} variant="outlined" />
            ))}
          </div>
        </Grid>
        <Grid
          item
          sm={4}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            flexWrap: 'wrap',
          }}
        >
          <Link
            href={`/events/${event.id}`}
            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
            target="_blank"
          >
            {t('pages.events.detail.link')} <LinkIcon />
          </Link>
          <Button
            color={isInBag(event.eventType, event.id) ? 'error' : 'info'}
            onClick={handleAddToBag}
          >
            {isInBag(event.eventType, event.id) ? 'Odobrať z batoha' : 'Pridať do batoha'}
          </Button>
        </Grid>
      </Grid>

      {event.description.introduction && (
        <>
          <Typography variant="h6" gutterBottom>
            {t('pages.events.detail.introduction')}
          </Typography>
          <Typography
            component="div"
            dangerouslySetInnerHTML={{ __html: event.description.introduction }}
            gutterBottom
          />
        </>
      )}

      {event.description.type_of_work && (
        <>
          <Typography variant="h6" gutterBottom>
            {t('pages.events.detail.typeOfWork')}
          </Typography>
          <Typography
            component="div"
            dangerouslySetInnerHTML={{ __html: event.description.type_of_work }}
            gutterBottom
          />
        </>
      )}

      {event.description.study_theme && (
        <>
          <Typography variant="h6" gutterBottom>
            {t('pages.events.detail.studyTheme')}
          </Typography>
          <Typography
            component="div"
            dangerouslySetInnerHTML={{ __html: event.description.study_theme }}
            gutterBottom
          />
        </>
      )}

      {event.description.accomodation && (
        <>
          <Typography variant="h6" gutterBottom>
            {t('pages.events.detail.accomodation')}
          </Typography>
          <Typography
            component="div"
            dangerouslySetInnerHTML={{ __html: event.description.accomodation }}
            gutterBottom
          />
        </>
      )}

      {event.description.camp_advert && (
        <>
          <Typography variant="h6" gutterBottom>
            {t('pages.events.detail.campAdvert')}
          </Typography>
          <Typography
            component="div"
            dangerouslySetInnerHTML={{ __html: event.description.camp_advert }}
            gutterBottom
          />
        </>
      )}

      {event.description.language_description && (
        <>
          <Typography variant="h6" gutterBottom>
            {t('pages.events.detail.languages')}
          </Typography>
          <Typography
            component="div"
            dangerouslySetInnerHTML={{ __html: event.description.language_description }}
            gutterBottom
          />
        </>
      )}

      {event.description.requirements && (
        <>
          <Typography variant="h6" gutterBottom>
            {t('pages.events.detail.requirements')}
          </Typography>
          <Typography
            component="div"
            dangerouslySetInnerHTML={{ __html: event.description.requirements }}
            gutterBottom
          />
        </>
      )}

      {event.description.location && (
        <>
          <Typography variant="h6" gutterBottom>
            {t('pages.events.detail.location')}
          </Typography>
          <Typography
            component="div"
            dangerouslySetInnerHTML={{ __html: event.description.location }}
            gutterBottom
          />
        </>
      )}

      {event.description.additional_camp_notes && (
        <>
          <Typography variant="h6" gutterBottom>
            {t('pages.events.detail.additionalNotes')}
          </Typography>
          <Typography
            component="div"
            dangerouslySetInnerHTML={{ __html: event.description.additional_camp_notes }}
            gutterBottom
          />
        </>
      )}

      {event.description.fees && (
        <>
          <Typography variant="h6" gutterBottom>
            {t('pages.events.detail.fees')}
          </Typography>
          <Typography
            component="div"
            dangerouslySetInnerHTML={{ __html: event.description.fees }}
            gutterBottom
          />
        </>
      )}
    </>
  )
}

export const EventDetailModal = ({ eventId, onClose }: EventDetailModalProps) => {
  const {
    data: event,
    isLoading,
    isError,
  } = useFetchData<EventDetail>('/v1/events/get', { id: eventId })

  return (
    <Drawer
      onClose={onClose}
      isOpen={true}
      title={
        <TitleWrapper>
          <Typography>{event?.code}</Typography>
          <Typography variant="h5">{event?.title || 'Event'}</Typography>
        </TitleWrapper>
      }
    >
      <div style={{ padding: 10 }}>
        <EventDetailContent event={event} isLoading={isLoading} isError={isError} />
      </div>
    </Drawer>
  )
}
