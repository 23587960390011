import { createTheme } from '@mui/material'

const colors = {
  yellow: '#FAB916',
  orange: '#EB5F25',
  brown: '#B14116',
  gray: '#F2F2F2',
  black: '#000000',
  red: '#FC2E20',
  green: '#228B22',
  white: '#FFFFFF',
}

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: colors.yellow,
    },
    secondary: {
      main: colors.brown,
    },
    success: {
      main: colors.green,
    },
    error: {
      main: colors.red,
    },
    warning: {
      main: colors.yellow,
    },
    background: {
      paper: colors.gray,
    },
    info: {
      main: colors.orange,
    },
  },
  typography: {
    fontFamily: '"Lato", "Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    fontWeightBold: 800,
  },
})
