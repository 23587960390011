import styled from 'styled-components'
import { ReactComponent as WithBag } from '../../assets/logo/withBag.svg'
import Button from '../ui/Button'
import { Grid, Typography } from '@mui/material'
import useTranslate from '../../utils/translations/useTranslate'

export const VOLUNTEERING_ABROAD_DIV_ID = 'volunteering-abroad'

const Wrapper = styled.div`
  padding: 40px 0;
  width: 100%;
  display: flex;
`

const VolunteerAbroad = () => {
  const { t } = useTranslate()

  return (
    <Wrapper id={VOLUNTEERING_ABROAD_DIV_ID}>
      <Grid container spacing="2">
        <Grid item sm={10}>
          <Typography variant="h4" gutterBottom>
            {t('pages.events.volunteerAbroad.title')}
          </Typography>
          <Typography component="div">
            <ul>
              <li>{t('pages.events.volunteerAbroad.description1')}</li>
              <li>{t('pages.events.volunteerAbroad.description2')}</li>
              <li>{t('pages.events.volunteerAbroad.description3')}</li>
              <li>{t('pages.events.volunteerAbroad.description4')}</li>
            </ul>
          </Typography>
          <div style={{ textAlign: 'right' }}>
            <Button href={`/events/search/${VOLUNTEERING_ABROAD_DIV_ID}`}>
              {t('pages.events.volunteerAbroad.goToSearchAction')}
            </Button>
          </div>
        </Grid>
        <Grid
          item
          sm={2}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <WithBag />
        </Grid>
      </Grid>
    </Wrapper>
  )
}

export default VolunteerAbroad
