import styled from 'styled-components'
import { ReactComponent as HoldingSlovakMap } from '../../assets/logo/holdingSlovakMap.svg'
import Button from '../ui/Button'
import { Typography, Grid } from '@mui/material'
import useTranslate from '../../utils/translations/useTranslate'

export const VOLUNTEERING_SLOVAKIA_DIV_ID = 'volunteering-slovakia'

const Wrapper = styled.div`
  padding: 40px 0;
  width: 100%;
  display: flex;
`

const VolunteerSlovakia = () => {
  const { t } = useTranslate()

  return (
    <Wrapper id={VOLUNTEERING_SLOVAKIA_DIV_ID}>
      <Grid container spacing="2">
        <Grid item sm={10}>
          <Typography variant="h4" gutterBottom>
            {t('pages.events.volunteerSlovakia.title')}
          </Typography>
          <Typography component="div">
            <ul>
              <li>{t('pages.events.volunteerSlovakia.description1')}</li>
              <li>{t('pages.events.volunteerSlovakia.description2')}</li>
              <li>{t('pages.events.volunteerSlovakia.description3')}</li>
              <li>{t('pages.events.volunteerSlovakia.description4')}</li>
              <li>{t('pages.events.volunteerSlovakia.description5')}</li>
            </ul>
          </Typography>
          <div style={{ textAlign: 'right' }}>
            <Button href={`/events/search/${VOLUNTEERING_SLOVAKIA_DIV_ID}`}>
              {t('pages.events.volunteerSlovakia.goToSearchAction')}
            </Button>
          </div>
        </Grid>
        <Grid
          item
          sm={2}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
        >
          <HoldingSlovakMap />
        </Grid>
      </Grid>
    </Wrapper>
  )
}

export default VolunteerSlovakia
