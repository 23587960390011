import Breadcrumbs from '../components/Breadcrumbs'
import { ContactArticle, Contacts } from '../components/homepage'
import LoadingOverlay from '../components/LoadingOverlay'
import ContentContainer from '../components/ui/ContentContainer'
import useFetchData from '../hooks/useFetchData'
import { muiTheme } from '../theme'

const ContactsPage = () => {
  const { data: contacts, isLoading } =
    useFetchData<Record<string, ContactArticle>>('/v1/base/contacts')

  if (!contacts || isLoading) {
    return (
      <div style={{ position: 'relative', minHeight: 200 }}>
        <LoadingOverlay />
      </div>
    )
  }

  return (
    <div style={{ width: '100%' }}>
      <ContentContainer background={muiTheme.palette.background.paper}>
        <Breadcrumbs />
        <Contacts contacts={contacts} />
      </ContentContainer>
    </div>
  )
}

export default ContactsPage
