import { useEffect, useMemo, useState } from 'react'
import { STORAGE_APPLICATION, useLocalStorage } from '../storage/useLocalStorage'
import { ApplicationData, EducationType, EmploymentType, Language, Sex } from '../types/application'

type UseApplicationData = {
  applicationData: ApplicationData
  // todo - keysof ApplicationData, but setter functions as values
  setApplicationData: any
}

const useApplicationData = (): UseApplicationData => {
  const { storageValue, setStorageValue } = useLocalStorage(STORAGE_APPLICATION, {
    applicationData: {},
  })

  const [name, setName] = useState<string>(storageValue.applicationData.name)
  const [surname, setSurname] = useState<string>(storageValue.applicationData.surname)
  const [sex, setSex] = useState<Sex>(storageValue.applicationData.sex)
  const [birthDate, setBirthDate] = useState<string>(storageValue.applicationData.birthDate)
  const [birthPlace, setBirthPlace] = useState<string>(storageValue.applicationData.birthPlace)
  const [nationality, setNationality] = useState<string>(storageValue.applicationData.nationality)
  const [employmentType, setEmploymentType] = useState<EmploymentType>(
    storageValue.applicationData.employmentType
  )
  const [educationType, setEducationType] = useState<EducationType>(
    storageValue.applicationData.educationType
  )
  // Permanent address
  const [permanentAddressStreetWithNumber, setPermanentAddressStreetWithNumber] = useState<string>(
    storageValue.applicationData.permanentAddressStreetWithNumber
  )
  const [permanentAddressZip, setPermanentAddressZip] = useState<string>(
    storageValue.applicationData.permanentAddressZip
  )
  const [permanentAddressCity, setPermanentAddressCity] = useState<string>(
    storageValue.applicationData.permanentAddressCity
  )
  const [permanentAddressCountry, setPermanentAddressCountry] = useState<string>(
    storageValue.applicationData.permanentAddressCountry
  )
  // Contact address
  const [contactAddressStreetWithNumber, setContactAddressStreetWithNumber] = useState<string>(
    storageValue.applicationData.contactAddressStreetWithNumber
  )
  const [contactAddressZip, setContactAddressZip] = useState<string>(
    storageValue.applicationData.contactAddressZip
  )
  const [contactAddressCity, setContactAddressCity] = useState<string>(
    storageValue.applicationData.contactAddressCity
  )
  const [contactAddressCountry, setContactAddressCountry] = useState<string>(
    storageValue.applicationData.contactAddressCountry
  )

  const [email, setEmail] = useState<string>(storageValue.applicationData.email)
  const [phone, setPhone] = useState<string>(storageValue.applicationData.phone)
  // Emergency contact
  const [emergencyContactName, setEmergencyContactName] = useState<string>(
    storageValue.applicationData.emergencyContactName
  )
  const [emergencyContactEmail, setEmergencyContactEmail] = useState<string>(
    storageValue.applicationData.emergencyContactEmail
  )
  const [emergencyContactPhone, setEmergencyContactPhone] = useState<string>(
    storageValue.applicationData.emergencyContactPhone
  )

  const [languages, setLanguages] = useState<Language[]>(
    storageValue.applicationData.languages || [{ language: '', level: '' }]
  )
  const [volunteerExperiences, setVolunteerExperiences] = useState<string>(
    storageValue.applicationData.volunteerExperiences
  )
  const [motivation, setMotivation] = useState<string>(storageValue.applicationData.motivation)
  const [generalRemarks, setGeneralRemarks] = useState<string>(
    storageValue.applicationData.generalRemarks
  )
  const [healthRemarks, setHealthRemarks] = useState<string>(
    storageValue.applicationData.healthRemarks
  )
  // Agreement of condtions state
  const [conditions, setConditions] = useState<Record<string, boolean>>({})

  // Sync changes to local storage
  useEffect(() => {
    setStorageValue({
      ...storageValue,
      applicationData: {
        name,
        surname,
        sex,
        birthDate,
        birthPlace,
        nationality,
        employmentType,
        educationType,
        permanentAddressStreetWithNumber,
        permanentAddressZip,
        permanentAddressCity,
        permanentAddressCountry,
        contactAddressStreetWithNumber,
        contactAddressZip,
        contactAddressCity,
        contactAddressCountry,
        email,
        phone,
        emergencyContactName,
        emergencyContactEmail,
        emergencyContactPhone,
        languages,
        volunteerExperiences,
        motivation,
        generalRemarks,
        healthRemarks,
        conditions,
      },
    })
  }, [
    name,
    surname,
    sex,
    birthDate,
    birthPlace,
    nationality,
    employmentType,
    educationType,
    permanentAddressStreetWithNumber,
    permanentAddressZip,
    permanentAddressCity,
    permanentAddressCountry,
    contactAddressStreetWithNumber,
    contactAddressZip,
    contactAddressCity,
    contactAddressCountry,
    email,
    phone,
    emergencyContactName,
    emergencyContactEmail,
    emergencyContactPhone,
    languages,
    volunteerExperiences,
    motivation,
    generalRemarks,
    healthRemarks,
    conditions,
  ])

  return useMemo(
    () => ({
      applicationData: {
        name,
        surname,
        sex,
        birthDate,
        birthPlace,
        nationality,
        employmentType,
        educationType,
        permanentAddress: {
          streetWithNumber: permanentAddressStreetWithNumber,
          zip: permanentAddressZip,
          city: permanentAddressCity,
          country: permanentAddressCountry,
        },
        contactAddress: {
          streetWithNumber: contactAddressStreetWithNumber,
          zip: contactAddressZip,
          city: contactAddressCity,
          country: contactAddressCountry,
        },
        email,
        phone,
        emergencyContact: {
          name: emergencyContactName,
          email: emergencyContactEmail,
          phone: emergencyContactPhone,
        },
        languages,
        volunteerExperiences,
        motivation,
        generalRemarks,
        healthRemarks,
        conditions,
      },
      setApplicationData: {
        name: setName,
        surname: setSurname,
        sex: setSex,
        birthDate: setBirthDate,
        birthPlace: setBirthPlace,
        nationality: setNationality,
        employmentType: setEmploymentType,
        educationType: setEducationType,
        permanentAddress: {
          streetWithNumber: setPermanentAddressStreetWithNumber,
          zip: setPermanentAddressZip,
          city: setPermanentAddressCity,
          country: setPermanentAddressCountry,
        },
        contactAddress: {
          streetWithNumber: setContactAddressStreetWithNumber,
          zip: setContactAddressZip,
          city: setContactAddressCity,
          country: setContactAddressCountry,
        },
        email: setEmail,
        phone: setPhone,
        emergencyContact: {
          name: setEmergencyContactName,
          email: setEmergencyContactEmail,
          phone: setEmergencyContactPhone,
        },
        languages: setLanguages,
        volunteerExperiences: setVolunteerExperiences,
        motivation: setMotivation,
        generalRemarks: setGeneralRemarks,
        healthRemarks: setHealthRemarks,
        conditions: setConditions,
      },
    }),
    [
      name,
      surname,
      sex,
      birthDate,
      birthPlace,
      nationality,
      employmentType,
      educationType,
      permanentAddressStreetWithNumber,
      permanentAddressZip,
      permanentAddressCity,
      permanentAddressCountry,
      contactAddressStreetWithNumber,
      contactAddressZip,
      contactAddressCity,
      contactAddressCountry,
      email,
      phone,
      emergencyContactName,
      emergencyContactEmail,
      emergencyContactPhone,
      languages,
      volunteerExperiences,
      motivation,
      generalRemarks,
      healthRemarks,
      conditions,
    ]
  )
}

export default useApplicationData
