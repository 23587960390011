export enum ApplicationType {
  BASIC = 'BASIC',
  SHORT = 'SHORT',
  WORKCAMP = 'WORKCAMP',
}

export enum Sex {
  MAN = 'M',
  WOMAN = 'F',
}

// DO NOT CHANGE - Or update on BE + translation keys
export enum EmploymentType {
  STUDENT = 'Student',
  EMPLOYED = 'Employed',
  UNEMPLOYED = 'Unemployed',
  OTHER = 'Other',
}

// DO NOT CHANGE - Or update also in the DB (educations table)
export enum EducationType {
  ELEMENTARY = 'elementary',
  VOCATIONAL = 'vocational', // Ucnovka
  VOCATIONAL_GRADUATION = 'vocationalGraduation', // Ucnovka s maturitou
  HIGHSCHOOL = 'highschool', // Stredna bez maturity
  HIGHSCHOOL_GRADUATION = 'highschoolGraduation', // Stredna vseobecna s maturitou
  HIGHSCHOOL_SPECIALIZED_GRADUATION = 'highschoolSpecializedGraduation', // Stredna odborna s maturitou
  HIGHER_SPECIALIZED = 'higherSpecialized', // Vyssia odborna
  UNIVERSITY_BACHELOR = 'universityBachelor', // Bagisterske
  UNIVERSITY_MASTERS = 'universityMasters', // Magisterske
  UNIVERSITY_POSTGRAD = 'universityPostgrad', // PHD
}

export type Address = {
  streetWithNumber: string
  zip: string
  city: string
  country: string
}

export enum Languages {
  CATALAN = 'Catalan',
  CHINESE = 'Chinese',
  CZECH = 'Czech',
  DUTCH = 'Dutch',
  ENGLISH = 'English',
  FRENCH = 'French',
  GERMAN = 'German',
  HUNGARIAN = 'Hungarian',
  ITALIAN = 'Italian',
  JAPANESE = 'Japanese',
  NORWEGIAN = 'Norwegian',
  POLISH = 'Polish',
  RUSSIAN = 'Russian',
  SERBIAN = 'Serbian',
  SLOVAK = 'Slovak',
  SLOVENIAN = 'Slovenian',
  SPANISH = 'Spanish',
  UKRAINIAN = 'Ukrainian',
  BULGARIAN = 'Bulgarian',
  ARMENIAN = 'Armenian',
  ARABIC = 'Arabic',
  TIBETIAN = 'Tibetan',
  LATIN = 'Latin',
  CROATIAN = 'Croatian',
  PORTUGESE = 'Portuguese',
  FINNISH = 'Finnish',
  ROMANIAN = 'Romanian',
  VIETNAMESE = 'Vietnamese',
  ESTONIAN = 'Estonian',
}

export enum LanguageLevel {
  ADVANCED = 'Advanced',
  INTERMEDIATE = 'Intermediate',
  ELEMENTARY = 'Elementary',
  BASIC = 'Basic',
}

export type Language = {
  language: Languages
  level: LanguageLevel
}

export type ApplicationData = {
  // basic info
  name: string
  surname: string
  sex: Sex
  birthDate: string
  birthPlace: string
  nationality: string
  employmentType: EmploymentType
  educationType: EducationType
  permanentAddress: Address
  contactAddress: Address
  email: string
  phone: string
  // volunteer experience
  emergencyContact: {
    name: string
    email: string
    phone: string
  }
  languages: Language[]
  volunteerExperiences: string
  motivation: string
  generalRemarks: string
  healthRemarks: string
  conditions: Record<string, boolean>
}

export enum ERROR_CODES {
  ZERO_EVENTS = 'ZERO_EVENTS',
  FIELD_MANDATORY = 'FIELD_MANDATORY',
  MOTIVATION_LENGTH = 'MOTIVATION_LENGTH',
  UNCHECKED_CONDITIONS = 'UNCHECKED_CONDITIONS',
  PHONE_NUMBER_FORMAT = 'PHONE_NUMBER_FORMAT',
}
