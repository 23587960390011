import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebookSquare,
  faTwitterSquare,
  faLinkedinIn,
  faYoutube,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons'

const Link = styled.a`
  padding: 0.78571429em 0.92857143em;
  margin: 0 0.35714286em;
  color: #000;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
`

const SocialNetworks = () => {
  return (
    <>
      <Link
        target="blank"
        title="Facebook"
        href="https://www.facebook.com/INEX-Slovakia-189864024941/"
      >
        <FontAwesomeIcon icon={faFacebookSquare} size="2x" />
      </Link>
      <Link target="blank" title="Twitter" href="https://twitter.com/inexslovakia">
        <FontAwesomeIcon icon={faTwitterSquare} size="2x" />
      </Link>
      <Link target="blank" title="LinkedIn" href="https://www.linkedin.com/company/inex-slovakia">
        <FontAwesomeIcon icon={faLinkedinIn} size="2x" />
      </Link>
      <Link target="blank" title="YouTube" href="https://www.youtube.com/user/Inexslovakia">
        <FontAwesomeIcon icon={faYoutube} size="2x" />
      </Link>
      <Link target="blank" title="Instagram" href="https://www.instagram.com/inex_slovakia/">
        <FontAwesomeIcon icon={faInstagram} size="2x" />
      </Link>
    </>
  )
}

export default SocialNetworks
