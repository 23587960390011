import Breadcrumbs from '../components/Breadcrumbs'
import DobromatContent from '../components/helpUs/Dobromat'
import ContentContainer from '../components/ui/ContentContainer'
import { muiTheme } from '../theme'

const HelpDobromat = () => {
  return (
    <div style={{ width: '100%' }}>
      <ContentContainer background={muiTheme.palette.background.paper}>
        <Breadcrumbs />
        <DobromatContent />
      </ContentContainer>
    </div>
  )
}

export default HelpDobromat
