import { Card, CardContent, CardMedia, Skeleton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { PostItem } from '../../types/media'
import { formatDate } from '../../utils/formatDate'
import Button from '../ui/Button'

type Props = {
  post: PostItem
}

const PostListItem = ({ post }: Props) => {
  return (
    <Card key={post.id} sx={{ display: 'flex' }}>
      {post.imageUrl ? (
        <CardMedia
          component="img"
          sx={{ width: 150, objectFit: 'contain', mt: 2, ml: 1, borderRadius: 1 }}
          image={`${process.env.REACT_APP_LEGACY_SERVER_ENDPOINT}${post.imageUrl}`}
          alt={post.title}
          loading="lazy"
        />
      ) : (
        <CardContent sx={{ padding: 0, mt: 2, ml: 1 }}>
          <Skeleton variant="text" width={150} animation={false} />
          <Skeleton variant="circular" width={40} height={40} animation={false} />
          <Skeleton
            variant="rectangular"
            component="div"
            animation={false}
            width={150}
            height={120}
            sx={{ mt: 1 }}
          />
        </CardContent>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component="div" variant="h5">
            {post.title}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
            {formatDate(post.createdAt)}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            dangerouslySetInnerHTML={{ __html: post.perex }}
          />
        </CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
          <Button href={post.href}>Detail</Button>
        </Box>
      </Box>
    </Card>
  )
}

export default PostListItem
