import Breadcrumbs from '../components/Breadcrumbs'
import ContentContainer from '../components/ui/ContentContainer'
import { muiTheme } from '../theme'

const MyAccount = () => {
  return (
    <div style={{ width: '100%' }}>
      <ContentContainer background={muiTheme.palette.background.paper}>
        <Breadcrumbs />
      </ContentContainer>
    </div>
  )
}

export default MyAccount
