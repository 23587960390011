import { Grid, Typography } from '@mui/material'
import useEventApplicationContext from '../../contexts/useEventApplicationContext'
import { EventItem } from '../../types/event'
import { formatDate } from '../../utils/formatDate'
import useTranslate from '../../utils/translations/useTranslate'
import Button from '../ui/Button'
import { ArrowUpward, ArrowDownward } from '@mui/icons-material'

type Props = {
  event: EventItem
  openDetailModal?: (event: EventItem) => void
  withActions?: boolean
  isOrderable?: boolean
  moveUp?: () => void
  moveDown?: () => void
}

const arrowIconStyles = { cursor: 'pointer' }

const ORDERABLE_COLUMN_WIDTH = 1
const ACTIONS_COLUMN_WIDTH = 4

const ApplicationEvent = ({
  event,
  openDetailModal,
  withActions,
  isOrderable,
  moveUp,
  moveDown,
}: Props) => {
  const { remove } = useEventApplicationContext()
  const { t } = useTranslate()

  return (
    <Grid
      container
      sx={{
        p: 3,
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 2,
      }}
    >
      {isOrderable && (
        <Grid item sm={ORDERABLE_COLUMN_WIDTH} sx={{ display: 'flex', gap: 1 }}>
          <ArrowUpward sx={arrowIconStyles} onClick={moveUp} />
          <ArrowDownward sx={arrowIconStyles} onClick={moveDown} />
        </Grid>
      )}
      <Grid
        item
        sm={12}
        md={
          11 -
          Number(isOrderable) * ORDERABLE_COLUMN_WIDTH -
          Number(withActions) * ACTIONS_COLUMN_WIDTH
        }
      >
        <Typography color="secondary">{event.code}</Typography>
        <Typography variant="h6" color="secondary" sx={{ fontWeight: 'bold' }}>
          {event.title}
        </Typography>
        <Typography>
          {event.country}, {formatDate(event.from)} - {formatDate(event.to)}
        </Typography>
      </Grid>
      {withActions && (
        <Grid
          item
          container
          xs={12}
          md={ACTIONS_COLUMN_WIDTH}
          sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}
        >
          <Grid item>
            <Button
              component="button"
              href={`/events/${event.id}`}
              onClick={(e: MouseEvent) => {
                if (!openDetailModal) {
                  return
                }

                e.preventDefault()
                openDetailModal(event)
              }}
              sx={{ marginRight: 1 }}
            >
              Detail
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="error"
              onClick={() => {
                remove(event.eventType, event.id)
              }}
            >
              {t('pages.events.search.removeFromBag')}
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default ApplicationEvent
