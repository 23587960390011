import { useCallback, useMemo } from 'react'
import sk from './sk.json'
import en from './en.json'
import { LANGUAGE, useLocalStorage } from '../../storage/useLocalStorage'

export enum Languages {
  SK = 'SK',
  EN = 'EN',
}

const checkLanguages = (): boolean => {
  const skKeys = Object.keys(sk)
  const enKeys = Object.keys(en)
  if (skKeys.length !== enKeys.length) {
    console.warn('[checkLanguageSources] Language sources contain different number of keys')
    return false
  }

  const differences = skKeys.filter(
    (skKey: string) => !Boolean((en as Record<string, string>)[skKey])
  )

  if (differences.length) {
    console.warn(
      '[checkLanguageSources] SK source contains different keys from en source',
      differences
    )
    return false
  }
  return true
}

// Check that language keys are set properly for both languages, run it by default
checkLanguages()

const useTranslate = () => {
  const { storageValue: language, setStorageValue: setStorageLanguage } = useLocalStorage(
    LANGUAGE,
    Languages.SK
  )

  const messages: Record<string, string> = useMemo(() => {
    if (language === Languages.SK) {
      return sk
    }
    if (language === Languages.EN) {
      return en
    }

    console.warn('[useTranslate] Unknown language', language)
    return {}
  }, [language])

  const translate = useCallback(
    (messageKey: string) => messages[messageKey] || messageKey,
    [messages]
  )

  // We have only 2 languages for now so the setLanguage implementation can work as a toggle operation
  const toggleLanguage = useCallback(() => {
    if (language === Languages.SK) {
      setStorageLanguage(Languages.EN)
    } else {
      setStorageLanguage(Languages.SK)
    }
  }, [language, setStorageLanguage])

  return useMemo(
    () => ({
      language,
      setLanguage: toggleLanguage,
      t: translate,
    }),
    [language, toggleLanguage, translate]
  )
}

export default useTranslate
