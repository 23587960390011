import { Button as MuiButton } from '@mui/material'

type Props = {
  className?: string
  variant?: 'contained' | 'outlined'
  color?: 'primary' | 'secondary' | 'info' | 'error' | 'success'
  children: React.ReactNode
  onClick?: (event: any) => void
  href?: string
  sx?: any
  size?: 'small' | 'medium' | 'large'
  target?: '_blank'
  type?: 'submit'
  component?: 'a' | 'button'
}

const Button = ({
  className,
  variant = 'contained',
  color = 'primary',
  children,
  onClick,
  href,
  sx,
  size,
  target,
  type,
  component,
}: Props) => {
  return (
    <MuiButton
      className={className}
      variant={variant}
      color={color}
      onClick={onClick}
      href={href}
      sx={sx}
      size={size}
      component={component || (href ? 'a' : 'button')}
      target={target}
      type={type}
    >
      {children}
    </MuiButton>
  )
}

export default Button
