import Button from '../../ui/Button'
import { EventItem as EventItemType } from '../../../types/event'
import useEventApplicationContext from '../../../contexts/useEventApplicationContext'
import { Card, CardActions, CardContent, Typography } from '@mui/material'
import { formatDate } from '../../../utils/formatDate'
import useTranslate from '../../../utils/translations/useTranslate'

type Props = {
  event: EventItemType
  openDetailModal: (event: EventItemType) => void
  handleAddToBag: (event: EventItemType) => void
}

const EventItem = ({ event, openDetailModal, handleAddToBag }: Props) => {
  const { isInBag } = useEventApplicationContext()
  const { t } = useTranslate()

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {event.code}
        </Typography>
        <Typography variant="h5" component="div">
          {event.title}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {event.country}
        </Typography>
        <Typography variant="body2">
          {formatDate(event.from)} - {formatDate(event.to)}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          component="button"
          href={`/events/${event.id}`}
          onClick={(e: MouseEvent) => {
            e.preventDefault()
            openDetailModal(event)
          }}
        >
          Detail
        </Button>
        <Button
          color={isInBag(event.eventType, event.id) ? 'error' : 'info'}
          onClick={() => handleAddToBag(event)}
        >
          {isInBag(event.eventType, event.id)
            ? t('pages.events.search.removeFromBag')
            : t('pages.events.search.addToBag')}
        </Button>
      </CardActions>
    </Card>
  )
}

export default EventItem
