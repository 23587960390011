import getCsrfCookieToken from './getCsrfCookieToken'

type Method = 'GET' | 'POST' | 'DELETE'

const composeUrl = (baseUrl: string, method: Method, variables: Object = {}): string => {
  const url = new URL(baseUrl)
  if (method === 'GET') {
    Object.entries(variables).forEach(([key, value]) => {
      url.searchParams.append(key, value)
    })
  }

  return url.href
}

const fetchData = (method: Method, endpoint: string, variables?: Object) => {
  const bodyObject = method === 'GET' ? {} : { body: JSON.stringify(variables) }

  return fetch(
    composeUrl(`${process.env.REACT_APP_SERVER_ENDPOINT}${endpoint}`, method, variables),
    {
      method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCsrfCookieToken(document.cookie),
      },
      ...bodyObject,
    }
  )
    .then(async (response) => {
      if (!response || response.status !== 200) {
        console.error('Failed to complete query.')
      }
      const responseBody = await response.json()
      if (responseBody?.errors) {
        console.error('Failed to complete query with errors: ', responseBody.errors)
      }

      return responseBody
    })
    .catch((error) => {
      console.error('Failed to complete query.')
      console.error(error)
    })
}

export default fetchData
