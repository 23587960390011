import { FormControl, FormHelperText, InputLabel, OutlinedInput } from '@mui/material'
import { ChangeEventHandler } from 'react'
import styled from 'styled-components'
import { muiTheme } from '../../theme'

type Props = {
  type: React.HTMLInputTypeAttribute
  required?: boolean
  onChange?: ChangeEventHandler
  label?: string
  labelBackground?: string
  value?: string
  className?: string
  name?: string
  error?: string
  multiline?: boolean
  helperMessage?: string
}

const StyledFormControl = styled(FormControl)`
  width: 100%;
`

const StyledInputLabel = styled(InputLabel)<{ background: string }>`
  background: ${({ background }) => background};
`

const TextInput = ({
  type,
  required,
  onChange,
  label,
  labelBackground = muiTheme.palette.background.paper,
  value,
  className,
  name,
  error,
  multiline,
  helperMessage,
}: Props) => {
  return (
    <StyledFormControl error={Boolean(error)} color="secondary">
      <StyledInputLabel background={labelBackground}>
        {label}
        {required ? ' *' : null}
      </StyledInputLabel>
      <OutlinedInput
        type={type}
        value={value}
        onChange={onChange}
        required={required}
        className={className}
        name={name}
        multiline={multiline}
      />
      <FormHelperText>
        {error} {helperMessage}
      </FormHelperText>
    </StyledFormControl>
  )
}

export default TextInput
