import { Box } from '@mui/material'
import { IBAN } from '../../constants'
import Link from '../ui/Link'
import TextInput from '../ui/TextInput'
import { useMemo, useState } from 'react'

const QRCodePayment = () => {
  const [amount, setAmount] = useState<number>(10)

  const qrUrl = useMemo(() => {
    const params = new URLSearchParams({
      iban: IBAN.replace(/\s/g, ''),
      amount: String(amount),
      currency: 'EUR',
      payment_note: 'INEX Slovakia',
      size: String(128),
      transparent: String(true),
    })

    return `https://api.QRGenerator.sk/by-square/pay/qr.png?${params.toString()}`
  }, [amount])

  return (
    <Box sx={{ mt: 2, mb: 1 }}>
      <TextInput
        type="number"
        label="Suma v EUR"
        value={String(amount)}
        onChange={(e) => {
          const value = (e.target as HTMLInputElement).value
          setAmount(Number(value) || 10)
        }}
      />
      <Link onClick={() => setAmount(5)} sx={{ cursor: 'pointer' }}>
        5 EUR
      </Link>
      <Link onClick={() => setAmount(10)} sx={{ ml: 1, cursor: 'pointer' }}>
        10 EUR
      </Link>
      <Link onClick={() => setAmount(20)} sx={{ ml: 1, cursor: 'pointer' }}>
        20 EUR
      </Link>
      <Link onClick={() => setAmount(50)} sx={{ ml: 1, cursor: 'pointer' }}>
        50 EUR
      </Link>
      <Link onClick={() => setAmount(100)} sx={{ ml: 1, cursor: 'pointer' }}>
        100 EUR
      </Link>
      <br />
      <br />
      <img src={qrUrl} alt="QR kod" loading="lazy" />
    </Box>
  )
}

export default QRCodePayment
