import styled from 'styled-components'
import {
  Select as MUISelect,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  FormHelperText,
} from '@mui/material'
import { muiTheme } from '../../theme'

type SelectProps = {
  multiple?: boolean
  children: React.ReactNode
  onChange?: (event: SelectChangeEvent<unknown>, child: React.ReactNode) => void
  options?: { id: string; name: string }[]
  value?: any
  error?: string
  required?: boolean
}

const StyledFormControl = styled(FormControl)`
  width: 100%;
`

const StyledInputLabel = styled(InputLabel)`
  background: ${muiTheme.palette.background.paper};
`

export const Select = ({
  multiple = false,
  children,
  onChange,
  options,
  value,
  error,
  required,
}: SelectProps) => {
  return (
    <StyledFormControl error={Boolean(error)} color="secondary">
      <StyledInputLabel>
        {children}
        {required ? ' *' : null}
      </StyledInputLabel>
      <MUISelect multiple={multiple} value={value} onChange={onChange} required={required}>
        {options?.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </MUISelect>
      <FormHelperText>{error}</FormHelperText>
    </StyledFormControl>
  )
}
