import { IconButton, Box, FormControlLabel, Grid, Switch, Typography } from '@mui/material'
import { useCallback, useState } from 'react'
import { GDPR_CONSENT, useLocalStorage } from '../storage/useLocalStorage'
import { muiTheme } from '../theme'
import Button from './ui/Button'
import Link from './ui/Link'
import CookieIcon from '@mui/icons-material/CookieOutlined'
import useTranslate from '../utils/translations/useTranslate'

const ConsentGDPR = () => {
  const { t } = useTranslate()
  const { storageValue: consents, setStorageValue } = useLocalStorage(GDPR_CONSENT, {
    isFilled: false,
    extendedConsentEnabled: false,
  })
  const [extendedConditionsVisible, setExtendedConditionsVisible] = useState<boolean>(false)

  const agreeConditions = useCallback(() => {
    setStorageValue({ ...consents, isFilled: true })
  }, [consents, setStorageValue])

  const disagreeConditions = useCallback(() => {
    setStorageValue({
      isFilled: true,
      extendedConsentEnabled: false,
    })
  }, [setStorageValue])

  if (consents.isFilled) {
    return (
      <IconButton
        onClick={() => {
          setStorageValue({ ...consents, isFilled: false })
        }}
        sx={{
          position: 'fixed',
          right: 0,
          bottom: '1em',
          zIndex: 100,
        }}
      >
        <CookieIcon sx={{ width: '3em', height: '3em' }} color="action" />
      </IconButton>
    )
  }

  return (
    <Grid
      container
      sx={{
        position: 'fixed',
        left: 0,
        bottom: 0,
        width: '100vw',
        zIndex: 100,
        p: 8,
        background: muiTheme.palette.secondary.main,
        border: `2px solid ${muiTheme.palette.primary.main}`,
        display: 'unset',
        boxSizing: 'border-box',
      }}
    >
      <Grid item sm={12} md={2}>
        <CookieIcon sx={{ width: '4em', height: '4em' }} color="primary" />
      </Grid>
      <Grid item sm={12} md={10}>
        <Typography variant="h6" color={muiTheme.palette.background.paper} gutterBottom>
          {t('cookies.description')}{' '}
          <Link color="primary" href="https://inex.sk/sk/a/102/cookies">
            {t('cookies.learnMoreLink')}
          </Link>
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mt: 2 }}>
          <Button onClick={agreeConditions}>{t('cookies.iAccept')}</Button>
          <Button onClick={disagreeConditions}>{t('cookies.iDeny')}</Button>
          <Button onClick={() => setExtendedConditionsVisible((prev) => !prev)}>
            {t('cookies.settings')}
          </Button>
        </Box>
        {extendedConditionsVisible && (
          <Box>
            <FormControlLabel
              control={<Switch color="primary" sx={{ m: 1 }} defaultChecked disabled />}
              label={
                <Typography variant="h6" color={muiTheme.palette.background.paper}>
                  {t('cookies.basicCookies')}
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  sx={{ m: 1 }}
                  checked={consents.extendedConsentEnabled}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setStorageValue({ ...consents, extendedConsentEnabled: event.target.checked })
                  }}
                />
              }
              label={
                <Typography variant="h6" color={muiTheme.palette.background.paper}>
                  {t('cookies.extendedCookies')}
                </Typography>
              }
            />
          </Box>
        )}
      </Grid>
    </Grid>
  )
}

export default ConsentGDPR
