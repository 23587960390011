import { Grid } from '@mui/material'
import { muiTheme } from '../../../theme'
import useTranslate from '../../../utils/translations/useTranslate'
import Button from '../../ui/Button'
import TextInput from '../../ui/TextInput'

const MailingListSubscriptionForm = () => {
  const { t } = useTranslate()

  return (
    <>
      <div
        id="mlb2-983168"
        className="ml-form-embedContainer ml-subscribe-form ml-subscribe-form-983168"
      >
        <div className="ml-form-align-center ">
          <div className="ml-form-embedWrapper embedForm">
            <div className="ml-form-embedBody ml-form-embedBodyDefault row-form">
              <div className="ml-form-embedContent">
                <p>{t('layout.footer.mailing.description')}</p>
              </div>
              <form
                className="ml-block-form"
                action="https://app.mailerlite.com/webforms/submit/f5q2j1"
                data-code="f5q2j1"
                method="post"
                target="_blank"
              >
                <div className="ml-form-formContent">
                  <div className="ml-form-fieldRow ml-last-item">
                    <Grid
                      container
                      className="ml-field-group ml-field-email ml-validate-email ml-validate-required"
                    >
                      <TextInput
                        type="email"
                        className="form-control"
                        data-inputmask=""
                        name="fields[email]"
                        label="Email"
                        labelBackground={muiTheme.palette.primary.main}
                        required
                      />
                      <input type="hidden" name="ml-submit" value="1" />
                      <div className="ml-form-embedSubmit">
                        <Button type="submit" color="info" sx={{ minWidth: 150 }}>
                          {t('layout.footer.mailing.joinAction')}
                        </Button>
                        <button
                          disabled={true}
                          style={{ display: 'none' }}
                          type="button"
                          className="loading ui grey button"
                        >
                          <div className="ml-form-embedSubmitLoad">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </button>
                      </div>
                    </Grid>
                  </div>
                </div>
              </form>
            </div>
            <div className="ml-form-successBody row-success" style={{ display: 'none' }}>
              <div className="ml-form-successContent">
                <h4>{t('layout.footer.mailing.result.title')}</h4>
                <p>{t('layout.footer.mailing.result.description')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        src="https://track.mailerlite.com/webforms/o/983168/f5q2j1?v03ee2804ef2f008535475a5d62f02f94"
        width="1"
        height="1"
        style={{
          maxWidth: 1,
          maxHeight: 1,
          visibility: 'hidden',
          padding: 0,
          margin: 0,
          display: 'block',
          border: 0,
        }}
        alt="mailerlite"
      />
      <script
        src="https://static.mailerlite.com/js/w/webforms.min.js?v03ee2804ef2f008535475a5d62f02f94"
        type="text/javascript"
      ></script>
    </>
  )
}

export default MailingListSubscriptionForm
