import { Grid } from '@mui/material'
import { useState } from 'react'
import AboutInex from '../components/aboutUs/AboutInex'
import History from '../components/aboutUs/History'
import Menu from '../components/aboutUs/Menu'
import OrganizationStructure from '../components/aboutUs/OrganizationStructure'
import OurTeam from '../components/aboutUs/OurTeam'
import Partners from '../components/aboutUs/Partners'
import Breadcrumbs from '../components/Breadcrumbs'
import ContentContainer from '../components/ui/ContentContainer'
import { muiTheme } from '../theme'

export type Page =
  | 'aboutUs'
  | 'ourTeam'
  | 'partnerOrganizations'
  | 'history'
  | 'organizationStructure'

type Props = {
  page?: Page
}

const AboutUs = ({ page: defaultPage = 'aboutUs' }: Props) => {
  const [page, setPage] = useState<Page>(defaultPage)

  const getContentComponent = () => {
    switch (page) {
      case 'aboutUs':
        return <AboutInex />
      case 'ourTeam':
        return <OurTeam />
      case 'partnerOrganizations':
        return <Partners />
      case 'history':
        return <History />
      case 'organizationStructure':
        return <OrganizationStructure />
    }
    return null
  }

  return (
    <div style={{ width: '100%' }}>
      <ContentContainer background={muiTheme.palette.background.paper}>
        <Breadcrumbs />
        <Grid container sx={{ minHeight: 450, paddingTop: 4, marginBottom: 8 }}>
          <Grid item sm={12} md={8}>
            {getContentComponent()}
          </Grid>
          <Grid item sm={12} md={4}>
            <Menu setPage={setPage} />
          </Grid>
        </Grid>
      </ContentContainer>
    </div>
  )
}

export default AboutUs
