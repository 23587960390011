import { Grid, Typography } from '@mui/material'
import { useCallback, useState } from 'react'
import styled from 'styled-components'
import useEventApplicationContext from '../../contexts/useEventApplicationContext'
import { LanguageLevel, Languages } from '../../types/application'
import useTranslate from '../../utils/translations/useTranslate'
import Button from '../ui/Button'
import { Select } from '../ui/Select'
import TextInput from '../ui/TextInput'

type Props = {
  goToPreviousFormPage: () => void
  goToNextFormPage: () => void
}

const Wrapper = styled.div`
  padding: 40px 0 60px 0;
  width: 100%;
  position: relative;
`

const ActionButtons = styled.div`
  display: flex;
  padding: 10px 0;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
`

const VOLUNTEERING_STEP_VALIDATION_FIELDS: string[] = [
  'emergencyContact',
  'languages',
  'volunteerExperiences',
  'motivation',
]

const Volunteering = ({ goToNextFormPage, goToPreviousFormPage }: Props) => {
  const { t } = useTranslate()
  const { eventsCount, applicationData, setApplicationData, validate } =
    useEventApplicationContext()
  const [errors, setErrors] = useState<Record<string, string>>({})

  const addLanguage = useCallback(() => {
    const newLanguages = [...(applicationData.languages || []), { language: '', level: '' }]

    setApplicationData.languages(newLanguages)
  }, [setApplicationData, applicationData.languages])

  const removeLanguage = useCallback(
    (index: number) => {
      console.log('languages', applicationData.languages)
      const newLanguages = [...(applicationData.languages || [])]
      newLanguages.splice(index, 1)

      setApplicationData.languages(newLanguages)
    },
    [setApplicationData, applicationData.languages]
  )

  const updateLanguageName = useCallback(
    (index: number, name: string) => {
      const newLanguages = [...(applicationData.languages || [])]
      if (!newLanguages[index]) {
        return
      }
      newLanguages[index] = { ...newLanguages[index], language: name as Languages }

      setApplicationData.languages(newLanguages)
    },
    [setApplicationData, applicationData.languages]
  )

  const updateLanguageLevel = useCallback(
    (index: number, level: string) => {
      const newLanguages = [...(applicationData.languages || [])]
      if (!newLanguages[index]) {
        return
      }

      newLanguages[index] = { ...newLanguages[index], level: level as LanguageLevel }

      setApplicationData.languages(newLanguages)
    },
    [setApplicationData, applicationData.languages]
  )

  // Validates input data for this step and propagates errors
  const validateVolunteering = useCallback(() => {
    const validationErrors = validate()
    const thisStepErrors: Record<string, string> = {}

    VOLUNTEERING_STEP_VALIDATION_FIELDS.forEach((field) => {
      if (validationErrors[field]) {
        thisStepErrors[field] = validationErrors[field][0]
      }
    })

    if (validationErrors.emergencyContactPhone) {
      thisStepErrors.emergencyContactPhone = validationErrors.emergencyContactPhone[0]
    }

    setErrors(thisStepErrors)
    return Boolean(Object.keys(thisStepErrors).length)
  }, [validate])

  if (!eventsCount) {
    return (
      <Wrapper>
        <Typography variant="h4" gutterBottom>
          {t('pages.applications.volunteering.title')}
        </Typography>
        <Typography>{t('pages.applications.myBag.empty')}</Typography>
        <ActionButtons>
          <Button href="/events">{t('pages.applications.myBag.activitiesLink')}</Button>
        </ActionButtons>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Typography variant="h4" gutterBottom>
        {t('pages.applications.volunteering.title')}
      </Typography>
      <Grid container spacing={2}>
        <Grid container item spacing={2} direction="row" alignItems="flex-end">
          <Grid item sm={4}>
            <Typography variant="h6" gutterBottom>
              {t('pages.applications.volunteering.emergencyContact')}
            </Typography>

            <TextInput
              type="input"
              label={t('pages.applications.volunteering.emergencyContact.name')}
              value={applicationData.emergencyContact?.name}
              onChange={(e) =>
                setApplicationData.emergencyContact.name((e.target as HTMLInputElement).value)
              }
              required
            />
          </Grid>
          <Grid item sm={4}>
            <TextInput
              type="email"
              label={t('pages.applications.volunteering.emergencyContact.email')}
              value={applicationData.emergencyContact?.email}
              onChange={(e) =>
                setApplicationData.emergencyContact.email((e.target as HTMLInputElement).value)
              }
              required
            />
          </Grid>
          <Grid item sm={4}>
            <TextInput
              type="tel"
              label={t('pages.applications.volunteering.emergencyContact.phone')}
              value={applicationData.emergencyContact?.phone}
              onChange={(e) =>
                setApplicationData.emergencyContact.phone((e.target as HTMLInputElement).value)
              }
              required
              error={t(errors['emergencyContactPhone'])}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2} direction="row" alignItems="flex-end">
          <Grid item sm={4}>
            <Typography variant="h6" gutterBottom>
              {t('pages.applications.volunteering.languages')}
              <Button color="primary" onClick={addLanguage} sx={{ ml: 2 }}>
                + {t('pages.applications.volunteering.languages.add')}
              </Button>
            </Typography>
          </Grid>
        </Grid>
        {applicationData.languages?.map((language, index) => {
          return (
            <Grid key={index} container item spacing={2} direction="row">
              <Grid item sm={4}>
                <Select
                  options={Object.keys(Languages).map((languageId) => {
                    return {
                      // @ts-ignore
                      id: Languages[languageId],
                      // @ts-ignore
                      name: Languages[languageId],
                    }
                  })}
                  value={language.language}
                  onChange={(e) => updateLanguageName(index, (e.target as HTMLInputElement).value)}
                  required
                >
                  {t('pages.applications.volunteering.languages.language')}
                </Select>
              </Grid>
              <Grid item sm={4}>
                <Select
                  options={Object.keys(LanguageLevel).map((languageLevelId) => {
                    return {
                      // @ts-ignore
                      id: LanguageLevel[languageLevelId],
                      // @ts-ignore
                      name: LanguageLevel[languageLevelId],
                    }
                  })}
                  value={language.level}
                  onChange={(e) => updateLanguageLevel(index, (e.target as HTMLInputElement).value)}
                  required
                >
                  {t('pages.applications.volunteering.languages.level')}
                </Select>
              </Grid>
              <Grid item sm={2}>
                <Button color="error" onClick={() => removeLanguage(index)}>
                  {t('pages.applications.volunteering.languages.remove')}
                </Button>
              </Grid>
            </Grid>
          )
        })}
        <Grid container item spacing={2} direction="row" alignItems="flex-end">
          <Grid item sm={12}>
            <Typography variant="h6" color="secondary" gutterBottom>
              {t('pages.applications.volunteering.englishSection')}
            </Typography>
            <TextInput
              type="input"
              label="Volunteer experiences and general skills"
              value={applicationData.volunteerExperiences}
              onChange={(e) => {
                setErrors(({ volunteerExperiences, ...newErrors }) => newErrors)
                setApplicationData.volunteerExperiences((e.target as HTMLInputElement).value)
              }}
              required
              multiline
              error={t(errors['volunteerExperiences'])}
            />
          </Grid>
          <Grid item sm={12}>
            <TextInput
              type="input"
              label="Why do you wish to take part in a volunteer project? (min. 350 characters without spaces)"
              value={applicationData.motivation}
              onChange={(e) => {
                setErrors(({ motivation, ...newErrors }) => newErrors)
                setApplicationData.motivation((e.target as HTMLInputElement).value)
              }}
              required
              multiline
              helperMessage={`${
                applicationData.motivation?.replace(/\s/g, '').length || 0
              } characters out of 350`}
              error={t(errors['motivation'])}
            />
          </Grid>
          <Grid item sm={12}>
            <TextInput
              type="input"
              label="General remarks"
              value={applicationData.generalRemarks}
              onChange={(e) =>
                setApplicationData.generalRemarks((e.target as HTMLInputElement).value)
              }
              multiline
            />
          </Grid>
          <Grid item sm={12}>
            <TextInput
              type="input"
              label="Remarks on health"
              value={applicationData.healthRemarks}
              onChange={(e) =>
                setApplicationData.healthRemarks((e.target as HTMLInputElement).value)
              }
              multiline
            />
          </Grid>
        </Grid>
      </Grid>
      <ActionButtons>
        <Button color="secondary" variant="outlined" onClick={goToPreviousFormPage}>
          {t('pages.applications.volunteering.backAction')}
        </Button>
        <Button
          color="primary"
          onClick={() => {
            const hasErrors = validateVolunteering()

            if (!hasErrors) {
              goToNextFormPage()
            }
          }}
        >
          {t('pages.applications.volunteering.nextAction')}
        </Button>
      </ActionButtons>
    </Wrapper>
  )
}

export default Volunteering
