import { useCallback, useMemo, useState } from 'react'

import { EventSearch, EventTypeCode } from '../../../types/event'
import { Grid, Typography } from '@mui/material'
import Snackbar from '../../ui/Snackbar'
import useFetchData from '../../../hooks/useFetchData'
import SearchForm from './SearchForm'
import Results from './Results'
import useTranslate from '../../../utils/translations/useTranslate'

type Props = {
  type: 'volunteer' | 'education'
}

const EventsSearchList = ({ type }: Props) => {
  const { t } = useTranslate()
  const [text, setText] = useState<string>('')
  const [from, setFrom] = useState<string>('')
  const [to, setTo] = useState<string>('')
  const [countries, setCountries] = useState<string[]>([])
  const [types, setTypes] = useState<string[]>([])
  const [age, setAge] = useState<string>('')
  const [snackbarMessage, setSnackbarMessage] = useState<string>('')
  const [page, setPage] = useState<number>(1)

  const eventTypeCodes: EventTypeCode[] = useMemo(() => {
    if (type === 'volunteer') {
      return [
        EventTypeCode.WeekendVolunteering,
        EventTypeCode.ESC,
        EventTypeCode.SlovakCamp,
        EventTypeCode.AbroadCamp,
        EventTypeCode.LMTV,
        EventTypeCode.VirtualCamp,
      ]
    }

    return [
      EventTypeCode.LeadersEdu,
      EventTypeCode.OneDayEdu,
      EventTypeCode.YouthExchangesEdu,
      EventTypeCode.OutgoingEdu,
    ]
  }, [type])

  const {
    data: searchEventsData,
    isLoading,
    isError,
  } = useFetchData<EventSearch>('/v1/events', {
    query: text,
    dateFrom: from,
    dateTo: to,
    countries,
    eventTypes: types,
    age,
    page,
    eventTypeCodes,
  })

  const closeSuccessSnackbar = useCallback(() => {
    setSnackbarMessage('')
  }, [])

  return (
    <>
      <Snackbar variant="success" isOpen={Boolean(snackbarMessage)} onClose={closeSuccessSnackbar}>
        {snackbarMessage}
      </Snackbar>
      <Typography variant="h4">{t('pages.events.search.title')}</Typography>
      <Typography color="secondary" gutterBottom>
        {t('pages.events.search.foundCountBeforeNumber')} {searchEventsData?.count || 0}{' '}
        {t('pages.events.search.foundCountAfterNumber')}
      </Typography>

      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item md={7} lg={8}>
          <Results
            events={searchEventsData?.events}
            pageCount={searchEventsData?.pageCount || 0}
            isLoading={isLoading}
            isError={isError}
            setSnackbarMessage={setSnackbarMessage}
            page={page}
            setPage={setPage}
          />
        </Grid>
        <Grid item md={5} lg={4}>
          <SearchForm
            searchType={type}
            text={text}
            setText={setText}
            from={from}
            setFrom={setFrom}
            to={to}
            setTo={setTo}
            countries={countries}
            setCountries={setCountries}
            types={types}
            setTypes={setTypes}
            age={age}
            setAge={setAge}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default EventsSearchList
