import { ReactComponent as Binoculars } from '../../assets/logo/binoculars.svg'
import Button from '../ui/Button'
import { Grid, Typography } from '@mui/material'
import useTranslate from '../../utils/translations/useTranslate'

const Events = () => {
  const { t } = useTranslate()

  return (
    <Grid container spacing={2} sx={{ paddingY: 6 }}>
      <Grid item sm={12} md={10}>
        <Typography variant="h4" gutterBottom>
          {t('homepage.events.title')}
        </Typography>
        <Typography variant="body1" component="div" sx={{ mb: 2 }}>
          <ul>
            <li>{t('homepage.events.bullet1')}</li>
            <li>{t('homepage.events.bullet2')}</li>
            <li>{t('homepage.events.bullet3')}</li>
            <li>{t('homepage.events.bullet4')}</li>
          </ul>
        </Typography>

        <Button href="/events" color="info">
          {t('homepage.events.goToEventsLink')}
        </Button>
        <Button
          color="secondary"
          variant="outlined"
          href="/frequently-asked-questions"
          sx={{ ml: 1 }}
        >
          {t('pages.events.dontKnow.goToFaq')}
        </Button>
      </Grid>
      <Grid
        item
        sm={12}
        md={2}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
      >
        <Binoculars width="150" />
      </Grid>
    </Grid>
  )
}

export default Events
