import { Box, Card, CardContent, CardMedia, Grid, Typography } from '@mui/material'
import styled from 'styled-components'
import { IBAN } from '../../constants'
import useTranslate from '../../utils/translations/useTranslate'

const StyledCardMedia = styled(CardMedia)`
  img {
    width: 150px;
    height: 150px;
  }
`

type ContactArticles =
  | 'kontakty_adresa'
  | 'kontakty_cislo'
  | 'kontakty_ico'
  | 'kontakty_ucet'
  | 'kontakty_otvaracie_description'
  | 'kontakty_otvaracie_pon'
  | 'kontakty_otvaracie_uto'
  | 'kontakty_otvaracie_str'
  | 'kontakty_otvaracie_stv'
  | 'kontakty_otvaracie_pia'
  | 'kontakty_otvaracie_vik'
  | 'kontakty_person_incoming_name'
  | 'kontakty_person_incoming_number'
  | 'kontakty_person_incoming_description'
  | 'kontakty_person_incoming_photo'
  | 'kontakty_person_incoming_description2'
  | 'kontakty_person_outgoing_name'
  | 'kontakty_person_outgoing_number'
  | 'kontakty_person_outgoing_description'
  | 'kontakty_person_outgoing_photo'
  | 'kontakty_person_outgoing_description2'
  | 'kontakty_person_eds_name'
  | 'kontakty_person_eds_number'
  | 'kontakty_person_eds_description'
  | 'kontakty_person_eds_description2'
  | 'kontakty_person_eds_photo'

export type ContactArticle = {
  sk: string
  en: string
}

type Props = {
  contacts: Record<ContactArticles, ContactArticle>
}

const Contacts = ({ contacts }: Props) => {
  const { t } = useTranslate()

  return (
    <>
      <Grid container sx={{ minHeight: 450, paddingTop: 4, marginBottom: 8 }} spacing={2}>
        <Grid item sm={12} md={7}>
          <Box>
            <Typography variant="h4" gutterBottom>
              {t('pages.contacts.title')}
            </Typography>
            <Typography variant="h6" gutterBottom>
              {t('pages.contacts.address')}
            </Typography>
            <Typography
              gutterBottom
              dangerouslySetInnerHTML={{ __html: contacts.kontakty_adresa?.sk }}
            />
            <Typography>
              {t('pages.contacts.phone')}:{' '}
              <span dangerouslySetInnerHTML={{ __html: contacts.kontakty_cislo?.sk }} />
            </Typography>
            <Typography>
              {t('pages.contacts.identificationNumber')}:{' '}
              <span dangerouslySetInnerHTML={{ __html: contacts.kontakty_ico?.sk }} />
            </Typography>
            <Typography gutterBottom>
              {t('pages.contacts.accountNumber')}: {IBAN} (
              {t('pages.helpUs.financially.direct.accountInBank')})
            </Typography>

            <Card sx={{ display: 'flex', my: 2 }}>
              <StyledCardMedia
                dangerouslySetInnerHTML={{
                  __html: contacts.kontakty_person_incoming_photo?.sk,
                }}
              />
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                  <Typography component="div" variant="h5">
                    Incoming -{' '}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: contacts.kontakty_person_incoming_name?.sk,
                      }}
                    />
                  </Typography>
                  <Typography variant="subtitle1" color="text.secondary" component="div">
                    in@inex.sk,{' '}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: contacts.kontakty_person_incoming_number?.sk,
                      }}
                    />
                  </Typography>
                </CardContent>

                <Typography sx={{ pl: 2, pb: 1 }}>
                  {t('pages.contacts.askMeAbout')}:
                  <span
                    dangerouslySetInnerHTML={{
                      __html: contacts.kontakty_person_incoming_description2?.sk,
                    }}
                  />
                </Typography>
              </Box>
            </Card>
            <Card sx={{ display: 'flex', my: 2 }}>
              <StyledCardMedia
                dangerouslySetInnerHTML={{
                  __html: contacts.kontakty_person_outgoing_photo?.sk,
                }}
              />
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                  <Typography component="div" variant="h5">
                    Outgoing -{' '}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: contacts.kontakty_person_outgoing_name?.sk,
                      }}
                    />
                  </Typography>
                  <Typography variant="subtitle1" color="text.secondary" component="div">
                    out@inex.sk,{' '}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: contacts.kontakty_person_outgoing_number?.sk,
                      }}
                    />
                  </Typography>
                </CardContent>

                <Typography sx={{ pl: 2, pb: 1 }}>
                  {t('pages.contacts.askMeAbout')}:
                  <span
                    dangerouslySetInnerHTML={{
                      __html: contacts.kontakty_person_outgoing_description2?.sk,
                    }}
                  />
                </Typography>
              </Box>
            </Card>
            <Card sx={{ display: 'flex', my: 2 }}>
              <StyledCardMedia
                dangerouslySetInnerHTML={{
                  __html: contacts.kontakty_person_eds_photo?.sk,
                }}
              />
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                  <Typography component="div" variant="h5">
                    ESC -{' '}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: contacts.kontakty_person_eds_name?.sk,
                      }}
                    />
                  </Typography>
                  <Typography variant="subtitle1" color="text.secondary" component="div">
                    esc@inex.sk,{' '}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: contacts.kontakty_person_eds_number?.sk,
                      }}
                    />
                  </Typography>
                </CardContent>

                <Typography sx={{ pl: 2, pb: 1 }}>
                  {t('pages.contacts.askMeAbout')}:
                  <span
                    dangerouslySetInnerHTML={{
                      __html: contacts.kontakty_person_eds_description2?.sk,
                    }}
                  />
                </Typography>
              </Box>
            </Card>
          </Box>
        </Grid>
        <Grid item sm={12} md={5} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography variant="h6" gutterBottom>
            {t('pages.contacts.openingHours')}:
          </Typography>
          <Typography
            dangerouslySetInnerHTML={{ __html: contacts.kontakty_otvaracie_description?.sk }}
          />
          <table style={{ width: '100%', border: '1px solid rgba(34,36,38,.15)' }}>
            <tbody>
              <tr>
                <td style={{ padding: 8 }}>{t('pages.contacts.monday')}</td>
                <td>
                  <Typography
                    dangerouslySetInnerHTML={{ __html: contacts.kontakty_otvaracie_pon?.sk }}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ padding: 8 }}>{t('pages.contacts.tuesday')}</td>
                <td>
                  <Typography
                    dangerouslySetInnerHTML={{ __html: contacts.kontakty_otvaracie_uto?.sk }}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ padding: 8 }}>{t('pages.contacts.wednesday')}</td>
                <td>
                  <Typography
                    dangerouslySetInnerHTML={{ __html: contacts.kontakty_otvaracie_str?.sk }}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ padding: 8 }}>{t('pages.contacts.thursday')}</td>
                <td>
                  <Typography
                    dangerouslySetInnerHTML={{ __html: contacts.kontakty_otvaracie_stv?.sk }}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ padding: 8 }}>{t('pages.contacts.friday')}</td>
                <td>
                  <Typography
                    dangerouslySetInnerHTML={{ __html: contacts.kontakty_otvaracie_pia?.sk }}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ padding: 8 }}>{t('pages.contacts.weekendDays')}</td>
                <td>
                  <Typography
                    dangerouslySetInnerHTML={{ __html: contacts.kontakty_otvaracie_vik?.sk }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      </Grid>
    </>
  )
}

export default Contacts
