import { Grid } from '@mui/material'
import { muiTheme } from '../../theme'
import { Post } from '../../types/media'
import Breadcrumbs from '../Breadcrumbs'
import ContentContainer from '../ui/ContentContainer'
import Menu from './Menu'
import PostList from './PostList'

const MOCK_LOGOS: Post[] = []

const Logos = () => {
  return (
    <div style={{ width: '100%' }}>
      <ContentContainer background={muiTheme.palette.background.paper}>
        <Breadcrumbs />
        <Grid container sx={{ minHeight: 450, paddingTop: 4, marginBottom: 8 }}>
          <Grid item sm={12} md={8}>
            <PostList posts={MOCK_LOGOS} />
          </Grid>
          <Grid item sm={12} md={4}>
            <Menu />
          </Grid>
        </Grid>
      </ContentContainer>
    </div>
  )
}

export default Logos
