import styled from 'styled-components'
import ContentContainer from '../../ui/ContentContainer'
import Partners from './Partners'
import MailingListSubscriptionForm from './MailingListSubscriptionForm'
import PageMap from './PageMap'

import { ReactComponent as InexakLying } from '../../../assets/logo/lying.svg'
import FamilySvg from '../../../assets/logo/family.svg'
import useTranslate from '../../../utils/translations/useTranslate'
import { Grid, Typography } from '@mui/material'
import Link from '../../ui/Link'
import SocialNetworks from './SocialNetworks'

const StyledContentContainer = styled(ContentContainer)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 25px 15% 25px 15%;
  gap: 25px;
  position: relative;
`

const StyledTopBorder = styled.div`
  position: absolute;
  top: -38px;
  left: 0;
  width: 100%;
  height: 40px;
  background-image: url(${FamilySvg});
  background-size: 163px;
`

const Footer = () => {
  const { t } = useTranslate()

  return (
    <StyledContentContainer>
      <StyledTopBorder />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ fontWeight: 'bold' }} gutterBottom>
            {t('layout.footer.partners.title')}
          </Typography>
          <Partners />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'center' }}
        >
          <SocialNetworks />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ fontWeight: 'bold' }} gutterBottom>
            {t('layout.footer.mailing.title')}
          </Typography>
          <MailingListSubscriptionForm />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ fontWeight: 'bold' }} gutterBottom>
            {t('layout.footer.pageMap.title')}
          </Typography>
          <PageMap />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', gap: 2, alignItems: 'flex-start' }}
        >
          <InexakLying width="100" height="30" />
          <div>
            INEX&nbsp;Slovakia&nbsp;2022, Developed by{' '}
            <Link
              color="#000000"
              href="https://www.linkedin.com/in/filip-sakala-8b43a1192/"
              target="_blank"
            >
              Filip&nbsp;Sakala
            </Link>
            . <br />
            <Link color="#000000" href="/contacts">
              {t('layout.footer.foundBugTitle')}
            </Link>
          </div>
        </Grid>
      </Grid>
    </StyledContentContainer>
  )
}

export default Footer
