import { Box, Typography } from '@mui/material'
import { ReactComponent as Boat } from '../../assets/logo/boat.svg'
import useTranslate from '../../utils/translations/useTranslate'

const AboutInex = () => {
  const { t } = useTranslate()

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        {t('pages.aboutUs.aboutInex.title')}
      </Typography>

      <Typography variant="h6" component="p" gutterBottom>
        <b>{t('pages.aboutUs.aboutInex.description1.1')}</b>{' '}
        {t('pages.aboutUs.aboutInex.description1.2')}{' '}
        <b>{t('pages.aboutUs.aboutInex.description1.3')}</b>{' '}
        {t('pages.aboutUs.aboutInex.description1.4')}{' '}
        <b>{t('pages.aboutUs.aboutInex.description1.5')}</b>.
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'center', paddingY: 2 }}>
        <Boat />
      </Box>
      <Typography variant="body1" component="p" gutterBottom>
        {t('pages.aboutUs.aboutInex.description2.1')}{' '}
        <b>{t('pages.aboutUs.aboutInex.description2.2')}</b>{' '}
        {t('pages.aboutUs.aboutInex.description2.3')}{' '}
        <b>{t('pages.aboutUs.aboutInex.description2.4')}</b>
      </Typography>

      <Typography variant="body1" component="p" gutterBottom>
        {t('pages.aboutUs.aboutInex.description3')}
      </Typography>
    </Box>
  )
}

export default AboutInex
