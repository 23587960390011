import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'

import Button from '../ui/Button'
import { Box, Grid, Skeleton, Typography } from '@mui/material'
import { Experience } from '../../types/baseConfig'
import LoadingOverlay from '../LoadingOverlay'
import useTranslate from '../../utils/translations/useTranslate'
import { muiTheme } from '../../theme'

const JUMP_TO_NEXT_FRAME_INTERVAL = 5000

type Props = {
  experiences?: Experience[]
  isLoading: boolean
  isError: boolean
}

const StyledDot = styled.div<{ isSelected: boolean }>`
  width: 12px;
  height: 12px;
  border: 2px solid black;
  border-radius: 50%;
  cursor: pointer;

  background: ${({ isSelected }) => (isSelected ? muiTheme.palette.info.main : 'initial')};

  &:hover {
    background: ${muiTheme.palette.info.main};
    transform: scale(1.05);
  }
`

type SelectDotProps = { isSelected: boolean; onSelect: () => void }
const SelectDot = ({ isSelected, onSelect }: SelectDotProps) => {
  return <StyledDot isSelected={isSelected} onClick={onSelect} />
}

const PrevNextButton = styled.div`
  display: flex;
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background: ${muiTheme.palette.info.main};
  }
`

const StyledImage = styled.img`
  border-radius: 10px;
  width: 200px;
  height: 200px;
  max-width: 200px;
  object-fit: contain;
  object-position: 0 0;
`

const Experiences = ({ experiences = [], isLoading, isError }: Props) => {
  const { t } = useTranslate()
  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  const [isIntervalPaused, setIsIntervalPaused] = useState<boolean>(false)
  const selectedExperience = experiences[selectedIndex]

  const handleSelectIndex = useCallback(
    (index: number) => {
      if (selectedIndex === index || selectedIndex < 0 || selectedIndex >= experiences.length) {
        return
      }

      setSelectedIndex(index)
    },
    [selectedIndex, experiences.length]
  )

  const handleSetPrevious = useCallback(() => {
    if (selectedIndex <= 0) {
      setSelectedIndex(experiences.length - 1)
      return
    }

    setSelectedIndex(selectedIndex - 1)
  }, [selectedIndex, experiences.length])

  const handleSetNext = useCallback(() => {
    if (selectedIndex >= experiences.length - 1) {
      setSelectedIndex(0)
      return
    }

    setSelectedIndex(selectedIndex + 1)
  }, [selectedIndex, experiences.length])

  useEffect(() => {
    const interval = setInterval(() => {
      if (isIntervalPaused) {
        return
      }
      handleSetNext()
    }, JUMP_TO_NEXT_FRAME_INTERVAL)

    return () => clearInterval(interval)
  }, [isIntervalPaused, handleSetNext])

  if (isLoading) {
    return <LoadingOverlay />
  }

  if (!experiences || isError) {
    return <>{t('errors.loading')}</>
  }

  if (!selectedExperience) {
    return null
  }

  return (
    <Grid
      container
      spacing={2}
      sx={{ paddingY: 6, userSelect: 'none', minHeight: 260 }}
      onMouseEnter={() => setIsIntervalPaused(true)}
      onMouseLeave={() => setIsIntervalPaused(false)}
      onTouchStart={() => setIsIntervalPaused(true)}
      onTouchEnd={() => setIsIntervalPaused(false)}
    >
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          width: '100%',
          gap: 2,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h4">{t('pages.homepage.volunteerExperiences.title')}</Typography>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <PrevNextButton onClick={handleSetPrevious}>
            <ChevronLeft />
          </PrevNextButton>
          {experiences.map((_, index) => (
            <SelectDot
              key={index}
              isSelected={selectedIndex === index}
              onSelect={() => handleSelectIndex(index)}
            />
          ))}
          <PrevNextButton onClick={handleSetNext}>
            <ChevronRight />
          </PrevNextButton>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4} lg={3}>
            {selectedExperience.imgSrc ? (
              <StyledImage
                src={`${
                  process.env.REACT_APP_ENV === 'DEV' ? 'http://localhost:3000' : 'https://inex.sk'
                }${selectedExperience.imgSrc}`}
                alt={selectedExperience.title}
                loading="lazy"
              />
            ) : (
              <Skeleton variant="rectangular" width={200} height={200} animation={false} />
            )}
          </Grid>
          <Grid item xs={12} md={8} lg={9}>
            <Typography variant="h6" gutterBottom>
              {selectedExperience.title}
            </Typography>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              {selectedExperience.subtitle}
            </Typography>
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: selectedExperience.description,
              }}
              sx={{
                mb: 2,
                height: 75,
                overflow: 'hidden',
                whiteSpace: 'pre-line',
                textOverflow: 'ellipsis',
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button href={`/media/blog/${selectedExperience.blogId}`} color="info">
              Prečítaj si blog
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Experiences
