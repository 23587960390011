import { CircularProgress, Typography } from '@mui/material'
import styled from 'styled-components'
import useTranslate from '../utils/translations/useTranslate'

const Loading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0.9;
  height: 100%;
  width: 100%;
`

const LoadingOverlay = () => {
  const { t } = useTranslate()

  return (
    <Loading>
      <CircularProgress />

      <Typography sx={{ mt: 2, letterSpacing: '0.1em' }}>{t('loading')}</Typography>
    </Loading>
  )
}

export default LoadingOverlay
