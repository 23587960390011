import { Grid, useMediaQuery, Menu, MenuItem, Divider } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import styled from 'styled-components'
import ContentContainer from '../../ui/ContentContainer'
import Button from '../../ui/Button'
import BagButton from './BagButton'
import Logo from './Logo'
import MenuButton from './MenuButton'
import MenuLink from './MenuLink'
import { useCallback, useState } from 'react'
import useTranslate from '../../../utils/translations/useTranslate'

const MenuLinks = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 20px;
  margin-top: -25px;
  margin-bottom: 25px;
`

const MenuButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
`

const Header = () => {
  const theme = useTheme()
  const isLargeScreenAndUp = useMediaQuery(theme.breakpoints.up('lg'))
  const isMediumScreenAndUp = useMediaQuery(theme.breakpoints.up('md'))
  const [menuAnchorElement, setMenuAnchorElement] = useState<null | HTMLElement>(null)
  const { language, setLanguage, t } = useTranslate()

  const openMenu = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorElement(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setMenuAnchorElement(null)
  }, [])

  // Display condensed menu
  if (!isLargeScreenAndUp) {
    return (
      <ContentContainer>
        <Grid
          container
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            minHeight: 102,
            mt: 2,
            mb: 2,
          }}
          spacing={2}
        >
          <Grid item>
            <Logo />
          </Grid>
          <Grid item>
            <Button onClick={openMenu} sx={{ minWidth: 220, height: 54 }}>
              Menu
            </Button>
            <Menu
              open={Boolean(menuAnchorElement)}
              anchorEl={menuAnchorElement}
              onClose={handleClose}
            >
              <MenuItem component="a" href="/contacts" sx={{ width: 220 }}>
                {t('layout.header.menu.contacts')}
              </MenuItem>
              <MenuItem component="a" href="/frequently-asked-questions">
                {t('layout.header.menu.faq')}
              </MenuItem>
              <MenuItem component="a" href="" onClick={setLanguage}>
                {language}
              </MenuItem>
              <MenuItem
                component="a"
                href={`${process.env.REACT_APP_LEGACY_SERVER_ENDPOINT}/my-account`}
              >
                {t('layout.header.menu.myAccount')}
              </MenuItem>
              <Divider />
              <MenuItem component="a" href="/about-us">
                {t('layout.header.menu.aboutUs')}
              </MenuItem>
              <MenuItem component="a" href="/events">
                {t('layout.header.menu.events')}
              </MenuItem>
              <MenuItem component="a" href="/help-us">
                {t('layout.header.menu.helpUs')}
              </MenuItem>
              <MenuItem component="a" href="/media/blog">
                {t('layout.header.menu.media')}
              </MenuItem>
              {!isMediumScreenAndUp && (
                <div>
                  <Divider />
                  <MenuItem sx={{ display: 'flex', justifyContent: 'center' }}>
                    <BagButton />
                  </MenuItem>
                </div>
              )}
            </Menu>
          </Grid>
          {isMediumScreenAndUp && (
            <Grid item>
              <BagButton />
            </Grid>
          )}
        </Grid>
      </ContentContainer>
    )
  }

  // Display full menu from medium sized screen up
  return (
    <ContentContainer>
      <Grid container sx={{ width: '100%', minHeight: 152 }} spacing={2}>
        <Grid
          item
          md={2}
          lg={3}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
        >
          <Logo />
        </Grid>
        <Grid
          item
          md={8}
          lg={7}
          sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
        >
          <MenuLinks>
            <MenuLink href="/contacts">{t('layout.header.menu.contacts')}</MenuLink>
            <MenuLink href="/frequently-asked-questions">{t('layout.header.menu.faq')}</MenuLink>
            <MenuLink href="" onClick={setLanguage}>
              {language}
            </MenuLink>
            <MenuLink href={`${process.env.REACT_APP_LEGACY_SERVER_ENDPOINT}/my-account`} bold>
              {t('layout.header.menu.myAccount')}
            </MenuLink>
          </MenuLinks>
          <MenuButtons>
            <MenuButton href="/about-us">{t('layout.header.menu.aboutUs')}</MenuButton>
            <MenuButton href="/events">{t('layout.header.menu.events')}</MenuButton>
            <MenuButton href="/help-us">{t('layout.header.menu.helpUs')}</MenuButton>
            <MenuButton href="/media/blog">{t('layout.header.menu.media')}</MenuButton>
          </MenuButtons>
        </Grid>
        <Grid
          item
          md={2}
          lg={2}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
        >
          <BagButton />
        </Grid>
      </Grid>
    </ContentContainer>
  )
}

export default Header
