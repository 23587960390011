import { Alert, Snackbar as MuiSnackbar } from '@mui/material'

type Props = {
  isOpen?: boolean
  variant?: 'error' | 'warning' | 'info' | 'success'
  onClose?: () => void
  action?: React.ReactNode
  children?: React.ReactNode
}

const Snackbar = ({ variant = 'info', isOpen = false, onClose, action, children }: Props) => {
  return (
    <MuiSnackbar open={isOpen} autoHideDuration={6000} action={action} onClose={onClose}>
      <Alert onClose={onClose} severity={variant} sx={{ width: '100%' }}>
        {children}
      </Alert>
    </MuiSnackbar>
  )
}

export default Snackbar
