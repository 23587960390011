import { ExpandMore } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import useTranslate from '../../utils/translations/useTranslate'
import TextInput from '../ui/TextInput'
import useFaqQuestions from './useFaqQuestions'

type Props = {
  filter: string
}

export const FAQMenu = ({
  filter,
  setFilter,
}: {
  filter: string
  setFilter: (v: string) => void
}) => {
  const { t } = useTranslate()

  return (
    <Paper sx={{ p: 2, mx: 2 }}>
      <TextInput
        type="input"
        label={t('pages.faq.menu.search')}
        value={filter}
        onChange={(e) => setFilter((e.target as HTMLInputElement).value)}
      />
    </Paper>
  )
}

const FAQ = ({ filter }: Props) => {
  const questions = useFaqQuestions(filter)

  return (
    <Stack spacing={2}>
      {questions.map(({ question, answer }, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography component="div" variant="h5">
              {question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography component="div" gutterBottom>
              {answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Stack>
  )
}

export default FAQ
