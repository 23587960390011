import useEventApplicationContext from '../../../contexts/useEventApplicationContext'
import Button from '../../ui/Button'
import { Typography } from '@mui/material'
import useTranslate from '../../../utils/translations/useTranslate'

const BagButton = () => {
  const { eventsCount } = useEventApplicationContext()
  const { t } = useTranslate()

  return (
    <Button href="/my-bag" color="secondary" sx={{ display: 'flex', gap: 1 }}>
      <Typography variant="h4">{eventsCount}</Typography>
      {t('layout.header.menu.bag')}
    </Button>
  )
}

export default BagButton
