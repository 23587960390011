import { useCallback, useEffect, useMemo, useState } from 'react'
import { STORAGE_EVENTS, useLocalStorage } from '../storage/useLocalStorage'
import { EventItem, EventTypeCode } from '../types/event'
import { ApplicationType, ApplicationData, ERROR_CODES } from '../types/application'
import { EventApplication, EventApplicationContext } from './EventApplicationContext'
import useApplicationData from './useApplicationData'
import { determineApplicationType } from '../components/applications/utils'
import fetchData from '../storage/fetchData'

type Props = {
  children: React.ReactNode
}

type EventTypesWithEvents = Record<EventTypeCode, EventItem[]>

export const BASIC_INFO_FIELDS = ['name', 'surname', 'email', 'phone']
export const SHORT_WORKCAMPS_BASIC_INFO_FIELDS = ['birthDate', 'permanentAddress']
export const WORKCAMPS_BASIC_INFO_FIELDS = [
  'birthPlace',
  'nationality',
  'sex',
  'employmentType',
  'educationType',
  'contactAddress',
]

const PHONE_FORMAT_REGEX = /^\+(\d{1,3}\s?){3,}\s?\d{1,3}$/

export const EventApplicationContextProvider = ({ children }: Props) => {
  const { storageValue, setStorageValue } = useLocalStorage(STORAGE_EVENTS, {})
  const { applicationData, setApplicationData } = useApplicationData()

  const [eventsByEventType, setEventsByEventType] = useState<EventTypesWithEvents>(storageValue)

  const addEvent = useCallback((event: EventItem) => {
    setEventsByEventType((prevEvents) => {
      const newEvents = { ...prevEvents }
      if (!newEvents[event.eventType as EventTypeCode]) {
        newEvents[event.eventType as EventTypeCode] = []
      }
      newEvents[event.eventType as EventTypeCode].push(event)

      return newEvents
    })
  }, [])

  const removeEvent = useCallback((eventTypeCode: EventTypeCode | string, removeId: string) => {
    setEventsByEventType((prevEvents) => {
      const { [eventTypeCode as EventTypeCode]: thisTypeEvents, ...otherEventTypes } = prevEvents

      if (!thisTypeEvents) {
        return prevEvents
      }

      const eventEntryIndex = thisTypeEvents.findIndex((event) => event.id === removeId)

      if (eventEntryIndex === -1) {
        return prevEvents
      }

      // remove element on index
      thisTypeEvents.splice(eventEntryIndex, 1)

      // there's no more event of that type - remove also the type
      if (!thisTypeEvents.length) {
        return otherEventTypes as EventTypesWithEvents
      }

      return { ...otherEventTypes, [eventTypeCode]: thisTypeEvents } as EventTypesWithEvents
    })
  }, [])

  const removeAllEvents = useCallback(() => {
    setEventsByEventType({} as EventTypesWithEvents)
  }, [])

  const isInBag = useCallback(
    (eventTypeCode: EventTypeCode | string, eventId: string) =>
      (eventsByEventType[eventTypeCode as EventTypeCode] || []).some(
        (event) => event.id === eventId
      ),
    [eventsByEventType]
  )

  const applicationType = useMemo(
    () => determineApplicationType(Object.keys(eventsByEventType) as EventTypeCode[]),
    [eventsByEventType]
  )

  const isFieldVisible = useCallback(
    (fieldName: string): boolean => {
      if (applicationType === ApplicationType.BASIC) {
        return BASIC_INFO_FIELDS.includes(fieldName)
      }
      if (applicationType === ApplicationType.SHORT) {
        return (
          BASIC_INFO_FIELDS.includes(fieldName) ||
          SHORT_WORKCAMPS_BASIC_INFO_FIELDS.includes(fieldName)
        )
      }

      return true
    },
    [applicationType]
  )

  const validateApplication = useCallback((): Record<string, ERROR_CODES[]> => {
    const errors: Record<string, ERROR_CODES[]> = {}

    // has any events?
    if (!Object.values(eventsByEventType).length) {
      errors.general = [ERROR_CODES.ZERO_EVENTS]
    }

    Object.entries(applicationData).forEach(([key, value]) => {
      if (!value) {
        errors[key] = [ERROR_CODES.FIELD_MANDATORY]
      }
    })

    if (applicationData.motivation && applicationData.motivation.replace(/\s/g, '').length < 350) {
      errors.motivation = [ERROR_CODES.MOTIVATION_LENGTH]
    }

    // validate phone format
    if (applicationData.phone && !PHONE_FORMAT_REGEX.test(applicationData.phone)) {
      errors.phone = [ERROR_CODES.PHONE_NUMBER_FORMAT]
    }
    if (
      applicationData.emergencyContact?.phone &&
      !PHONE_FORMAT_REGEX.test(applicationData.emergencyContact.phone)
    ) {
      errors.emergencyContactPhone = [ERROR_CODES.PHONE_NUMBER_FORMAT]
    }

    const eventTypes = Object.keys(eventsByEventType)
    const hasUncheckedEventTypesConditions = eventTypes.some(
      (code) => !applicationData.conditions[`eventType_${code}`]
    )
    if (!applicationData.conditions.general || hasUncheckedEventTypesConditions) {
      errors.conditions = [ERROR_CODES.UNCHECKED_CONDITIONS]
    }

    return errors
  }, [eventsByEventType, applicationData])

  // sync data with local storage
  useEffect(() => {
    setStorageValue(eventsByEventType)
  }, [eventsByEventType])

  const submitApplication = useCallback(async (): Promise<boolean> => {
    // Application contains some kind of validation error - do nothing
    const errors = validateApplication()
    if (Object.keys(errors).length) {
      return false
    }

    // Send events categorized by event types if needed
    // const eventIdsByTypes = Object.entries(eventsByEventType).reduce(
    //   (eventTypes: any, [type, events]) => {
    //     eventTypes[type] = events.map((event) => event.id)

    //     return eventTypes
    //   },
    //   {}
    // )

    const eventIds = Object.values(eventsByEventType)
      .flat()
      .map((event) => event.id)

    const dataToSend = Object.keys(applicationData)
      .filter((field) => isFieldVisible(field) || ['conditions'].includes(field))
      .reduce((group: any, field: any) => {
        group[field] = applicationData[field as keyof ApplicationData]

        return group
      }, {})

    // call API
    const fetchedData = await fetchData('POST', '/v1/applications/register', {
      events: eventIds,
      applicationType,
      ...dataToSend,
    })

    // if error -> return false
    if (fetchedData.error) {
      return false
    }

    // Cleanup on successful application submitting - you can probably keep the application data in cookies for the next application
    if (fetchedData.result === true) {
      removeAllEvents()
    }

    // if OK -> erase applicationData, return true
    return fetchedData.result
  }, [
    applicationData,
    eventsByEventType,
    validateApplication,
    applicationType,
    removeAllEvents,
    isFieldVisible,
  ])

  const eventsCount = useMemo(
    () => Object.values(eventsByEventType).flat().length,
    [eventsByEventType]
  )

  const moveEventUp = useCallback((eventTypeCode: EventTypeCode, event: EventItem) => {
    setEventsByEventType((prevEvents) => {
      if (!prevEvents[eventTypeCode]) {
        return prevEvents
      }

      const newEvents = [...prevEvents[eventTypeCode]]
      const currentEventIndex = newEvents.findIndex(({ id }) => id === event.id)

      if (currentEventIndex === -1 || currentEventIndex === 0) {
        return prevEvents
      }
      // removes
      newEvents.splice(currentEventIndex, 1)
      // adds on the new position
      newEvents.splice(currentEventIndex - 1, 0, event)

      return { ...prevEvents, [eventTypeCode]: newEvents }
    })
  }, [])

  const moveEventDown = useCallback((eventTypeCode: EventTypeCode, event: EventItem) => {
    setEventsByEventType((prevEvents) => {
      if (!prevEvents[eventTypeCode]) {
        return prevEvents
      }

      const newEvents = [...prevEvents[eventTypeCode]]
      const currentEventIndex = newEvents.findIndex(({ id }) => id === event.id)

      if (currentEventIndex === -1 || currentEventIndex === newEvents.length - 1) {
        return prevEvents
      }

      // removes
      newEvents.splice(currentEventIndex, 1)
      // adds on the new position
      newEvents.splice(currentEventIndex + 1, 0, event)

      return { ...prevEvents, [eventTypeCode]: newEvents }
    })
  }, [])

  const eventApplicationContext: EventApplication = {
    events: eventsByEventType,
    eventsCount,
    applicationData,
    setApplicationData,
    validate: validateApplication,
    add: addEvent,
    remove: removeEvent,
    removeAll: removeAllEvents,
    isInBag,
    applicationType,
    isFieldVisible,
    submitApplication,
    moveEventUp,
    moveEventDown,
  }

  return (
    <EventApplicationContext.Provider value={eventApplicationContext}>
      {children}
    </EventApplicationContext.Provider>
  )
}
