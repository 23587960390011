export type EventItem = {
  id: string
  eventType: string
  title: string
  country: string
  from: string
  to: string
  code: string
  types: string[]
}

export type EventDetail = EventItem & {
  description: {
    introduction: string
    type_of_work: string
    study_theme: string
    accomodation: string
    camp_advert: string
    language_description: string
    requirements: string
    location: string
    additional_camp_notes: string
    fees: string
  }
}

export type EventSearch = {
  events: EventItem[]
  count: number
  pageCount: number
}

export enum EventTypeCode {
  OneDayVolunteering = 'DAYVOL',
  WeekendVolunteering = 'WKNDVOL',
  ESC = 'ESC',
  SlovakCamp = 'SKCAMP',
  AbroadCamp = 'OUTCAMP',
  LMTV = 'LMTV',
  Archive = 'ARCH',
  VirtualCamp = 'VIRT',

  LeadersEdu = 'LEADEDU',
  OneDayEdu = 'DAYEDU',
  YouthExchangesEdu = 'YNGEDU',
  OutgoingEdu = 'OUTEDU',
  EmployeeEdu = 'EMPEDU',

  OfflinePromo = 'OFFPRM',

  MeetingsRms = 'MTGRMS',
  MeetingsAlliance = 'MTGALNC',
  MeetingsIuventa = 'MTGIUV',

  MemberApplication = 'MEMBER',
  Test = 'TEST',
}

export const OrderableEventTypes = [EventTypeCode.AbroadCamp]

export type EventType = {
  code: EventTypeCode
  title: string
  feesDescription: string
  applicationConditions: string
}
