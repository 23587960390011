import { Typography } from '@mui/material'
import styled from 'styled-components'
import { muiTheme } from '../../../theme'

const StyledMenuButton = styled.a`
  text-decoration: none;
  color: ${muiTheme.palette.text.primary};

  &:hover {
    text-decoration: underline;
    text-decoration-color: ${muiTheme.palette.info.main};
  }
`

type Props = {
  children: React.ReactNode
  href: string
}

const MenuButton = ({ children, href }: Props) => {
  return (
    <StyledMenuButton href={href}>
      <Typography variant="h6" sx={{ letterSpacing: '0.03em', textTransform: 'uppercase' }}>
        {children}
      </Typography>
    </StyledMenuButton>
  )
}

export default MenuButton
