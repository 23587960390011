import { Statistics } from '../components/homepage'
import AboutUs from '../components/homepage/AboutUs'
import Events from '../components/homepage/Events'
import Experiences from '../components/homepage/Experiences'
import InexRecommends from '../components/homepage/InexRecommends'
import ContentContainer from '../components/ui/ContentContainer'
import useFetchData from '../hooks/useFetchData'
import { muiTheme } from '../theme'
import { Config } from '../types/baseConfig'

const HomePage = () => {
  const { data: homepageData, isLoading, isError } = useFetchData<Config>('/v1/base/config')

  return (
    <div style={{ width: '100%' }}>
      <ContentContainer background={muiTheme.palette.background.paper}>
        <AboutUs />
      </ContentContainer>
      <ContentContainer>
        <Events />
      </ContentContainer>
      <ContentContainer background={muiTheme.palette.background.paper}>
        <InexRecommends
          events={homepageData?.recommendedEvents}
          isLoading={isLoading}
          isError={isError}
        />
      </ContentContainer>
      <ContentContainer>
        <Experiences
          experiences={homepageData?.experiences}
          isLoading={isLoading}
          isError={isError}
        />
      </ContentContainer>
      <ContentContainer background={muiTheme.palette.background.paper}>
        <Statistics statistics={homepageData?.statistics} isLoading={isLoading} isError={isError} />
      </ContentContainer>
    </div>
  )
}

export default HomePage
