import { useEffect, useMemo, useRef, useState } from 'react'
import { isEqual } from 'lodash'
import fetchData from '../storage/fetchData'

const useFetchData = <T>(fetchUrl: string, fetchParams: any = {}) => {
  const [data, setData] = useState<T | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const fetchParamsRef = useRef({})

  useEffect(() => {
    if ((data && isEqual(fetchParamsRef.current, fetchParams)) || isLoading || isError) {
      return
    }

    fetchParamsRef.current = fetchParams

    const fetchAsync = async () => {
      setIsLoading(true)
      const fetchedData = await fetchData('GET', fetchUrl, fetchParams)

      if (fetchedData.error) {
        setIsLoading(false)
        setIsError(true)
        return
      }

      setData(fetchedData)
      setIsLoading(false)
      setIsError(false)
    }

    fetchAsync()
  }, [isLoading, isError, data, fetchUrl, fetchParams])

  return useMemo(
    () => ({
      data,
      isLoading,
      isError,
    }),
    [data, isLoading, isError]
  )
}

export default useFetchData
