import { Grid, Typography } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import useEventApplicationContext from '../../contexts/useEventApplicationContext'
import { ApplicationType } from '../../types/application'
import { EventType, EventTypeCode } from '../../types/event'
import Button from '../ui/Button'
import Checkbox from '../ui/Checkbox'
import Link from '../ui/Link'
import Drawer from '../ui/Drawer'
import ApplicationEvent from './ApplicationEvent'
import { formatDate } from '../../utils/formatDate'
import useTranslate from '../../utils/translations/useTranslate'

type Props = {
  goToPreviousFormPage: () => void
  goToNextFormPage: ({ eventTypes }: { eventTypes: EventTypeCode[] }) => void
  eventTypes: EventType[]
}

const Wrapper = styled.div`
  padding: 40px 0 60px 0;
  width: 100%;
  position: relative;
`

const ActionButtons = styled.div`
  display: flex;
  padding: 10px 0;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
`

const Review = ({ eventTypes, goToNextFormPage, goToPreviousFormPage }: Props) => {
  const { t } = useTranslate()
  const {
    events,
    eventsCount,
    applicationData,
    applicationType,
    isFieldVisible,
    setApplicationData,
    validate,
    submitApplication,
  } = useEventApplicationContext()
  const [conditionsData, setConditionsData] = useState<string | undefined>(undefined)
  const [errors, setErrors] = useState<Record<string, string>>({})

  const eventTypesByCode: Record<string, EventType> = useMemo(() => {
    if (!eventTypes) {
      return {}
    }

    return eventTypes.reduce((groups: Record<string, EventType>, eventType: EventType) => {
      groups[eventType.code] = eventType

      return groups
    }, {})
  }, [eventTypes])

  const openConditions = useCallback(
    (eventTypeCode: string) => setConditionsData(eventTypeCode),
    []
  )

  const closeConditions = useCallback(() => setConditionsData(undefined), [])

  // Validates input data for this step and propagates errors
  const validateReview = useCallback(() => {
    const validationErrors = validate()
    const thisStepErrors: Record<string, string> = {}

    if (validationErrors.conditions) {
      thisStepErrors.conditions = validationErrors.conditions[0]
    }

    setErrors(thisStepErrors)
    return Boolean(Object.keys(thisStepErrors).length)
  }, [validate])

  const register = useCallback(async () => {
    const hasErrors = validateReview()
    const isSubmitted = await submitApplication()

    if (!hasErrors && isSubmitted) {
      goToNextFormPage({ eventTypes: Object.keys(events) as EventTypeCode[] })
    }
  }, [events, validateReview, submitApplication, goToNextFormPage])

  if (!eventsCount) {
    return (
      <Wrapper>
        <Typography variant="h4" gutterBottom>
          {t('pages.applications.review.title')}
        </Typography>
        <Typography>{t('pages.applications.myBag.empty')}</Typography>
        <ActionButtons>
          <Button href="/events">{t('pages.applications.myBag.activitiesLink')}</Button>
        </ActionButtons>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      {conditionsData && (
        <Drawer
          title={
            <Typography variant="h5">
              {t('pages.applications.review.conditionsOnType')}{' '}
              {eventTypesByCode[conditionsData].title}
            </Typography>
          }
          isOpen={Boolean(conditionsData)}
          onClose={closeConditions}
        >
          <div style={{ padding: 10 }}>
            <Typography
              component="div"
              dangerouslySetInnerHTML={{
                __html: eventTypesByCode[conditionsData].applicationConditions,
              }}
            />
          </div>
        </Drawer>
      )}
      <Typography variant="h4" gutterBottom>
        {t('pages.applications.review.title')}
      </Typography>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <Typography variant="h5" gutterBottom>
            {t('pages.applications.review.basicInfo')}
          </Typography>

          <Typography variant="body1" gutterBottom>
            <Typography component="span" sx={{ fontWeight: 'bold' }}>
              {applicationData.name} {applicationData.surname}{' '}
            </Typography>
            {isFieldVisible('sex') && applicationData.sex}
          </Typography>

          {isFieldVisible('birthDate') && (
            <Typography variant="body1">
              {t('pages.applications.basicInfo.birthDate')}:{' '}
              {formatDate(applicationData.birthDate || '')}
            </Typography>
          )}

          {isFieldVisible('birthPlace') && (
            <Typography variant="body1">
              {t('pages.applications.basicInfo.birthPlace')}: {applicationData.birthPlace}
            </Typography>
          )}
          {isFieldVisible('nationality') && (
            <Typography variant="body1">
              {t('pages.applications.basicInfo.nationality')}: {applicationData.nationality}
            </Typography>
          )}
          {isFieldVisible('employmentType') && (
            <Typography variant="body1">
              {t('pages.applications.basicInfo.employmentType')}:{' '}
              {t(`pages.applications.basicInfo.employmentType.${applicationData.employmentType}`)}
            </Typography>
          )}
          {isFieldVisible('educationType') && (
            <Typography variant="body1">
              {t('pages.applications.basicInfo.educationType')}:{' '}
              {t(`pages.applications.basicInfo.educationType.${applicationData.educationType}`)}
            </Typography>
          )}

          {isFieldVisible('permanentAddress') && (
            <Typography variant="body1">
              {t('pages.applications.basicInfo.permanentAddress')}:{' '}
              {applicationData.permanentAddress?.streetWithNumber},{' '}
              {applicationData.permanentAddress?.zip} {applicationData.permanentAddress?.city},{' '}
              {applicationData.permanentAddress?.country}
            </Typography>
          )}
          {isFieldVisible('contactAddress') && (
            <Typography variant="body1">
              {t('pages.applications.basicInfo.contactAddress')}:{' '}
              {applicationData.contactAddress?.streetWithNumber},{' '}
              {applicationData.contactAddress?.zip} {applicationData.contactAddress?.city},{' '}
              {applicationData.contactAddress?.country}
            </Typography>
          )}

          {isFieldVisible('email') && (
            <Typography variant="body1">
              {t('pages.applications.basicInfo.contacts.email')}: {applicationData.email}
            </Typography>
          )}
          {isFieldVisible('phone') && (
            <Typography variant="body1">
              {t('pages.applications.basicInfo.contacts.phone')}: {applicationData.phone}
            </Typography>
          )}
        </Grid>

        {applicationType === ApplicationType.WORKCAMP && (
          <>
            <Grid item spacing={2}>
              <Typography variant="h5" gutterBottom>
                {t('pages.applications.review.volunteering')}
              </Typography>

              <Typography variant="body1">
                {t('pages.applications.volunteering.emergencyContact')}:{' '}
                {applicationData.emergencyContact?.name}, {applicationData.emergencyContact?.email},{' '}
                {applicationData.emergencyContact?.phone}
              </Typography>
              <Typography variant="body1">
                {t('pages.applications.volunteering.languages')}:{' '}
                {applicationData.languages?.map(
                  ({ language, level }) => `${language} (${level}), `
                )}
              </Typography>
              <Typography variant="body1">
                Volunteer experiences and general skills: {applicationData.volunteerExperiences}
              </Typography>
              <Typography variant="body1">
                Why do you wish to take part in a volunteer project?: {applicationData.motivation}
              </Typography>
              <Typography variant="body1">
                General remarks: {applicationData.generalRemarks}
              </Typography>
              <Typography variant="body1">
                Remarks on health: {applicationData.healthRemarks}
              </Typography>
            </Grid>
          </>
        )}

        <Grid item>
          <Typography variant="h5" gutterBottom>
            {t('pages.applications.review.events')}
          </Typography>

          {Object.entries(events).map(([eventTypeCode, events]) => {
            const eventType = eventTypesByCode[eventTypeCode]

            return (
              <div key={eventType.code}>
                <Typography variant="h6" gutterBottom marginTop={2}>
                  {eventType.title}
                </Typography>
                {events.map((event) => (
                  <ApplicationEvent key={event.id} event={event} />
                ))}
              </div>
            )
          })}
        </Grid>

        <Grid container item direction="column">
          <Typography variant="h5" gutterBottom>
            {t('pages.applications.review.conditions')}
          </Typography>
          <Checkbox
            label={t('pages.applications.review.conditions.baseAgreement')}
            color={errors?.conditions ? 'error' : 'secondary'}
            onChange={(event) => {
              setErrors(({ conditions, ...nextErrors }) => nextErrors)
              setApplicationData.conditions((conditions: Record<string, boolean>) => ({
                ...conditions,
                general: event.target.checked,
              }))
            }}
            checked={Boolean(applicationData.conditions?.general)}
          />
          {Object.keys(events).map((eventTypeCode) => {
            const eventType = eventTypesByCode[eventTypeCode]

            return (
              <Checkbox
                key={String(eventType.code)}
                label={
                  <>
                    {t('pages.applications.review.conditions.eventTypeConditions')}{' '}
                    <Link
                      onClick={(event: MouseEvent) => {
                        // do not change checkbox value
                        event.preventDefault()
                        openConditions(eventTypeCode)
                      }}
                      target="_blank"
                    >
                      {t('pages.applications.review.conditions.eventTypeConditionsLink')}
                    </Link>{' '}
                    {t('pages.applications.review.conditions.eventTypeConditionsOn')}{' '}
                    {eventType.title}
                  </>
                }
                onChange={(event) => {
                  setErrors(({ conditions, ...nextErrors }) => nextErrors)
                  setApplicationData.conditions((conditions: Record<string, boolean>) => ({
                    ...conditions,
                    [`eventType_${eventType.code}`]: event.target.checked,
                  }))
                }}
                checked={Boolean(
                  applicationData.conditions &&
                    applicationData.conditions[`eventType_${eventType.code}`]
                )}
                color={errors?.conditions ? 'error' : 'secondary'}
              />
            )
          })}
          <Checkbox
            label={t('pages.applications.review.conditions.newsletter')}
            color="secondary"
            onChange={(event) =>
              setApplicationData.conditions((conditions: Record<string, boolean>) => ({
                ...conditions,
                newsletter: event.target.checked,
              }))
            }
            checked={Boolean(applicationData.conditions?.newsletter)}
          />
          <Checkbox
            label={t('pages.applications.review.conditions.member')}
            color="secondary"
            onChange={(event) =>
              setApplicationData.conditions((conditions: Record<string, boolean>) => ({
                ...conditions,
                memberApplication: event.target.checked,
              }))
            }
            checked={Boolean(applicationData.conditions?.memberApplication)}
          />
        </Grid>
      </Grid>
      <ActionButtons>
        <Button color="secondary" variant="outlined" onClick={goToPreviousFormPage}>
          {t('pages.applications.review.backAction')}
        </Button>
        <Button color="success" onClick={register}>
          {t('pages.applications.review.nextAction')}
        </Button>
      </ActionButtons>
    </Wrapper>
  )
}

export default Review
