import Breadcrumbs from '../components/Breadcrumbs'
import Educational from '../components/events/Educational'
import EventTypes from '../components/events/EventTypes'
import Unknown from '../components/events/Unknown'
import VolunteerAbroad from '../components/events/VolunteerAbroad'
import VolunteerSlovakia from '../components/events/VolunteerSlovakia'
import ContentContainer from '../components/ui/ContentContainer'
import { muiTheme } from '../theme'

const EventsPage = () => {
  return (
    <div style={{ width: '100%' }}>
      <ContentContainer background={muiTheme.palette.background.paper}>
        <Breadcrumbs />
        <EventTypes />
      </ContentContainer>
      <ContentContainer>
        <VolunteerSlovakia />
      </ContentContainer>
      <ContentContainer background={muiTheme.palette.background.paper}>
        <VolunteerAbroad />
      </ContentContainer>
      <ContentContainer>
        <Educational />
      </ContentContainer>
      <ContentContainer background={muiTheme.palette.background.paper}>
        <Unknown />
      </ContentContainer>
    </div>
  )
}

export default EventsPage
