import { Stack } from '@mui/material'
import { PostItem } from '../../types/media'
import PostListItem from './PostListItem'

type Props = {
  posts: PostItem[]
}

const PostList = ({ posts }: Props) => {
  return (
    <Stack spacing={2}>
      {posts.map((post: PostItem) => (
        <PostListItem key={post.id} post={post} />
      ))}
    </Stack>
  )
}

export default PostList
