import Button from '../ui/Button'
import { ReactComponent as Boat } from '../../assets/logo/boat.svg'
import { Grid, Typography } from '@mui/material'
import useTranslate from '../../utils/translations/useTranslate'

const AboutUs = () => {
  const { t } = useTranslate()

  return (
    <Grid container spacing={2} sx={{ paddingY: 6 }}>
      <Grid item sm={12} md={10}>
        <Typography variant="h4" gutterBottom>
          Find yourself in the ocean of adventure
        </Typography>
        <Typography variant="body1" component="p" sx={{ mb: 2 }}>
          {t('homepage.aboutUs.description')}
        </Typography>

        <Button href="/about-us">{t('homepage.aboutUs.linkMore')}</Button>
      </Grid>
      <Grid
        item
        sm={12}
        md={2}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
      >
        <Boat width="150" />
      </Grid>
    </Grid>
  )
}

export default AboutUs
