import { Box, Grid, Typography } from '@mui/material'
import styled from 'styled-components'
import useTranslate from '../../utils/translations/useTranslate'

type PersonProps = {
  imgSrc: string
  title: string
  description: string
}

const Photo = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
`

const Person = ({ imgSrc, title, description }: PersonProps) => {
  return (
    <Grid container marginBottom={1}>
      <Grid item sm={12} md={2} sx={{ minWidth: 100 }}>
        <Photo alt={title} src={imgSrc} loading="lazy" />
      </Grid>
      <Grid item sm={12} md={10}>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>

        <Typography variant="body1" gutterBottom>
          {description}
        </Typography>
      </Grid>
    </Grid>
  )
}

const OurTeam = () => {
  const { t } = useTranslate()

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        {t('pages.aboutUs.ourTeam.title')}
      </Typography>

      <Typography variant="h5" gutterBottom>
        {t('pages.aboutUs.ourTeam.currentTeam')}
      </Typography>

      <Person
        imgSrc="https://inex.sk/system/tinymce/images/original/majka_circle2.svg?1509111145"
        title={t('pages.aboutUs.ourTeam.headOfOffice')}
        description={t('pages.aboutUs.ourTeam.headOfOffice.description')}
      />
      <Person
        imgSrc="https://inex.sk/system/tinymce/images/original/monika_circle.svg?1506870698"
        title={t('pages.aboutUs.ourTeam.incoming')}
        description={t('pages.aboutUs.ourTeam.incoming.description')}
      />
      <Person
        imgSrc="https://inex.sk/system/tinymce/images/original/mirka_circle.svg?1506870645"
        title={t('pages.aboutUs.ourTeam.outgoing')}
        description={t('pages.aboutUs.ourTeam.outgoing.description')}
      />
      <Person
        imgSrc="https://inex.sk/system/tinymce/images/original/drawing.svg?1506868482"
        title={t('pages.aboutUs.ourTeam.esc')}
        description={t('pages.aboutUs.ourTeam.esc.description')}
      />

      <Typography variant="h5" gutterBottom>
        {t('pages.aboutUs.ourTeam.escVolunteers')}
      </Typography>
      <Person
        imgSrc="https://inex.sk/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBa0FRIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--05193463f384c507506abb89771bf5c511a7f780/Iryna%20ciernobiela.jpg?lang=sk"
        title={t('pages.aboutUs.ourTeam.esc.volunteer1')}
        description={t('pages.aboutUs.ourTeam.esc.volunteer1.description')}
      />
      <Person
        imgSrc="https://inex.sk/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBa0VRIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--00b00fdcedd227f0a05a8a67535a07a5e8708686/Chiara%20ciernobiela.jpg?lang=sk"
        title={t('pages.aboutUs.ourTeam.esc.volunteer2')}
        description={t('pages.aboutUs.ourTeam.esc.volunteer2.description')}
      />

      <Typography variant="h5" gutterBottom>
        {t('pages.aboutUs.ourTeam.otherHelpers')}
      </Typography>
      <Person
        imgSrc="https://inex.sk/system/tinymce/images/original/jarka_circle.svg"
        title={t('pages.aboutUs.ourTeam.otherHelpers.helper1')}
        description={t('pages.aboutUs.ourTeam.otherHelpers.helper1.description')}
      />
      <Person
        imgSrc="https://inex.sk/system/tinymce/images/original/inexak.jpeg?1551973885"
        title={t('pages.aboutUs.ourTeam.otherHelpers.helper2')}
        description={t('pages.aboutUs.ourTeam.otherHelpers.helper2.description')}
      />
    </Box>
  )
}

export default OurTeam
