import { Link as MuiLink } from '@mui/material'

type Props = {
  href?: string
  color?: string
  variant?:
    | 'body1'
    | 'body2'
    | 'button'
    | 'caption'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'inherit'
    | 'overline'
    | 'subtitle1'
    | 'subtitle2'
  children: React.ReactNode
  target?: string
  onClick?: (event: any) => void
  sx?: any
  className?: string
}

const Link = ({
  href,
  color = 'secondary',
  variant,
  children,
  target,
  onClick,
  sx,
  className,
}: Props) => {
  return (
    <MuiLink
      href={href}
      onClick={onClick}
      color={color}
      variant={variant}
      target={target}
      sx={sx}
      className={className}
    >
      {children}
    </MuiLink>
  )
}

export default Link
