import { Typography } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import useEventApplicationContext from '../../contexts/useEventApplicationContext'
import { EventItem, EventType, EventTypeCode, OrderableEventTypes } from '../../types/event'
import useTranslate from '../../utils/translations/useTranslate'
import { EventDetailModal } from '../events/search/EventDetailModal'
import Button from '../ui/Button'
import ApplicationEvent from './ApplicationEvent'

type Props = {
  goToNextFormPage: () => void
  eventTypes: EventType[]
}

const Wrapper = styled.div`
  padding: 40px 0 60px 0;
  width: 100%;
  position: relative;
`

const StyledEvents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
`

const StyledEventDetailModal = styled(EventDetailModal)`
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 2;
`

const ActionButtons = styled.div`
  display: flex;
  padding: 10px 0;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 5px;
`

const MyApplications = ({ goToNextFormPage, eventTypes }: Props) => {
  const { t } = useTranslate()
  const { events, eventsCount, removeAll, moveEventUp, moveEventDown } =
    useEventApplicationContext()
  const [modalData, setModalData] = useState<string | undefined>(undefined)

  const eventTypesByCode: Record<string, EventType> = useMemo(() => {
    if (!eventTypes) {
      return {}
    }

    return eventTypes.reduce((groups: Record<string, EventType>, eventType: EventType) => {
      groups[eventType.code] = eventType

      return groups
    }, {})
  }, [eventTypes])

  const openDetailModal = useCallback((event: EventItem) => {
    setModalData(event.id)
  }, [])

  const closeDetailModal = useCallback(() => {
    setModalData(undefined)
  }, [])

  const handleRemoveAll = useCallback(() => {
    if (window.confirm(t('pages.applications.myBag.emptyBagAction.confirm'))) {
      removeAll()
    }
  }, [removeAll, t])

  if (!eventsCount) {
    return (
      <Wrapper>
        <Typography variant="h4" gutterBottom>
          {t('pages.applications.myBag.title')}
        </Typography>
        <Typography>{t('pages.applications.myBag.empty')}</Typography>
        <ActionButtons>
          <Button href="/events">{t('pages.applications.myBag.activitiesLink')}</Button>
        </ActionButtons>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      {modalData && <StyledEventDetailModal eventId={modalData} onClose={closeDetailModal} />}

      <Typography variant="h4" sx={{ fontWeight: 'bold' }} gutterBottom>
        {t('pages.applications.myBag.title')}
      </Typography>
      <Typography color="secondary" gutterBottom>
        {t('pages.applications.myBag.activitiesCountBefore')}
        {eventsCount}
        {t('pages.applications.myBag.activitiesCountAfter')}
      </Typography>
      {Object.entries(events).map(([eventTypeCode, typeEvents]) => {
        const eventType = eventTypesByCode[eventTypeCode]
        const isOrderable = OrderableEventTypes.includes(eventTypeCode as EventTypeCode)

        return (
          <div key={eventType.code}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }} gutterBottom>
              {eventType.title}
            </Typography>
            {isOrderable && (
              <Typography gutterBottom>{t('pages.applications.myBag.orderableTitle')}</Typography>
            )}
            <StyledEvents>
              {typeEvents.map((event) => (
                <ApplicationEvent
                  key={event.id}
                  event={event}
                  openDetailModal={openDetailModal}
                  withActions
                  isOrderable={isOrderable}
                  moveUp={() => moveEventUp(eventTypeCode as EventTypeCode, event)}
                  moveDown={() => moveEventDown(eventTypeCode as EventTypeCode, event)}
                />
              ))}
            </StyledEvents>
          </div>
        )
      })}
      <ActionButtons>
        <Button color="error" variant="outlined" size="small" onClick={handleRemoveAll}>
          {t('pages.applications.myBag.emptyBagAction')}
        </Button>
        <Button color="success" onClick={goToNextFormPage}>
          {t('pages.applications.myBag.fillApplicationAction')}
        </Button>
      </ActionButtons>
    </Wrapper>
  )
}

export default MyApplications
