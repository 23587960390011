import { MenuItem, MenuList } from '@mui/material'
import { Page } from '../../pages/AboutUs'
import useTranslate from '../../utils/translations/useTranslate'

type Props = {
  setPage: (page: Page) => void
}

const Menu = ({ setPage }: Props) => {
  const { t } = useTranslate()

  return (
    <MenuList
      sx={{
        margin: 2,
        padding: 2,
        // todo: Na malej obrazovke zobrazit hore, inak fixed position vpravo
        // position: 'fixed',
        boxShadow:
          '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);',
      }}
    >
      <MenuItem component="a" href="/about-us" sx={{ whiteSpace: 'initial' }}>
        {t('pages.aboutUs.menu.aboutInex')}
      </MenuItem>
      <MenuItem component="a" href="/about-us/our-team" sx={{ whiteSpace: 'initial' }}>
        {t('pages.aboutUs.menu.ourTeam')}
      </MenuItem>
      <MenuItem component="a" href="/about-us/partners" sx={{ whiteSpace: 'initial' }}>
        {t('pages.aboutUs.menu.partnerOrganizations')}
      </MenuItem>
      <MenuItem component="a" href="/about-us/history" sx={{ whiteSpace: 'initial' }}>
        {t('pages.aboutUs.menu.history')}
      </MenuItem>
      <MenuItem component="a" href="/about-us/organization" sx={{ whiteSpace: 'initial' }}>
        {t('pages.aboutUs.menu.organizationalStructure')}
      </MenuItem>
    </MenuList>
  )
}

export default Menu
