import { Box, Typography } from '@mui/material'
import QRCodePayment from '../components/helpUs/QRCodePayment'
import Button from '../components/ui/Button'
import ContentContainer from '../components/ui/ContentContainer'
import Link from '../components/ui/Link'
import { IBAN } from '../constants'
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism'
import Breadcrumbs from '../components/Breadcrumbs'
import useTranslate from '../utils/translations/useTranslate'
import { muiTheme } from '../theme'

const HelpUs = () => {
  const { t } = useTranslate()

  return (
    <div style={{ width: '100%' }}>
      <ContentContainer background={muiTheme.palette.background.paper}>
        <Box sx={{ pb: 4 }}>
          <Breadcrumbs />
          <Box sx={{ pt: 4, pb: 2 }}>
            <Typography variant="h4" gutterBottom>
              {t('pages.helpUs.title')}
            </Typography>

            <Typography variant="body1" gutterBottom>
              {t('pages.helpUs.description')}{' '}
              <Link href="/contacts" color="secondary">
                {t('pages.helpUs.descriptionLink')}
              </Link>
            </Typography>
          </Box>
          <Typography variant="h5" gutterBottom>
            {t('pages.helpUs.financially.title')}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {t('pages.helpUs.financially.description')}
          </Typography>

          <Typography variant="h6" gutterBottom>
            {t('pages.helpUs.financially.direct')}
          </Typography>
          <Typography variant="body1" gutterBottom>
            IBAN: <b>{IBAN}</b> ({t('pages.helpUs.financially.direct.accountInBank')})
            <br />
            <Link
              color="secondary"
              onClick={() => navigator.clipboard.writeText(IBAN)}
              sx={{ cursor: 'pointer' }}
            >
              {t('pages.helpUs.financially.copyIban')}
            </Link>
          </Typography>
          <QRCodePayment />
          <Typography variant="body1" gutterBottom>
            {t('pages.helpUs.financially.direct.thanks')}{' '}
            <VolunteerActivismIcon color="secondary" />
          </Typography>
        </Box>
      </ContentContainer>
      <ContentContainer>
        <Box sx={{ paddingY: 4 }}>
          <Typography variant="h5" gutterBottom>
            {t('pages.helpUs.volunteering.title')}
          </Typography>
          <Typography variant="body1" gutterBottom sx={{ mb: 2 }}>
            {t('pages.helpUs.volunteering.description')}
          </Typography>
          <Button href="/events" color="info">
            {t('pages.helpUs.volunteering.linkToEvents')}
          </Button>
        </Box>
      </ContentContainer>
      <ContentContainer background={muiTheme.palette.background.paper}>
        <Box sx={{ paddingY: 4 }}>
          <Typography variant="h5">{t('pages.helpUs.2Percent.title')}</Typography>
          <Typography variant="body1" gutterBottom sx={{ mb: 2 }}>
            {t('pages.helpUs.2Percent.description')}
          </Typography>
          <Button href="/help-us/2-percenta" color="info">
            {t('pages.helpUs.2Percent.linkMore')}
          </Button>
        </Box>
      </ContentContainer>
      <ContentContainer>
        <Box sx={{ paddingY: 4 }}>
          <Typography variant="h5">{t('pages.helpUs.onlinePurchases.title')}</Typography>
          <Typography variant="body1" gutterBottom sx={{ mb: 2 }}>
            {t('pages.helpUs.onlinePurchases.description')}
          </Typography>
          <Button href="/help-us/dobromat" color="info">
            {t('pages.helpUs.onlinePurchases.linkMore')}
          </Button>
        </Box>
      </ContentContainer>
      <ContentContainer background={muiTheme.palette.background.paper}>
        <Box sx={{ paddingY: 4 }}>
          <Typography variant="h5" gutterBottom>
            {t('pages.helpUs.trainerTeam.title')}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {t('pages.helpUs.trainerTeam.description')}{' '}
            <Link href="/contacts" color="secondary">
              {t('pages.helpUs.trainerTeam.linkMore')}
            </Link>
          </Typography>
        </Box>
        <Box sx={{ pb: 8 }}>
          <Typography variant="h5" gutterBottom>
            {t('pages.helpUs.promoTeam.title')}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {t('pages.helpUs.promoTeam.description')}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {t('pages.helpUs.promoTeam.description2')}
            <b>{t('pages.helpUs.promoTeam.description3')}</b>
            {t('pages.helpUs.promoTeam.description4')}{' '}
            <Link href="/contacts" color="secondary">
              {t('pages.helpUs.promoTeam.linkMore')}
            </Link>{' '}
            {t('pages.helpUs.promoTeam.description5')}
          </Typography>
        </Box>
      </ContentContainer>
    </div>
  )
}

export default HelpUs
