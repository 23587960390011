import { useCallback, useState } from 'react'

import { EventDetailModal } from './EventDetailModal'
import { EventItem as EventItemType } from '../../../types/event'
import useEventApplicationContext from '../../../contexts/useEventApplicationContext'
import { Pagination, Stack } from '@mui/material'
import LoadingOverlay from '../../LoadingOverlay'
import EventItem from './EventItem'
import useTranslate from '../../../utils/translations/useTranslate'

type Props = {
  events?: EventItemType[]
  pageCount: number
  isLoading: boolean
  isError: boolean
  setSnackbarMessage: (message: string) => void
  page: number
  setPage: (page: number) => void
}

const Results = ({
  events,
  pageCount,
  isLoading,
  isError,
  setSnackbarMessage,
  page,
  setPage,
}: Props) => {
  const { t } = useTranslate()
  const { add, isInBag, remove } = useEventApplicationContext()

  const [modalData, setModalData] = useState<string | undefined>(undefined)

  const openDetailModal = useCallback((event: EventItemType) => {
    setModalData(event.id)
  }, [])

  const closeDetailModal = useCallback(() => {
    setModalData(undefined)
  }, [])

  const handleAddToBag = useCallback(
    (event: EventItemType) => {
      if (isInBag(event.eventType, event.id)) {
        remove(event.eventType, event.id)
        setSnackbarMessage('Aktivita bola úspešne odobratá z tvojho batoha')
      } else {
        add(event)
        setSnackbarMessage('Aktivita bola úspešne pridaná do tvojho batoha')
      }
    },
    [add, remove, isInBag, setSnackbarMessage]
  )

  if (isLoading) {
    return <LoadingOverlay />
  }

  if (!events || isError) {
    return <>{t('errors.loading')}</>
  }

  if (!events.length) {
    return <>{t('events.noResults')}</>
  }

  return (
    <>
      {modalData && <EventDetailModal eventId={modalData} onClose={closeDetailModal} />}

      <Stack spacing={2}>
        {events.map((event) => (
          <EventItem
            key={event.id}
            event={event}
            openDetailModal={openDetailModal}
            handleAddToBag={handleAddToBag}
          />
        ))}
      </Stack>
      {pageCount > 1 && (
        <Pagination
          page={page}
          onChange={(_, pageNumber) => setPage(pageNumber)}
          count={pageCount}
          color="primary"
          sx={{ mt: 2 }}
        />
      )}
    </>
  )
}

export default Results
