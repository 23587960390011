const getCsrfCookieToken = (cookies: string): string => {
  const splitCookies = cookies.split('; ')
  const csrfTokenCookie = splitCookies.find((cookie) => cookie.startsWith('CSRF-TOKEN='))

  if (!csrfTokenCookie) {
    return ''
  }

  // return value
  return csrfTokenCookie.split('=')[1]
}
export default getCsrfCookieToken
