import styled from 'styled-components'
import CountUp from 'react-countup'

import { Statistics as StatisticsType } from '../../types/baseConfig'
import LoadingOverlay from '../LoadingOverlay'
import useTranslate from '../../utils/translations/useTranslate'
import { Grid } from '@mui/material'

type Props = {
  statistics?: StatisticsType
  isLoading: boolean
  isError: boolean
}

type CountUpValueProps = {
  value: number
}

const StyledCountUpValue = styled(CountUp)`
  text-align: center;
  font-size: 3.2rem;
  line-height: 3.2rem;
`

const CountUpValue = ({ value }: CountUpValueProps) => (
  <StyledCountUpValue end={Number(value)} duration={2} separator=","></StyledCountUpValue>
)

const Statistic = styled(Grid)`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
`

const Label = styled.div`
  font-size: 1em;
  line-height: 1.5em;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.87);
  text-transform: uppercase;
  text-align: center;
`

const Statistics = ({ statistics, isLoading, isError }: Props) => {
  const { t } = useTranslate()

  if (isLoading) {
    return <LoadingOverlay />
  }

  if (!statistics || isError) {
    return <>{t('errors.loading')}</>
  }

  const { years, countries, volunteers, events } = statistics

  return (
    <Grid
      container
      sx={{
        position: 'relative',
        paddingTop: 6,
        paddingBottom: 13,
        display: 'flex',
      }}
    >
      <Statistic item xs={12} md={6} lg={3}>
        <CountUpValue value={years} />
        <Label>
          {t('homepage.statistics.yearsOfExperience1')}
          <br />
          {t('homepage.statistics.yearsOfExperience2')}
        </Label>
      </Statistic>
      <Statistic item xs={12} md={6} lg={3}>
        <CountUpValue value={countries} />
        <Label>
          {t('homepage.statistics.countries1')}
          <br />
          {t('homepage.statistics.countries2')}
        </Label>
      </Statistic>
      <Statistic item xs={12} md={6} lg={3}>
        <CountUpValue value={volunteers} />
        <Label>
          {t('homepage.statistics.volunteers1')}
          <br />
          {t('homepage.statistics.volunteers2')}
        </Label>
      </Statistic>
      <Statistic item xs={12} md={6} lg={3}>
        <CountUpValue value={events} />
        <Label>
          {t('homepage.statistics.events1')}
          <br />
          {t('homepage.statistics.events2')}
        </Label>
      </Statistic>
    </Grid>
  )
}

export default Statistics
