import { Card, CardActions, CardContent, Typography } from '@mui/material'
import { useState } from 'react'
import { EventItem } from '../../types/event'
import { formatDate } from '../../utils/formatDate'
import { EventDetailModal } from '../events/search/EventDetailModal'
import Button from '../ui/Button'

type Props = {
  event: EventItem
}

const EventCard = ({ event }: Props) => {
  const [isDetailOpen, setIsDetailOpen] = useState<boolean>(false)
  const { id, code, title, country, from, to } = event

  return (
    <>
      {isDetailOpen && <EventDetailModal eventId={id} onClose={() => setIsDetailOpen(false)} />}

      <Card variant="outlined" sx={{ width: '100%' }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            <b>{code}</b>
          </Typography>
          <Typography
            variant="h5"
            component="div"
            color="secondary"
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {title}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            {country}
          </Typography>
          <Typography variant="body2">
            {formatDate(from)} - {formatDate(to)}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => setIsDetailOpen(true)}>
            Detail
          </Button>
        </CardActions>
      </Card>
    </>
  )
}

export default EventCard
