import styled from 'styled-components'
import { ReactComponent as InexLogo } from '../../../assets/logo/logo.svg'

const StyledLogo = styled(InexLogo)`
  &:hover {
    transform: scale(1.05);
  }
`

const Logo = () => {
  return (
    <a href="/">
      <StyledLogo width="200" />
    </a>
  )
}

export default Logo
