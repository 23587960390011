import { Grid } from '@mui/material'
import styled from 'styled-components'
import useTranslate from '../../../utils/translations/useTranslate'
import Link from '../../ui/Link'

const StyledGridItem = styled(Grid)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledLink = styled(Link)``

const PageMap = () => {
  const { t } = useTranslate()

  return (
    <Grid container spacing={2} sx={{ display: 'flex' }}>
      <StyledGridItem item xs={6}>
        <StyledLink color="text.primary" href="/about-us">
          {t('layout.footer.pageMap.aboutUs')}
        </StyledLink>
        <StyledLink color="text.primary" href="/events">
          {t('layout.footer.pageMap.events')}
        </StyledLink>
        <StyledLink color="text.primary" href="/help-us">
          {t('layout.footer.pageMap.helpUs')}
        </StyledLink>
        <StyledLink color="text.primary" href="/contacts">
          {t('layout.footer.pageMap.contacts')}
        </StyledLink>
        <StyledLink color="text.primary" href="/frequently-asked-questions">
          {t('layout.footer.pageMap.faq')}
        </StyledLink>
      </StyledGridItem>
      <StyledGridItem item xs={6}>
        <StyledLink color="text.primary" href="/media/blog">
          {t('layout.footer.pageMap.blog')}
        </StyledLink>
        <StyledLink color="text.primary" href="/media/press">
          {t('layout.footer.pageMap.pressReleases')}
        </StyledLink>
        <StyledLink color="text.primary" href="/media/publications">
          {t('layout.footer.pageMap.publications')}
        </StyledLink>
        <StyledLink color="text.primary" href="/media/annual-reports">
          {t('layout.footer.pageMap.annualReports')}
        </StyledLink>
      </StyledGridItem>
    </Grid>
  )
}

export default PageMap
