import { Delete } from '@mui/icons-material'
import { Box, Paper, Typography, Chip } from '@mui/material'
import { BlogPostCategory } from '../../types/media'
import useTranslate from '../../utils/translations/useTranslate'
import TextInput from '../ui/TextInput'

type Props = {
  selectedCategoryName: string
  categories: BlogPostCategory[]
  setCategory: (category: string) => void
  query: string
  setQuery: (query: string) => void
}

export const BlogMenu = ({
  categories,
  selectedCategoryName,
  setCategory,
  query,
  setQuery,
}: Props) => {
  const { t } = useTranslate()

  return (
    <Paper sx={{ p: 2, mx: 2 }}>
      <Typography variant="body1">{t('media.blog.menu.categories')}</Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', marginTop: 1, marginBottom: 2, gap: 1 }}>
        {categories.map((category) => {
          const isSelected = category.name === selectedCategoryName
          return (
            <Chip
              key={category.id}
              label={category.name}
              color="secondary"
              variant={isSelected ? undefined : 'outlined'}
              onClick={() => setCategory(category.name)}
              onDelete={isSelected ? () => setCategory('') : undefined}
              deleteIcon={isSelected ? <Delete /> : undefined}
            />
          )
        })}
      </Box>
      <TextInput
        type="input"
        label={t('media.blog.menu.search')}
        value={query}
        onChange={(e) => setQuery((e.target as HTMLInputElement).value)}
      />
    </Paper>
  )
}

export default BlogMenu
