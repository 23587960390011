import { createContext } from 'react'
import { ApplicationData, ApplicationType } from '../types/application'
import { EventItem, EventTypeCode } from '../types/event'

export type EventApplication = {
  events: Record<EventTypeCode, EventItem[]>
  eventsCount: number
  applicationData: Partial<ApplicationData>
  // todo - keysof ApplicationData, but setter functions as values
  setApplicationData: any
  validate: () => Record<string, string[]>
  add: (event: EventItem) => void
  remove: (eventTypeCode: EventTypeCode | string, eventId: string) => void
  removeAll: () => void
  isInBag: (eventTypeCode: EventTypeCode | string, eventId: string) => boolean
  applicationType?: ApplicationType
  isFieldVisible: (fieldName: string) => boolean
  submitApplication: () => Promise<boolean>
  moveEventUp: (eventTypeCode: EventTypeCode, event: EventItem) => void
  moveEventDown: (eventTypeCode: EventTypeCode, event: EventItem) => void
}

export const EventApplicationContext = createContext<EventApplication>({
  events: {} as Record<EventTypeCode, EventItem[]>,
  eventsCount: 0,
  applicationData: {},
  setApplicationData: {},
  validate: () => ({}),
  add: () => {},
  remove: () => {},
  removeAll: () => {},
  isInBag: () => false,
  applicationType: undefined,
  isFieldVisible: () => false,
  submitApplication: () => new Promise((resolve) => false),
  moveEventUp: () => {},
  moveEventDown: () => {},
})
