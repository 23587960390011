import { Box, Grid, Typography } from '@mui/material'
import styled from 'styled-components'
import Button from '../ui/Button'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import useTranslate from '../../utils/translations/useTranslate'

type PartnerProps = {
  imgSrc: string
  title: string
  description?: string
  href: string
}

const Photo = styled.img`
  width: 100px;
`

const Partner = ({ imgSrc, title, description, href }: PartnerProps) => {
  const { t } = useTranslate()

  return (
    <Grid container marginBottom={1}>
      <Grid
        item
        sm={12}
        md={2}
        sx={{
          minWidth: 100,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Photo alt={title} src={imgSrc} loading="lazy" />
      </Grid>
      <Grid item sm={12} md={10}>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>

        <Typography variant="body1" gutterBottom>
          {description}
        </Typography>

        <Button href={href} target="_blank">
          {t('pages.aboutUs.partnerOrganizations.webLink')}&nbsp;&nbsp;
          <ExitToAppIcon />
        </Button>
      </Grid>
    </Grid>
  )
}

const Partners = () => {
  const { t } = useTranslate()

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        {t('pages.aboutUs.partnerOrganizations.title')}
      </Typography>

      <Typography variant="h5" gutterBottom>
        {t('pages.aboutUs.partnerOrganizations.membershipInNetworks')}
      </Typography>

      <Typography variant="body1" gutterBottom sx={{ mb: 3 }}>
        {t('pages.aboutUs.partnerOrganizations.membershipInNetworks.description')}
      </Typography>

      <Partner
        imgSrc="https://inex.sk/system/tinymce/images/medium/Alliance_logo_2013_high_def.jpg?1507893562"
        title="Alliance of European Voluntary Service Organisations"
        description={t('pages.aboutUs.partnerOrganizations.membershipInNetworks.alliance')}
        href="http://www.alliance-network.eu/"
      />
      <Partner
        imgSrc="https://inex.sk/system/tinymce/images/medium/451-Service_Civil_International_SCI.jpg?1506100286"
        title="Service Civil International"
        description={t('pages.aboutUs.partnerOrganizations.membershipInNetworks.sci')}
        href="http://www.sci.ngo/"
      />
      <Partner
        imgSrc="https://inex.sk/system/tinymce/images/medium/modre_zelene.png?1507032485"
        title="Rada mládeže Slovenska"
        description={t('pages.aboutUs.partnerOrganizations.membershipInNetworks.rms')}
        href="http://mladez.sk/"
      />

      <Typography variant="h5" gutterBottom sx={{ mt: 3, mb: 2 }}>
        {t('pages.aboutUs.partnerOrganizations.partnersInSlovakia')}
      </Typography>
      <Partner
        imgSrc="https://inex.sk/system/tinymce/images/medium/BROZ.png?1507892651"
        title="BROZ"
        href="http://www.broz.sk/"
      />
      <Partner
        imgSrc="https://inex.sk/system/tinymce/images/medium/cierny_hrad.png?1507892679"
        title="Čierny hrad"
        href="https://www.ciernyhrad.sk/"
      />
      <Partner
        imgSrc="https://inex.sk/system/tinymce/images/medium/slatinka_logo.jpg?1507892761"
        title="Slatinka"
        href="http://www.slatinka.sk/"
      />
      <Partner
        imgSrc="https://inex.sk/system/tinymce/images/original/uhrovec.jpg?1551364250"
        title="Hrad Uhrovec"
        href="http://www.hraduhrovec.sk/"
      />
      <Partner
        imgSrc="https://inex.sk/system/tinymce/images/medium/logobarlicka.jpg?1507892824"
        title="Barlička"
        href="http://barlicka.sk/"
      />
      <Partner
        imgSrc="https://inex.sk/system/tinymce/images/medium/logo_brekov.jpg?1507893008"
        title="Brekov"
        href="http://www.brekov.eu/03_znzbh.htm"
      />
      <Partner
        imgSrc="https://inex.sk/system/tinymce/images/medium/logo_RC_logo.jpg?1507893031"
        title="Rákoczi"
        href="http://www.rakoczi.wbl.sk/"
      />
      <Partner
        imgSrc="https://inex.sk/system/tinymce/images/medium/integra.jpg?1507893059"
        title="Integra"
        href="http://www.dssintegra.sk/"
      />
      <Partner
        imgSrc="https://inex.sk/system/tinymce/images/medium/logo-kalvaria.png?1507893097"
        title="Kalvária"
        href="http://www.kalvaria.org/"
      />
      <Partner
        imgSrc="https://inex.sk/system/tinymce/images/medium/presporkovo.jpg?1507893155"
        title="Prešporkovo"
        href="http://www.presporkovo.sk/"
      />
      <Partner
        imgSrc="https://inex.sk/system/tinymce/images/original/ZS_Surany.jpg?1551364677"
        title="ZR ZŠ Šurany"
        href="http://www.zssnpsu.edu.sk/"
      />
    </Box>
  )
}

export default Partners
