import { Grid, Typography } from '@mui/material'
import Menu from '../../components/media/Menu'
import ContentContainer from '../../components/ui/ContentContainer'
import { muiTheme } from '../../theme'
import { Post as PostType } from '../../types/media'
import Breadcrumbs from '../Breadcrumbs'
import PostListItem from './PostListItem'

type Props = {
  post: PostType
}

const Post = ({ post }: Props) => {
  return (
    <div style={{ width: '100%' }}>
      <ContentContainer background={muiTheme.palette.background.paper}>
        <Breadcrumbs />
        <Grid container sx={{ minHeight: 450, paddingTop: 4, marginBottom: 8 }}>
          <Grid item sm={12} md={8}>
            <PostListItem post={post} />
            <Typography component="div" dangerouslySetInnerHTML={{ __html: post.content }} />
          </Grid>
          <Grid item sm={12} md={4}>
            <Menu />
          </Grid>
        </Grid>
      </ContentContainer>
    </div>
  )
}

export default Post
