import { Routes, Route, useLocation } from 'react-router-dom'
import {
  Contacts,
  HomePage,
  Events,
  EventsSearch,
  HelpUs,
  AboutUs,
  FAQ,
  MyAccount,
  MyBag,
  AnnualReports,
  Blog,
  PressReleases,
  Publications,
  Logos,
} from './pages'
import Layout from './components/layout'
import { EventApplicationContextProvider } from './contexts/EventApplicationContextProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { ThemeProvider as MuiThemeProvider } from '@mui/material'
import { muiTheme } from './theme'
import HelpDobromat from './pages/HelpDobromat'
import HelpTwoPercent from './pages/HelpTwoPercent'
import Page404 from './pages/Page404'
import BlogDetail from './pages/BlogDetail'
import PressReleaseDetail from './pages/PressReleaseDetail'
import PublicationDetail from './pages/PublicationDetail'
import AnnualReportDetail from './pages/AnnualReportDetail'
import EventDetail from './pages/EventDetail'
import ConsentGDPR from './components/ConsentGDPR'
import ReactGA from 'react-ga'
import { GDPR_CONSENT } from './storage/useLocalStorage'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import useFetchData from './hooks/useFetchData'
import { SeoParams } from './types/baseConfig'

let GDPR_CONSENTS: { isFilled: boolean; extendedConsentEnabled: boolean } | undefined
try {
  // Get from local storage by key
  const item = window.localStorage.getItem(GDPR_CONSENT)

  if (item) {
    GDPR_CONSENTS = JSON.parse(item)
  }
} catch (error) {
  console.log(error)
}

if (
  GDPR_CONSENTS &&
  GDPR_CONSENTS.isFilled &&
  GDPR_CONSENTS.extendedConsentEnabled &&
  process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID &&
  process.env.REACT_APP_ENV !== 'DEV'
) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID)
}

function App() {
  const { pathname } = useLocation()
  // replace parametrized IDs (numbers) in path by :id
  const fixedPath = pathname.replace(/\/\d+\//g, '/:id/').replace(/\/\d+$/g, '/:id')

  const { data: seoParams } = useFetchData<SeoParams>('/v1/base/seo', { pathname: fixedPath })

  useEffect(() => {
    if (
      GDPR_CONSENTS &&
      GDPR_CONSENTS.isFilled &&
      GDPR_CONSENTS.extendedConsentEnabled &&
      process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID &&
      process.env.REACT_APP_ENV !== 'DEV'
    ) {
      ReactGA.pageview(window.location.pathname + window.location.search)
    }
  }, [])

  return (
    <MuiThemeProvider theme={muiTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <EventApplicationContextProvider>
          <Helmet>
            <title>{seoParams?.title}</title>
            <meta name="keywords" content={seoParams?.keywords} />
            <meta name="description" content={seoParams?.description} />
            <link rel="canonical" href={`https://inex.sk${seoParams?.canonical || pathname}`} />
          </Helmet>
          <Layout>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/contacts" element={<Contacts />} />
              <Route path="/events" element={<Events />} />
              <Route path="/events/search" element={<Events />} />
              <Route
                path="/events/search/volunteering-slovakia"
                element={<EventsSearch type="volunteer" location="local" />}
              />
              <Route
                path="/events/search/volunteering-abroad"
                element={<EventsSearch type="volunteer" location="abroad" />}
              />
              <Route path="/events/search/education" element={<EventsSearch type="education" />} />
              <Route path="/events/:id" element={<EventDetail />} />

              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/about-us/our-team" element={<AboutUs page="ourTeam" />} />
              <Route path="/about-us/partners" element={<AboutUs page="partnerOrganizations" />} />
              <Route path="/about-us/history" element={<AboutUs page="history" />} />
              <Route
                path="/about-us/organization"
                element={<AboutUs page="organizationStructure" />}
              />

              <Route path="/media/blog" element={<Blog />} />
              <Route path="/media/blog/:id" element={<BlogDetail />} />
              <Route path="/media/press-releases" element={<PressReleases />} />
              <Route path="/media/press-releases/:id" element={<PressReleaseDetail />} />
              <Route path="/media/publications" element={<Publications />} />
              <Route path="/media/publications/:id" element={<PublicationDetail />} />
              <Route path="/media/annual-reports" element={<AnnualReports />} />
              <Route path="/media/annual-reports/:id" element={<AnnualReportDetail />} />
              <Route path="/media/logos" element={<Logos />} />

              <Route path="/frequently-asked-questions" element={<FAQ />} />
              <Route path="/my-account" element={<MyAccount />} />
              <Route path="/my-bag" element={<MyBag />} />

              <Route path="/help-us" element={<HelpUs />} />
              <Route path="/help-us/dobromat" element={<HelpDobromat />} />
              <Route path="/help-us/2-percenta" element={<HelpTwoPercent />} />

              <Route path="*" element={<Page404 />} />
            </Routes>
            <ConsentGDPR />
          </Layout>
        </EventApplicationContextProvider>
      </LocalizationProvider>
    </MuiThemeProvider>
  )
}

export default App
