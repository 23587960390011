import styled from 'styled-components'
import Header from './Header'
import Footer from './Footer'

type Props = {
  children: React.ReactNode
}

const Wrapper = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
`

const Layout = ({ children }: Props) => {
  return (
    <Wrapper>
      <Header />
      {children}
      <Footer />
    </Wrapper>
  )
}

export default Layout
