import { Paper } from '@mui/material'
import useTranslate from '../../utils/translations/useTranslate'
import TextInput from '../ui/TextInput'

type Props = {
  query: string
  setQuery: (query: string) => void
}

export const PostMenu = ({ query, setQuery }: Props) => {
  const { t } = useTranslate()

  return (
    <Paper sx={{ p: 2, mx: 2 }}>
      <TextInput
        type="input"
        label={t('media.post.menu.search')}
        value={query}
        onChange={(e) => setQuery((e.target as HTMLInputElement).value)}
      />
    </Paper>
  )
}

export default PostMenu
