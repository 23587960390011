import { useContext } from 'react'
import { EventApplication, EventApplicationContext } from './EventApplicationContext'

export const useEventApplicationContext = (): EventApplication => {
  const context = useContext(EventApplicationContext)

  if (typeof context === 'undefined') {
    throw new Error(
      'useEventApplicationContext must be used within a EventApplicationContext provider'
    )
  }

  return context
}

export default useEventApplicationContext
