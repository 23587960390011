import { useMemo } from 'react'
import { IBAN } from '../../constants'
import useTranslate from '../../utils/translations/useTranslate'
import Button from '../ui/Button'

// This util converts component to string contents
function textContent(elem: React.ReactElement | string): string {
  if (!elem) {
    return ''
  }
  if (typeof elem === 'string') {
    return elem
  }
  const children = elem.props && elem.props.children
  if (children instanceof Array) {
    return children.map(textContent).join('')
  }
  return textContent(children)
}

// TODO: It could also remove diacritics from strings, see lodash.deburr
const useFaqQuestions = (
  filter: string
): { question: string; answer: string | React.ReactNode }[] => {
  const { t } = useTranslate()

  return useMemo(() => {
    return [
      { question: t('pages.faq.questions.1'), answer: t('pages.faq.questions.1.answer') },
      {
        question: t('pages.faq.questions.2'),
        answer: (
          <>
            {t('pages.faq.questions.2.answer')}
            <br />
            <Button href="/media/blog">Blog</Button>
          </>
        ),
      },
      { question: t('pages.faq.questions.3'), answer: t('pages.faq.questions.3.answer') },
      {
        question: t('pages.faq.questions.4'),
        answer: <div dangerouslySetInnerHTML={{ __html: t('pages.faq.questions.4.answer') }} />,
      },
      {
        question: t('pages.faq.questions.5'),
        answer: <div dangerouslySetInnerHTML={{ __html: t('pages.faq.questions.5.answer') }} />,
      },
      {
        question: t('pages.faq.questions.6'),
        answer: <div dangerouslySetInnerHTML={{ __html: t('pages.faq.questions.6.answer') }} />,
      },
      { question: t('pages.faq.questions.7'), answer: t('pages.faq.questions.7.answer') },
      {
        question: t('pages.faq.questions.8'),
        answer: (
          <>
            {t('pages.faq.questions.8.answer')}
            <br />
            <Button href="/events">Aktivity</Button>
          </>
        ),
      },
      { question: t('pages.faq.questions.9'), answer: t('pages.faq.questions.9.answer') },
      { question: t('pages.faq.questions.10'), answer: t('pages.faq.questions.10.answer') },
      {
        question: t('pages.faq.questions.11'),
        answer: `${t('pages.faq.questions.11.answer')} ${IBAN} ${t(
          'pages.faq.questions.11.answer2'
        )}`,
      },
      { question: t('pages.faq.questions.12'), answer: t('pages.faq.questions.12.answer') },
      { question: t('pages.faq.questions.13'), answer: t('pages.faq.questions.13.answer') },
      { question: t('pages.faq.questions.14'), answer: t('pages.faq.questions.14.answer') },
      { question: t('pages.faq.questions.15'), answer: t('pages.faq.questions.15.answer') },
      { question: t('pages.faq.questions.16'), answer: t('pages.faq.questions.16.answer') },
      { question: t('pages.faq.questions.17'), answer: t('pages.faq.questions.17.answer') },
      { question: t('pages.faq.questions.18'), answer: t('pages.faq.questions.18.answer') },
      { question: t('pages.faq.questions.19'), answer: t('pages.faq.questions.19.answer') },
      { question: t('pages.faq.questions.20'), answer: t('pages.faq.questions.20.answer') },
      { question: t('pages.faq.questions.21'), answer: t('pages.faq.questions.21.answer') },
      { question: t('pages.faq.questions.22'), answer: t('pages.faq.questions.22.answer') },
      { question: t('pages.faq.questions.23'), answer: t('pages.faq.questions.23.answer') },
      { question: t('pages.faq.questions.24'), answer: t('pages.faq.questions.24.answer') },
      { question: t('pages.faq.questions.25'), answer: t('pages.faq.questions.25.answer') },
      { question: t('pages.faq.questions.26'), answer: t('pages.faq.questions.26.answer') },
      { question: t('pages.faq.questions.27'), answer: t('pages.faq.questions.27.answer') },
    ].filter(
      ({ question, answer }) =>
        question.toLowerCase().includes(filter.toLowerCase()) ||
        textContent(answer).toLowerCase().includes(filter.toLowerCase())
    )
  }, [t, filter])
}

export default useFaqQuestions
