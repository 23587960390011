import { Typography } from '@mui/material'
import { muiTheme } from '../../../theme'
import Link from '../../ui/Link'

type Props = {
  children: React.ReactNode
  href?: string
  onClick?: (event: MouseEvent) => void
  bold?: boolean
}

const MenuLink = ({ children, href, onClick, bold = false }: Props) => {
  return (
    <Link
      href={href}
      onClick={onClick}
      sx={{
        textDecoration: 'none',
        color: muiTheme.palette.text.primary,
        '&:hover': { textDecoration: 'underline', textDecorationColor: muiTheme.palette.info.main },
      }}
    >
      <Typography sx={{ fontWeight: bold ? 'bold' : 'normal' }}>{children}</Typography>
    </Link>
  )
}

export default MenuLink
