import { Grid, Typography } from '@mui/material'
import { EventItem } from '../../types/event'
import useTranslate from '../../utils/translations/useTranslate'
import LoadingOverlay from '../LoadingOverlay'
import EventCard from './EventCard'

type Props = {
  events?: EventItem[]
  isLoading: boolean
  isError: boolean
}

const InexRecommends = ({ events, isLoading, isError }: Props) => {
  const { t } = useTranslate()

  if (isLoading) {
    return <LoadingOverlay />
  }

  if (!events || isError) {
    return <>{t('errors.loading')}</>
  }

  return (
    <Grid container spacing={2} sx={{ paddingY: 6, position: 'relative' }}>
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          {t('homepage.inexRecommends.title')}
        </Typography>
      </Grid>
      {events.map((event) => (
        <Grid key={event.id} item xs={12} md={6} lg={4}>
          <EventCard event={event} />
        </Grid>
      ))}

      {!events.length && (
        <Grid item xs={12}>
          <Typography gutterBottom>{t('events.noResults')}</Typography>
        </Grid>
      )}
    </Grid>
  )
}

export default InexRecommends
