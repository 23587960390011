import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import CountUp from 'react-countup'
import styled from 'styled-components'
import Link from '../ui/Link'
import { ExpandMore as ExpandMoreIcon, PictureAsPdf } from '@mui/icons-material'
import useTranslate from '../../utils/translations/useTranslate'

type CountUpValueProps = {
  value: number
}

const StyledCountUpValue = styled(CountUp)`
  text-align: center;
  font-size: 3.2rem;
  line-height: 3.2rem;
`

const CountUpValue = ({ value }: CountUpValueProps) => (
  <StyledCountUpValue end={Number(value)} duration={2} separator=","></StyledCountUpValue>
)

const StatisticsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
  padding: 10px;
`

const InexIdentificationInfo = () => {
  const { t } = useTranslate()

  return (
    <>
      <Table size="small">
        <TableBody>
          <TableRow sx={{ border: 0 }}>
            <TableCell scope="row" sx={{ border: 0 }}>
              {t('pages.2percent.identificationData.organizationName')}:
            </TableCell>
            <TableCell sx={{ border: 0 }}>INEX Slovakia – občianske združenie</TableCell>
          </TableRow>
          <TableRow sx={{ border: 0 }}>
            <TableCell scope="row" sx={{ border: 0 }}>
              {t('pages.2percent.identificationData.address')}:
            </TableCell>
            <TableCell sx={{ border: 0 }}>Prokopova 15, 851 01 Bratislava</TableCell>
          </TableRow>
          <TableRow sx={{ border: 0 }}>
            <TableCell scope="row" sx={{ border: 0 }}>
              {t('pages.2percent.identificationData.organizationName')}:
            </TableCell>
            <TableCell sx={{ border: 0 }}>občianske združenie</TableCell>
          </TableRow>
          <TableRow sx={{ border: 0 }}>
            <TableCell scope="row" sx={{ border: 0 }}>
              {t('pages.2percent.identificationData.ico')}IČO:
            </TableCell>
            <TableCell sx={{ border: 0 }}>
              30804027 ({t('pages.2percent.identificationData.icoNote')})
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Typography variant="body2" gutterBottom sx={{ ml: 2 }}>
        <i>({t('pages.2percent.identificationData.sidNote')})</i>
      </Typography>
    </>
  )
}

const TwoPercentContent = () => {
  const { t } = useTranslate()

  return (
    <Grid container item sx={{ minHeight: 450, paddingTop: 4, marginBottom: 8 }}>
      <Box>
        <Typography variant="h4" gutterBottom>
          {t('pages.2percent.title')}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {t('pages.2percent.introduction1')} <b>{t('pages.2percent.introduction1bold')}</b>
          {t('pages.2percent.introduction1rest')}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {t('pages.2percent.introduction2')} <b>{t('pages.2percent.introduction2bold')}</b>{' '}
          {t('pages.2percent.introduction2rest')} <b>{t('pages.2percent.introduction2restBold')}</b>
        </Typography>
        <StatisticsWrapper>
          <div>
            <Typography variant="body1" gutterBottom>
              <b>{t('pages.2percent.statsVolunteering')}</b>
            </Typography>
            <CountUpValue value={302} />
          </div>
          <div>
            <Typography variant="body1" gutterBottom>
              <b>{t('pages.2percent.statsEducation')}</b>
            </Typography>
            <CountUpValue value={360} />
          </div>
          <div>
            <Typography variant="body1" gutterBottom>
              <b>{t('pages.2percent.statsPromo')}</b>
            </Typography>
            <CountUpValue value={2353} />
          </div>
          <div>
            <Typography variant="body1" gutterBottom>
              <b>{t('pages.2percent.statsRepresentation')}</b>
            </Typography>
            <CountUpValue value={50} />
          </div>
        </StatisticsWrapper>
        <Typography variant="body1" gutterBottom>
          {t('pages.2percent.thankYou')}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {t('pages.2percent.introduction3')}{' '}
          <Link href="mailto:finance@inex.sk">finance@inex.sk</Link>{' '}
          {t('pages.2percent.introduction3rest')}
        </Typography>
        <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
          {t('pages.2percent.identificationData.title')}
        </Typography>
        <InexIdentificationInfo />

        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          {t('pages.2percent.manual.title')}
        </Typography>
        <div>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{t('pages.2percent.manual.foNoTax.title')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" gutterBottom>
                <b>{t('pages.2percent.manual.foNoTax.description1Bold')}</b>{' '}
                {t('pages.2percent.manual.foNoTax.description1Rest')}
              </Typography>
              <Typography component="div" variant="body1" gutterBottom>
                <b>{t('pages.2percent.manual.foNoTax.description2Bold')}</b>
                <ul>
                  <li>
                    {t('pages.2percent.manual.foNoTax.description2.1')}{' '}
                    <b>{t('pages.2percent.manual.foNoTax.description2.1bold')}</b>
                  </li>
                  <li>
                    {t('pages.2percent.manual.foNoTax.description2.2')}{' '}
                    <b>{t('pages.2percent.manual.foNoTax.description2.2bold')}</b>{' '}
                    {t('pages.2percent.manual.foNoTax.description2.2rest')}
                    <Link href="mailto:out@inex.sk">out@inex.sk</Link>{' '}
                    {t('pages.2percent.manual.foNoTax.description2.2rest2')}
                  </li>
                  <li>
                    {t('pages.2percent.manual.foNoTax.description2.3')}{' '}
                    <Link
                      href="https://www.financnasprava.sk/sk/elektronicke-sluzby/verejne-sluzby/zistenie-miestnej-prislusnost"
                      target="_blank"
                    >
                      {t('pages.2percent.manual.foNoTax.description2.3rest1')}
                    </Link>{' '}
                    <b>{t('pages.2percent.manual.foNoTax.description2.3rest2')}</b>.
                  </li>
                </ul>
                <Typography>{t('pages.2percent.manual.foNoTax.description2.pdfTitle')}</Typography>
                <Typography>
                  <PictureAsPdf />{' '}
                  <Link
                    href="https://www.inex.sk/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb29QIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--9871a92abf4b85496f59d164be7049340b4734f7/V2Pv21_V.pdf?disposition=preview&amp;lang=sk"
                    target="_blank"
                  >
                    {t('pages.2percent.manual.foNoTax.description2.pdfFilename')}
                  </Link>
                </Typography>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{t('pages.2percent.manual.foTax.title')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" gutterBottom>
                <b>{t('pages.2percent.manual.foTax.description1')}</b>{' '}
                {t('pages.2percent.manual.foTax.description1.2')}{' '}
                <b>{t('pages.2percent.manual.foTax.description1.3')}</b>.
              </Typography>

              <Typography variant="body1" gutterBottom>
                <b>{t('pages.2percent.manual.foTax.description2')}</b>
              </Typography>
              <InexIdentificationInfo />
              <Typography component="div" variant="body1" gutterBottom>
                <b>{t('pages.2percent.manual.foTax.description3')}</b>
                <ul>
                  <li>
                    {t('pages.2percent.manual.foTax.description3.2')}{' '}
                    <b>{t('pages.2percent.manual.foTax.description3.3')}</b>.
                  </li>
                  <li>
                    {t('pages.2percent.manual.foTax.description3.4')}{' '}
                    <b>{t('pages.2percent.manual.foTax.description3.5')}</b>{' '}
                    {t('pages.2percent.manual.foTax.description3.6')}{' '}
                    <Link href="mailto:out@inex.sk">out@inex.sk</Link>{' '}
                    {t('pages.2percent.manual.foTax.description3.7')}
                  </li>
                  <li>
                    {t('pages.2percent.manual.foTax.description3.8')}{' '}
                    <Link
                      href="https://www.financnasprava.sk/sk/elektronicke-sluzby/verejne-sluzby/zistenie-miestnej-prislusnost"
                      target="_blank"
                    >
                      {t('pages.2percent.manual.foTax.description3.9')}
                    </Link>{' '}
                    <b>{t('pages.2percent.manual.foTax.description3.10')}</b>.
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{t('pages.2percent.manual.po.title')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" gutterBottom>
                {t('pages.2percent.manual.po.description1')}{' '}
                <b>{t('pages.2percent.manual.po.description1.2')}</b>.
              </Typography>
              <Typography variant="body1" gutterBottom>
                <b>{t('pages.2percent.manual.po.description2')}</b>
              </Typography>
              <InexIdentificationInfo />
              <Typography component="div" variant="body1" gutterBottom>
                <ul>
                  <li>
                    {t('pages.2percent.manual.po.description3')}{' '}
                    <Link
                      href="https://www.financnasprava.sk/sk/elektronicke-sluzby/verejne-sluzby/zistenie-miestnej-prislusnost"
                      target="_blank"
                    >
                      {t('pages.2percent.manual.po.description3.2')}{' '}
                    </Link>{' '}
                    <b>{t('pages.2percent.manual.po.description3.3')}</b>.
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </Box>
    </Grid>
  )
}

export default TwoPercentContent
