import LoadingOverlay from '../components/LoadingOverlay'
import Post from '../components/media/Post'
import useFetchData from '../hooks/useFetchData'
import { useParams } from 'react-router-dom'
import { Post as PostType } from '../types/media'
import useTranslate from '../utils/translations/useTranslate'

const BlogDetail = () => {
  const { id } = useParams()
  const { data: post, isLoading, isError } = useFetchData<PostType>('/v1/blogs', { id })
  const { t } = useTranslate()

  if (isLoading) {
    return <LoadingOverlay />
  }

  if (!post || isError) {
    return <>{t('errors.loading')}</>
  }

  return <Post post={post} />
}

export default BlogDetail
