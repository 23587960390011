import { Grid, Pagination } from '@mui/material'
import { useMemo, useState } from 'react'
import LoadingOverlay from '../components/LoadingOverlay'
import PostList from '../components/media/PostList'
import PostMenu from '../components/media/PostMenu'
import Menu from '../components/media/Menu'
import ContentContainer from '../components/ui/ContentContainer'
import useFetchData from '../hooks/useFetchData'
import { PostList as PostListType } from '../types/media'
import Breadcrumbs from '../components/Breadcrumbs'
import useTranslate from '../utils/translations/useTranslate'
import { muiTheme } from '../theme'

type PublicationPageContentProps = {
  publicationsData: PostListType | undefined
  isLoading: boolean
  isError: boolean
  page: number
  setPage: (page: number) => void
}

const PublicationPageContent = ({
  publicationsData,
  isLoading,
  isError,
  page,
  setPage,
}: PublicationPageContentProps) => {
  const { t } = useTranslate()

  if (isLoading) {
    return <LoadingOverlay />
  }

  if (!publicationsData || isError) {
    return <>{t('errors.loading')}</>
  }

  if (!publicationsData.posts.length) {
    return <>{t('publications.noResults')}</>
  }

  return (
    <>
      <PostList posts={publicationsData.posts} />
      {publicationsData.pageCount > 1 && (
        <Pagination
          page={page}
          onChange={(_, pageNumber) => setPage(pageNumber)}
          count={publicationsData.pageCount}
          color="primary"
          sx={{ mt: 2 }}
        />
      )}
    </>
  )
}

const PublicationPage = () => {
  const [query, setQuery] = useState<string>('')
  const [page, setPage] = useState<number>(1)

  const { data, isLoading, isError } = useFetchData<PostListType>('/v1/publications/list', {
    query,
    page,
  })

  // remap href as a link to internal page if not set from db
  const publicationsData = useMemo(() => {
    if (!data) {
      return
    }

    const newData = data
    newData.posts = newData.posts.map((post) => ({
      ...post,
      href: post.href ? post.href : `/media/publications/${post.id}`,
    }))
    return newData
  }, [data])

  return (
    <div style={{ width: '100%' }}>
      <ContentContainer background={muiTheme.palette.background.paper}>
        <Breadcrumbs />
        <Grid container sx={{ minHeight: 450, paddingTop: 4, marginBottom: 8 }}>
          <Grid item sm={12} md={8}>
            <PublicationPageContent
              publicationsData={publicationsData}
              isLoading={isLoading}
              isError={isError}
              page={page}
              setPage={setPage}
            />
          </Grid>
          <Grid item sm={12} md={4}>
            <Menu />
            <PostMenu query={query} setQuery={setQuery} />
          </Grid>
        </Grid>
      </ContentContainer>
    </div>
  )
}

export default PublicationPage
