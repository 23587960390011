import { Box, Typography } from '@mui/material'
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from '@mui/lab'
import React from 'react'
import useTranslate from '../../utils/translations/useTranslate'

const HistoryTimelineYear = ({ year, children }: { year: string; children: React.ReactNode }) => {
  return (
    <TimelineItem sx={{ flexDirection: 'row' }}>
      <TimelineContent color="secondary" sx={{ flex: 'initial' }}>
        {year}
      </TimelineContent>
      <TimelineSeparator>
        <TimelineDot variant="outlined" color="secondary" />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineOppositeContent>{children}</TimelineOppositeContent>
    </TimelineItem>
  )
}

const HistoryTimeline = () => {
  const { t } = useTranslate()

  return (
    <>
      <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
        {t('pages.history.timeline.title')}
      </Typography>
      <Timeline position="left" sx={{ width: '100%' }}>
        <HistoryTimelineYear year="1993">
          <Typography variant="h6" gutterBottom>
            {t('pages.history.timeline.1993.title')}
          </Typography>
          <ul>
            <li>{t('pages.history.timeline.1993.description1')}</li>
            <li>{t('pages.history.timeline.1993.description2')}</li>
            <li>{t('pages.history.timeline.1993.description3')}</li>
            <li>{t('pages.history.timeline.1993.description4')}</li>
          </ul>
        </HistoryTimelineYear>
        <HistoryTimelineYear year="1994">
          <Typography variant="h6">{t('pages.history.timeline.1994.title')}</Typography>
        </HistoryTimelineYear>
        <HistoryTimelineYear year="1998">
          <Typography variant="h6">{t('pages.history.timeline.1998.title')}</Typography>
        </HistoryTimelineYear>
        <HistoryTimelineYear year="2001">
          <Typography variant="h6">{t('pages.history.timeline.2001.title')}</Typography>
        </HistoryTimelineYear>
        <HistoryTimelineYear year="2002">
          <Typography variant="h6">{t('pages.history.timeline.2002.title')}</Typography>
        </HistoryTimelineYear>
        <HistoryTimelineYear year="2006">
          <Typography variant="h6">{t('pages.history.timeline.2006.title')}</Typography>
        </HistoryTimelineYear>
        <HistoryTimelineYear year="2009">
          <Typography variant="h6">{t('pages.history.timeline.2009.title')}</Typography>
          <img
            src="https://inex.sk/system/tinymce/images/original/mssr_farba.png?1508403108"
            loading="lazy"
            alt="Ministerstvo školstva, vedy, výskumu a športu Slovenskej republiky"
            style={{ maxWidth: 300 }}
          />
          <Typography variant="h6">{t('pages.history.timeline.2009.description1')}</Typography>
          <img
            src="https://inex.sk/system/tinymce/images/original/dobrovolnik_roka.jpg?1508403402"
            loading="lazy"
            alt="Ocenenie Dobrovoľník roka"
            style={{ maxWidth: 300 }}
          />
        </HistoryTimelineYear>
        <HistoryTimelineYear year="2011">
          <Typography variant="h6">{t('pages.history.timeline.2011.title')}</Typography>
          <img
            src="https://inex.sk/system/tinymce/images/original/5e0235bc-31ff-4704-83ae-edbec7f55961.png?1508433354"
            loading="lazy"
            alt="Ocenenie MOST Radou mládeže Slovenska"
            style={{ maxWidth: 300 }}
          />
          <Typography variant="h6">{t('pages.history.timeline.2011.description1')}</Typography>
          <img
            src="https://inex.sk/system/tinymce/images/original/dobrovolnik_roka.jpg?1508403402"
            loading="lazy"
            alt="Zahraničný dobrovoľník roka na Slovensku"
            style={{ maxWidth: 300 }}
          />
        </HistoryTimelineYear>
        <HistoryTimelineYear year="2014">
          <Typography variant="h6">{t('pages.history.timeline.2014.title')}</Typography>
        </HistoryTimelineYear>
        <HistoryTimelineYear year="2015">
          <Typography variant="h6">{t('pages.history.timeline.2015.title')}</Typography>
        </HistoryTimelineYear>
        <HistoryTimelineYear year="2016">
          <Typography variant="h6">{t('pages.history.timeline.2016.title')}</Typography>
          <img
            src="https://inex.sk/system/tinymce/images/original/mssr_farba.png?1508403108"
            loading="lazy"
            alt="Ministerstvo školstva, vedy, výskumu a športu Slovenskej republiky"
            style={{ maxWidth: 300 }}
          />
        </HistoryTimelineYear>
        <HistoryTimelineYear year="2020">
          <Typography variant="h6">{t('pages.history.timeline.2020.title')}</Typography>
        </HistoryTimelineYear>
      </Timeline>
    </>
  )
}

const History = () => {
  const { t } = useTranslate()

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        {t('pages.history.title')}
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
        {t('pages.history.paragraph1.title')}
      </Typography>
      <Typography
        variant="body1"
        component="p"
        gutterBottom
        dangerouslySetInnerHTML={{
          __html: t('pages.history.paragraph1.description1'),
        }}
      />
      <Typography
        variant="body1"
        component="p"
        gutterBottom
        dangerouslySetInnerHTML={{
          __html: t('pages.history.paragraph1.description2'),
        }}
      />
      <Typography
        variant="body1"
        component="p"
        gutterBottom
        dangerouslySetInnerHTML={{
          __html: t('pages.history.paragraph1.description3'),
        }}
      />

      <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
        {t('pages.history.paragraph2.title')}
      </Typography>
      <Typography
        variant="body1"
        component="p"
        gutterBottom
        dangerouslySetInnerHTML={{
          __html: t('pages.history.paragraph2.description1'),
        }}
      />
      <Typography
        variant="body1"
        component="p"
        gutterBottom
        dangerouslySetInnerHTML={{
          __html: t('pages.history.paragraph2.description2'),
        }}
      />
      <Typography
        variant="body1"
        component="p"
        gutterBottom
        dangerouslySetInnerHTML={{
          __html: t('pages.history.paragraph2.description3'),
        }}
      />

      <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
        {t('pages.history.paragraph3.title')}
      </Typography>
      <Typography
        variant="body1"
        component="p"
        gutterBottom
        dangerouslySetInnerHTML={{
          __html: t('pages.history.paragraph3.description1'),
        }}
      />
      <Typography
        variant="body1"
        component="p"
        gutterBottom
        dangerouslySetInnerHTML={{
          __html: t('pages.history.paragraph3.description2'),
        }}
      />
      <Typography
        variant="body1"
        component="p"
        gutterBottom
        dangerouslySetInnerHTML={{
          __html: t('pages.history.paragraph3.description3'),
        }}
      />
      <Typography
        variant="body1"
        component="p"
        gutterBottom
        dangerouslySetInnerHTML={{
          __html: t('pages.history.paragraph3.description4'),
        }}
      />
      <Typography
        variant="body1"
        component="p"
        gutterBottom
        dangerouslySetInnerHTML={{
          __html: t('pages.history.paragraph3.description5'),
        }}
      />

      <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
        {t('pages.history.paragraph4.title')}
      </Typography>
      <Typography
        variant="body1"
        component="p"
        gutterBottom
        dangerouslySetInnerHTML={{
          __html: t('pages.history.paragraph4.description1'),
        }}
      />
      <Typography
        variant="body1"
        component="p"
        gutterBottom
        dangerouslySetInnerHTML={{
          __html: t('pages.history.paragraph4.description2'),
        }}
      />

      <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
        {t('pages.history.paragraph5.title')}
      </Typography>
      <Typography variant="body1" component="p" gutterBottom>
        {t('pages.history.paragraph5.description1')}
      </Typography>
      <Typography variant="body1" component="p" gutterBottom>
        {t('pages.history.paragraph5.description2')}
      </Typography>
      <Typography
        variant="body1"
        component="p"
        gutterBottom
        dangerouslySetInnerHTML={{
          __html: t('pages.history.paragraph5.description3'),
        }}
      />

      <HistoryTimeline />
    </Box>
  )
}

export default History
