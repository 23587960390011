import styled from 'styled-components'
import { muiTheme } from '../../theme'

const ContentContainer = styled.div<{ background?: string }>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 5px;

  width: ${100 - 2 * 15}%;
  background: ${({ background }) => background || muiTheme.palette.primary.main};
  padding: 0 15% 0 15%;

  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
`

export default ContentContainer
