import styled from 'styled-components'

import logoKpmg from '../../../assets/partners/logo_KPMG.jpg'
import logoMS from '../../../assets/partners/logo_MS.png'
import logoErasmus from '../../../assets/partners/logo_erasmus.jpg'
import logoIuventa from '../../../assets/partners/logo_iuventa.png'
import logoNadaciaPontis from '../../../assets/partners/logo_nadacia_pontis.png'
import logoOfficeland from '../../../assets/partners/logo_officeland.png'
import logoZlataStudna from '../../../assets/partners/logo_zlata_studna.png'
import logoZones from '../../../assets/partners/logo_zones.png'
import logoNadaciaEset from '../../../assets/partners/logo_nadacia_eset.png'
import websupportImage from '../../../assets/websupport.png'
import { muiTheme } from '../../../theme'
import { Grid } from '@mui/material'

type PartnerProps = {
  imgSrc: string
  href: string
  title: string
}

const PARTNERS: PartnerProps[] = [
  { imgSrc: logoMS, href: 'https://www.minedu.sk/', title: 'MŠVVaŠ SR' },
  { imgSrc: logoIuventa, href: 'https://www.iuventa.sk/sk/IUVENTA-home.alej', title: 'Iuventa' },
  { imgSrc: logoErasmus, href: 'http://erasmusplus.sk/', title: 'Erasmus +' },
  { imgSrc: logoKpmg, href: 'https://home.kpmg.com/sk/sk/home.html', title: 'KPMG' },
  { imgSrc: logoOfficeland, href: 'https://officeland.sk/', title: 'Officeland' },
  { imgSrc: logoZones, href: 'https://www.zones.sk/', title: 'zones.sk' },
  { imgSrc: logoZlataStudna, href: 'http://zlatastudna.sk/', title: 'Zlatá studňa' },
  { imgSrc: logoNadaciaPontis, href: 'http://www.nadaciapontis.sk/', title: 'Nadácia Pontis' },
  { imgSrc: logoNadaciaEset, href: 'https://www.nadaciaeset.sk/', title: 'Nadácia ESET' },
]

const WebsupportWrapper = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledPartner = styled.a`
  background: #ffffff;
  flex: 1;
  min-width: 150px;
  max-width: 300px;
  height: 60px;
  padding: 5px;
  border-radius: 8px;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid ${muiTheme.palette.secondary.main};
  }
`

const Partners = () => (
  <>
    <Grid container spacing={2}>
      <Grid
        item
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexWrap: 'wrap',
          gap: 4,
        }}
      >
        {PARTNERS.map((partner, key) => (
          <StyledPartner key={key} href={partner.href} title={partner.title} target="_blank">
            <img
              src={partner.imgSrc}
              alt={partner.title}
              style={{ width: '100%', height: '100%', objectFit: 'contain' }}
            />
          </StyledPartner>
        ))}
      </Grid>
    </Grid>
    <Grid item sx={{ marginTop: 4 }}>
      <WebsupportWrapper
        href="https://websupport.sk/"
        title="Hosting zadarmo, domény, servery"
        target="_blank"
      >
        <img
          style={{ borderRadius: 8, width: '100%', maxWidth: 338 }}
          alt="Sponzorovaný hosting od spoločnosti WebSupport.sk"
          title="Hosting zadarmo, domény, servery"
          src={websupportImage}
          loading="lazy"
        />
        <img
          style={{ border: 0 }}
          src="http://provizie.websupport.sk/scripts/imp.php?site=freeweb&ban=fba454f9"
          width="1"
          height="1"
          alt=""
          loading="lazy"
        />
      </WebsupportWrapper>
    </Grid>
  </>
)

export default Partners
