import LoadingOverlay from '../components/LoadingOverlay'
import useFetchData from '../hooks/useFetchData'
import { useParams } from 'react-router-dom'
import { EventDetail as EventDetailType } from '../types/event'
import { EventDetailContent } from '../components/events/search/EventDetailModal'
import ContentContainer from '../components/ui/ContentContainer'
import { Typography } from '@mui/material'
import Breadcrumbs from '../components/Breadcrumbs'
import useTranslate from '../utils/translations/useTranslate'
import { muiTheme } from '../theme'

const EventDetail = () => {
  const { id } = useParams()
  const {
    data: event,
    isLoading,
    isError,
  } = useFetchData<EventDetailType>('/v1/events/get', { id })
  const { t } = useTranslate()

  if (isLoading) {
    return <LoadingOverlay />
  }

  if (!event || isError) {
    return <>{t('errors.loading')}</>
  }

  return (
    <div style={{ width: '100%' }}>
      <ContentContainer background={muiTheme.palette.background.paper}>
        <Breadcrumbs />
        <div style={{ padding: 10, marginBottom: 50 }}>
          <Typography component="div" variant="body2">
            {event?.code}
          </Typography>
          <Typography component="div" variant="h4">
            {event?.title || 'Event'}
          </Typography>

          <EventDetailContent event={event} isLoading={isLoading} isError={isError} />
        </div>
      </ContentContainer>
    </div>
  )
}

export default EventDetail
