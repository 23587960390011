import { ApplicationType } from '../../types/application'
import { EventItem, EventTypeCode } from '../../types/event'

const WORKCAMP_APPLICATION_EVENT_TYPES = [
  EventTypeCode.OneDayVolunteering,
  EventTypeCode.WeekendVolunteering,
  EventTypeCode.ESC,
  EventTypeCode.SlovakCamp,
  EventTypeCode.AbroadCamp,
  EventTypeCode.LMTV,
  EventTypeCode.VirtualCamp,
  EventTypeCode.LeadersEdu,
  EventTypeCode.YouthExchangesEdu,
  EventTypeCode.OutgoingEdu,
  EventTypeCode.OfflinePromo,
]

const SHORT_APPLICATION_EVENT_TYPES = [EventTypeCode.MemberApplication]

const BASIC_APPLICATION_EVENT_TYPES = [EventTypeCode.OneDayEdu]

/*
  Calculates application type from application events
  Larger application type is always pushed before smaller ones (WORKCAMP > SHORT > BASIC)
  If application type cannot be determined (no events or other specific setting), undefined is returned
*/
export const determineApplicationType = (
  eventTypeCodes: EventTypeCode[]
): ApplicationType | undefined => {
  const hasWorkcampApplicationCode = eventTypeCodes.some((code) =>
    WORKCAMP_APPLICATION_EVENT_TYPES.includes(code)
  )

  if (hasWorkcampApplicationCode) {
    return ApplicationType.WORKCAMP
  }

  const hasShortApplicationCode = eventTypeCodes.some((code) =>
    SHORT_APPLICATION_EVENT_TYPES.includes(code)
  )

  if (hasShortApplicationCode) {
    return ApplicationType.SHORT
  }

  const hasBasicApplicationCode = eventTypeCodes.some((code) =>
    BASIC_APPLICATION_EVENT_TYPES.includes(code)
  )

  if (hasBasicApplicationCode) {
    return ApplicationType.BASIC
  }

  return undefined
}

export const determineApplicationTypeFromEvents = (
  events: EventItem[]
): ApplicationType | undefined => {
  const eventTypeCodes = events.map((event) => event.eventType)

  return determineApplicationType(eventTypeCodes as EventTypeCode[])
}

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}
