import { Typography, Box } from '@mui/material'
import useTranslate from '../../utils/translations/useTranslate'
import Link from '../ui/Link'

const OrganizationStructure = () => {
  const { t } = useTranslate()

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        {t('pages.aboutUs.organizationalStructure.title')}
      </Typography>
      <Typography variant="h6" gutterBottom>
        {t('pages.aboutUs.organizationalStructure.chairman')}
      </Typography>
      <Typography variant="body1" gutterBottom>
        Peter Béreš
      </Typography>
      <Typography variant="h6" sx={{ mt: 2 }}>
        {t('pages.aboutUs.organizationalStructure.coordinationCommiteeMembers')}
      </Typography>
      <Typography component="div" variant="body1" gutterBottom>
        <ul>
          <li>Mária Ďurajková</li>
          <li>Barbara Krištofíková</li>
          <li>Monika Mudroňová</li>
          <li>Filip Sakala</li>
          <li>Miroslava Zváčová</li>
        </ul>
      </Typography>
      <Typography variant="h6" gutterBottom>
        {t('pages.aboutUs.organizationalStructure.chiefControlor')}
      </Typography>
      <Typography variant="body1" gutterBottom>
        Yuliya Kruhlik
      </Typography>

      <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
        {t('pages.aboutUs.organizationalStructure.documents')}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {t('pages.aboutUs.organizationalStructure.weComplyWith')}{' '}
        <Link href="http://www.zakonypreludi.sk/zz/2011-406/znenie-20160101#cl6" color="secondary">
          {t('pages.aboutUs.organizationalStructure.weComplyWithAct')}
        </Link>
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Link
          href="https://www.iuventa.sk/files/sprava%20o%20mladezi%202018_popularizovana.pdf"
          color="secondary"
        >
          {t('pages.aboutUs.organizationalStructure.reportAboutYouth2018')}
        </Link>
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Link
          href="https://inex.sk/system/tinymce/images/original/inex_stanovy.pdf?1517926739"
          color="secondary"
        >
          {t('pages.aboutUs.organizationalStructure.constitution')}
        </Link>
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Link
          href="https://inex.sk/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb01QIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--b669c82b933d901d955babee8d67c7a3ba13e979/Vnu%CC%81torny%CC%81%20predpis%2001-2007%20-%20kontrolo%CC%81r.pdf?disposition=preview&lang=sk"
          color="secondary"
        >
          {t('pages.aboutUs.organizationalStructure.internalDirective')}
        </Link>
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Link
          href="https://inex.sk/system/tinymce/images/original/Sprava_o_cinnosti_2015.pdf?1517927117"
          color="secondary"
        >
          {t('pages.aboutUs.organizationalStructure.report')} 2015
        </Link>
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Link
          href="https://inex.sk/system/tinymce/images/original/Sprava_o-_cinnosti_2016.pdf?1517927357"
          color="secondary"
        >
          {t('pages.aboutUs.organizationalStructure.report')} 2016
        </Link>
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Link
          href="https://inex.sk/system/tinymce/images/original/2017_Spr%C3%A1va_o_%C4%8Dinnosti_.pdf?1521633617"
          color="secondary"
        >
          {t('pages.aboutUs.organizationalStructure.report')} 2017
        </Link>
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Link
          href="https://inex.sk/system/tinymce/images/original/2018_Spr%C3%A1va_o_%C4%8Dinnosti_.pdf?1557133501"
          color="secondary"
        >
          {t('pages.aboutUs.organizationalStructure.report')} 2018
        </Link>
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Link
          href="https://inex.sk/system/tinymce/images/original/2019_Spr%C3%A1va_o_%C4%8Dinnosti_.pdf?1601540046"
          color="secondary"
        >
          {t('pages.aboutUs.organizationalStructure.report')} 2019
        </Link>
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Link
          href="https://inex.sk/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcG9PIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--b427585a38de41ff45a410380abeab4025e8033d/2020%20Spr%C3%A1va%20o%20%C4%8Dinnosti.pdf?disposition=preview&lang=sk"
          color="secondary"
        >
          {t('pages.aboutUs.organizationalStructure.report')} 2020
        </Link>
      </Typography>

      <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
        {t('pages.aboutUs.organizationalStructure.incomesAndExpenses')} 2020
      </Typography>

      <img
        src="https://www.inex.sk/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb1VQIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1a7a5111ec86fcf90f79a9e548c4285286606aa6/vynosy2020.png?lang=sk"
        loading="lazy"
        alt="Výnosy za rok 2020"
      />
      <img
        src="https://www.inex.sk/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb1lQIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--59ec7c87770984a93183ae3904f9eda3244ccc3a/naklady2020.png?lang=sk"
        loading="lazy"
        alt="Náklady za rok 2020"
      />
    </Box>
  )
}

export default OrganizationStructure
