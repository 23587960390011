import { Checkbox as MuiCheckbox, FormControlLabel, Typography } from '@mui/material'

type Props = {
  checked?: boolean
  color?: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
  label?: string | React.ReactNode
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const Checkbox = ({ checked, color, label, onChange }: Props) => {
  return (
    <FormControlLabel
      control={<MuiCheckbox checked={checked} color={color} onChange={onChange} />}
      label={<Typography color={color === 'error' ? 'error' : undefined}>{label}</Typography>}
    />
  )
}

export default Checkbox
