import { Drawer as MuiDrawer, IconButton, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import styled from 'styled-components'
import { muiTheme } from '../../theme'
import { Close } from '@mui/icons-material'

type Props = {
  title?: React.ReactNode | string
  isOpen: boolean
  onClose?: () => void
  children: React.ReactNode
}

const StyledDrawer = styled(MuiDrawer)<{ viewportWidth: number }>`
  & > .MuiDrawer-paper {
    width: ${({ viewportWidth }) => viewportWidth}vw;
  }
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: ${muiTheme.palette.primary.main};
  height: 35px;
  padding: 6px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  color: ${muiTheme.palette.secondary.main};
`
const Body = styled.div`
  background: ${muiTheme.palette.background.paper};
  height: 100%;
  padding: 6px;
`

const Drawer = ({ title, isOpen, onClose, children }: Props) => {
  const theme = useTheme()
  const isMediumScreenAndUp = useMediaQuery(theme.breakpoints.up('md'))
  const isLargeScreenAndUp = useMediaQuery(theme.breakpoints.up('lg'))

  return (
    <StyledDrawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      viewportWidth={isLargeScreenAndUp ? 50 : isMediumScreenAndUp ? 75 : 95}
    >
      <Header>
        {title}
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Header>
      <Body>{children}</Body>
    </StyledDrawer>
  )
}

export default Drawer
