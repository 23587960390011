import { useState } from 'react'
import BasicInfo from '../components/applications/BasicInfo'
import MyApplications from '../components/applications/MyApplications'
import Result from '../components/applications/Result'
import Review from '../components/applications/Review'
import { scrollToTop } from '../components/applications/utils'
import Volunteering from '../components/applications/Volunteering'
import Breadcrumbs from '../components/Breadcrumbs'
import LoadingOverlay from '../components/LoadingOverlay'
import ContentContainer from '../components/ui/ContentContainer'
import useEventApplicationContext from '../contexts/useEventApplicationContext'
import useFetchData from '../hooks/useFetchData'
import { muiTheme } from '../theme'
import { ApplicationType } from '../types/application'
import { Config } from '../types/baseConfig'
import { EventTypeCode } from '../types/event'
import useTranslate from '../utils/translations/useTranslate'

type ApplicationFormPage = 'default' | 'basic-info' | 'volunteering' | 'review' | 'result'

const MyBag = () => {
  const [formPage, setFormPage] = useState<ApplicationFormPage>('default')
  const [formData, setFormData] = useState<any>({})
  const { applicationType } = useEventApplicationContext()
  const { data: configData, isLoading, isError } = useFetchData<Config>('/v1/base/config')
  const { t } = useTranslate()

  if (isLoading) {
    return <LoadingOverlay />
  }

  if (!configData || !configData.eventTypes || isError) {
    return <>{t('errors.loading')}</>
  }

  const getFormPageComponent = () => {
    switch (formPage) {
      case 'default':
        return (
          <MyApplications
            goToNextFormPage={() => {
              setFormPage('basic-info')
              scrollToTop()
            }}
            eventTypes={configData?.eventTypes || []}
          />
        )
      case 'basic-info':
        const nextStep =
          applicationType === ApplicationType.BASIC || applicationType === ApplicationType.SHORT
            ? 'review'
            : 'volunteering'
        return (
          <BasicInfo
            goToNextFormPage={() => {
              setFormPage(nextStep)
              scrollToTop()
            }}
            goToPreviousFormPage={() => {
              setFormPage('default')
              scrollToTop()
            }}
          />
        )
      case 'volunteering':
        return (
          <Volunteering
            goToNextFormPage={() => {
              setFormPage('review')
              scrollToTop()
            }}
            goToPreviousFormPage={() => {
              setFormPage('basic-info')
              scrollToTop()
            }}
          />
        )
      case 'review':
        const previousStep =
          applicationType === ApplicationType.BASIC || applicationType === ApplicationType.SHORT
            ? 'basic-info'
            : 'volunteering'
        return (
          <Review
            goToNextFormPage={({ eventTypes }: { eventTypes: EventTypeCode[] }) => {
              setFormPage('result')
              setFormData({ eventTypes })
              scrollToTop()
            }}
            goToPreviousFormPage={() => {
              setFormPage(previousStep)
              scrollToTop()
            }}
            eventTypes={configData?.eventTypes || []}
          />
        )
      case 'result':
        return (
          <Result
            eventTypes={
              formData.eventTypes
                ? configData.eventTypes.filter((eventType) =>
                    formData.eventTypes.includes(eventType.code)
                  )
                : []
            }
          />
        )
    }

    return null
  }

  return (
    <div style={{ width: '100%' }}>
      <ContentContainer background={muiTheme.palette.background.paper}>
        <Breadcrumbs />
        {getFormPageComponent()}
      </ContentContainer>
    </div>
  )
}

export default MyBag
