import { Grid, Typography } from '@mui/material'
import styled from 'styled-components'
import useTranslate from '../../utils/translations/useTranslate'
import Button from '../ui/Button'
import { ReactComponent as Celebrating } from '../../assets/logo/celebrating.svg'
import { EventType } from '../../types/event'

type Props = {
  eventTypes: EventType[]
}

const ActionButtons = styled.div`
  display: flex;
  padding: 10px 0;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
`

const Result = ({ eventTypes }: Props) => {
  const { t } = useTranslate()
  return (
    <Grid container sx={{ paddingTop: 4, paddingBottom: 8 }}>
      <Typography variant="h4" gutterBottom>
        {t('pages.applications.result.title')}
      </Typography>
      <Grid item sm={12} md={10}>
        <Typography gutterBottom>
          {t('pages.applications.result.description1.1')}.{' '}
          {t('pages.applications.result.description1.2')}
        </Typography>

        <Typography gutterBottom>{t('pages.applications.result.description2')}</Typography>

        {eventTypes.map((eventType) => (
          <>
            <Typography variant="h6" gutterBottom>
              {eventType.title}
            </Typography>
            <Typography
              gutterBottom
              dangerouslySetInnerHTML={{
                __html: eventType.feesDescription,
              }}
            />
          </>
        ))}

        <ActionButtons>
          <Button color="secondary" variant="outlined" href="/">
            {t('pages.applications.result.backToWebAction')}
          </Button>
          <Button
            color="primary"
            href={`${process.env.REACT_APP_LEGACY_SERVER_ENDPOINT}/my-account`}
          >
            {t('pages.applications.result.myAccountAction')}
          </Button>
        </ActionButtons>
      </Grid>
      <Grid
        item
        sm={12}
        md={2}
        sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end' }}
      >
        <Celebrating width="100" />
      </Grid>
    </Grid>
  )
}

export default Result
