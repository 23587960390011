import Breadcrumbs from '../components/Breadcrumbs'
import TwoPercentContent from '../components/helpUs/TwoPercent'
import ContentContainer from '../components/ui/ContentContainer'
import { muiTheme } from '../theme'

const HelpTwoPercent = () => {
  return (
    <div style={{ width: '100%' }}>
      <ContentContainer background={muiTheme.palette.background.paper}>
        <Breadcrumbs />
        <TwoPercentContent />
      </ContentContainer>
    </div>
  )
}

export default HelpTwoPercent
