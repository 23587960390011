import { Box, Grid, Typography } from '@mui/material'
import useTranslate from '../../utils/translations/useTranslate'
import Link from '../ui/Link'

const DobromatContent = () => {
  const { t } = useTranslate()

  return (
    <Grid container item sx={{ minHeight: 450, paddingTop: 4, marginBottom: 8 }}>
      <Box>
        <Typography variant="h4" gutterBottom>
          {t('pages.dobromat.title')}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {t('pages.dobromat.description1')}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {t('pages.dobromat.description2')}
        </Typography>

        <Typography variant="h6" gutterBottom>
          {t('pages.dobromat.title2')}
        </Typography>

        <Typography component="div" variant="body1" gutterBottom>
          {t('pages.dobromat.description3.1')} <i>{t('pages.dobromat.description3.2')}</i>
          <ul>
            <li>
              {t('pages.dobromat.description3.3')}{' '}
              <Link href="https://dobromat.sk/" color="secondary" target="_blank">
                www.dobromat.sk
              </Link>
              {t('pages.dobromat.description3.4')}
            </li>
            <li>{t('pages.dobromat.description3.5')}</li>
          </ul>
        </Typography>

        <Typography variant="h6" gutterBottom>
          {t('pages.dobromat.title3')}
        </Typography>

        <Typography variant="body1" gutterBottom>
          {t('pages.dobromat.description4')}
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <a href="https://dobromat.sk/" target="_blank" rel="noreferrer">
            <img
              src="https://inex.sk/system/tinymce/images/original/dobromat.png?1549971597"
              loading="lazy"
              alt="Dobromat"
              style={{ maxWidth: 300 }}
            />
          </a>
        </Box>
      </Box>
    </Grid>
  )
}

export default DobromatContent
