import ContentContainer from '../components/ui/ContentContainer'
import FAQComponent, { FAQMenu } from '../components/FAQ'
import { Grid } from '@mui/material'
import { useState } from 'react'
import Breadcrumbs from '../components/Breadcrumbs'
import { muiTheme } from '../theme'

const FAQ = () => {
  const [filter, setFilter] = useState<string>('')

  return (
    <div style={{ width: '100%' }}>
      <ContentContainer background={muiTheme.palette.background.paper}>
        <Breadcrumbs />
        <Grid container sx={{ minHeight: 450, paddingTop: 4, marginBottom: 8 }}>
          <Grid item sm={12} md={8}>
            <FAQComponent filter={filter} />
          </Grid>
          <Grid item sm={12} md={4}>
            <FAQMenu filter={filter} setFilter={setFilter} />
          </Grid>
        </Grid>
      </ContentContainer>
    </div>
  )
}

export default FAQ
