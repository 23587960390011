import { Grid, Typography } from '@mui/material'
import { useCallback, useState } from 'react'
import styled from 'styled-components'
import {
  BASIC_INFO_FIELDS,
  SHORT_WORKCAMPS_BASIC_INFO_FIELDS,
  WORKCAMPS_BASIC_INFO_FIELDS,
} from '../../contexts/EventApplicationContextProvider'
import useEventApplicationContext from '../../contexts/useEventApplicationContext'
import { ApplicationType, EducationType, EmploymentType, Sex } from '../../types/application'
import useTranslate from '../../utils/translations/useTranslate'
import Button from '../ui/Button'
import DatePicker from '../ui/DatePicker'
import { Select } from '../ui/Select'
import TextInput from '../ui/TextInput'

type Props = {
  goToPreviousFormPage: () => void
  goToNextFormPage: () => void
}

const Wrapper = styled.div`
  padding: 40px 0 60px 0;
  width: 100%;
  position: relative;
`

const ActionButtons = styled.div`
  display: flex;
  padding: 10px 0;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
`

const BasicInfo = ({ goToNextFormPage, goToPreviousFormPage }: Props) => {
  const { t } = useTranslate()
  const {
    eventsCount,
    applicationData,
    applicationType,
    setApplicationData,
    validate,
    isFieldVisible,
  } = useEventApplicationContext()
  const [errors, setErrors] = useState<Record<string, string>>({})

  // Validates input data for this step and propagates errors
  const validateBasicInfo = useCallback(() => {
    const validationErrors = validate()
    const thisStepErrors: Record<string, string> = {}

    BASIC_INFO_FIELDS.forEach((field) => {
      if (validationErrors[field]) {
        thisStepErrors[field] = validationErrors[field][0]
      }
    })
    if (applicationType !== ApplicationType.BASIC) {
      SHORT_WORKCAMPS_BASIC_INFO_FIELDS.forEach((field) => {
        if (validationErrors[field]) {
          thisStepErrors[field] = validationErrors[field][0]
        }
      })
      if (applicationType !== ApplicationType.SHORT) {
        WORKCAMPS_BASIC_INFO_FIELDS.forEach((field) => {
          if (validationErrors[field]) {
            thisStepErrors[field] = validationErrors[field][0]
          }
        })
      }
    }

    setErrors(thisStepErrors)
    return Boolean(Object.keys(thisStepErrors).length)
  }, [validate, applicationType])

  if (!eventsCount || !applicationType) {
    return (
      <Wrapper>
        <Typography variant="h4" gutterBottom>
          {t('pages.applications.basicInfo.title')}
        </Typography>
        <Typography>{t('pages.applications.myBag.empty')}</Typography>
        <ActionButtons>
          <Button href="/events">{t('pages.applications.myBag.activitiesLink')}</Button>
        </ActionButtons>
      </Wrapper>
    )
  }
  return (
    <Wrapper>
      <Typography variant="h4" gutterBottom>
        {t('pages.applications.basicInfo.title')}
      </Typography>
      <Grid container spacing={2}>
        <Grid container item spacing={2}>
          <Grid item sm={4}>
            {isFieldVisible('name') && (
              <TextInput
                type="input"
                label={t('pages.applications.basicInfo.name')}
                value={applicationData.name}
                onChange={(e) => {
                  setErrors(({ name, ...newErrors }) => newErrors)
                  setApplicationData.name((e.target as HTMLInputElement).value)
                }}
                required
                error={t(errors['name'])}
              />
            )}
          </Grid>
          <Grid item sm={4}>
            {isFieldVisible('surname') && (
              <TextInput
                type="input"
                label={t('pages.applications.basicInfo.surname')}
                value={applicationData.surname}
                onChange={(e) => {
                  setErrors(({ surname, ...newErrors }) => newErrors)
                  setApplicationData.surname((e.target as HTMLInputElement).value)
                }}
                required
                error={t(errors['surname'])}
              />
            )}
          </Grid>
        </Grid>

        <Grid container item spacing={2}>
          {isFieldVisible('birthDate') && (
            <Grid item sm={4}>
              <DatePicker
                label={t('pages.applications.basicInfo.birthDate')}
                value={applicationData.birthDate}
                onChange={(e) => {
                  setErrors(({ birthDate, ...newErrors }) => newErrors)
                  setApplicationData.birthDate(e as string)
                }}
                required
                error={t(errors['birthDate'])}
              />
            </Grid>
          )}
          {isFieldVisible('birthPlace') && (
            <Grid item sm={4}>
              <TextInput
                type="input"
                label={t('pages.applications.basicInfo.birthPlace')}
                value={applicationData.birthPlace}
                onChange={(e) => {
                  setErrors(({ birthPlace, ...newErrors }) => newErrors)
                  setApplicationData.birthPlace((e.target as HTMLInputElement).value)
                }}
                required
                error={t(errors['birthPlace'])}
              />
            </Grid>
          )}
          {isFieldVisible('nationality') && (
            <Grid item sm={4}>
              <TextInput
                type="input"
                label={t('pages.applications.basicInfo.nationality')}
                value={applicationData.nationality}
                onChange={(e) => {
                  setErrors(({ nationality, ...newErrors }) => newErrors)
                  setApplicationData.nationality((e.target as HTMLInputElement).value)
                }}
                required
                error={t(errors['nationality'])}
              />
            </Grid>
          )}
        </Grid>

        <Grid container item spacing={2}>
          {isFieldVisible('sex') && (
            <Grid item sm={4}>
              <Select
                options={[
                  { id: Sex.MAN, name: t('pages.applications.basicInfo.sex.man') },
                  { id: Sex.WOMAN, name: t('pages.applications.basicInfo.sex.woman') },
                ]}
                value={applicationData.sex}
                onChange={(e) => {
                  setErrors(({ sex, ...newErrors }) => newErrors)
                  setApplicationData.sex((e.target as HTMLInputElement).value)
                }}
                required
                error={t(errors['sex'])}
              >
                {t('pages.applications.basicInfo.sex')}
              </Select>
            </Grid>
          )}
          {isFieldVisible('employmentType') && (
            <Grid item sm={4}>
              <Select
                options={[
                  {
                    id: EmploymentType.STUDENT,
                    name: t('pages.applications.basicInfo.employmentType.Student'),
                  },
                  {
                    id: EmploymentType.EMPLOYED,
                    name: t('pages.applications.basicInfo.employmentType.Employed'),
                  },
                  {
                    id: EmploymentType.UNEMPLOYED,
                    name: t('pages.applications.basicInfo.employmentType.Unemployed'),
                  },
                  {
                    id: EmploymentType.OTHER,
                    name: t('pages.applications.basicInfo.employmentType.Other'),
                  },
                ]}
                value={applicationData.employmentType}
                onChange={(e) => {
                  setErrors(({ employmentType, ...newErrors }) => newErrors)
                  setApplicationData.employmentType((e.target as HTMLInputElement).value)
                }}
                required
                error={t(errors['employmentType'])}
              >
                {t('pages.applications.basicInfo.employmentType')}
              </Select>
            </Grid>
          )}
          {isFieldVisible('educationType') && (
            <Grid item sm={4}>
              <Select
                options={[
                  {
                    id: EducationType.ELEMENTARY,
                    name: t('pages.applications.basicInfo.educationType.elementary'),
                  },
                  {
                    id: EducationType.VOCATIONAL,
                    name: t('pages.applications.basicInfo.educationType.vocational'),
                  },
                  {
                    id: EducationType.VOCATIONAL_GRADUATION,
                    name: t('pages.applications.basicInfo.educationType.vocationalGraduation'),
                  },
                  {
                    id: EducationType.HIGHSCHOOL,
                    name: t('pages.applications.basicInfo.educationType.highschool'),
                  },
                  {
                    id: EducationType.HIGHSCHOOL_GRADUATION,
                    name: t('pages.applications.basicInfo.educationType.highschoolGraduation'),
                  },
                  {
                    id: EducationType.HIGHSCHOOL_SPECIALIZED_GRADUATION,
                    name: t(
                      'pages.applications.basicInfo.educationType.highschoolSpecializedGraduation'
                    ),
                  },
                  {
                    id: EducationType.HIGHER_SPECIALIZED,
                    name: t('pages.applications.basicInfo.educationType.higherSpecialized'),
                  },
                  {
                    id: EducationType.UNIVERSITY_BACHELOR,
                    name: t('pages.applications.basicInfo.educationType.universityBachelor'),
                  },
                  {
                    id: EducationType.UNIVERSITY_MASTERS,
                    name: t('pages.applications.basicInfo.educationType.universityMasters'),
                  },
                  {
                    id: EducationType.UNIVERSITY_POSTGRAD,
                    name: t('pages.applications.basicInfo.educationType.universityPostgrad'),
                  },
                ]}
                value={applicationData.educationType}
                onChange={(e) => {
                  setErrors(({ educationType, ...newErrors }) => newErrors)
                  setApplicationData.educationType((e.target as HTMLInputElement).value)
                }}
                required
                error={t(errors['educationType'])}
              >
                {t('pages.applications.basicInfo.educationType')}
              </Select>
            </Grid>
          )}
        </Grid>

        {isFieldVisible('permanentAddress') && (
          <Grid container item spacing={2} alignItems="flex-end">
            <Grid item sm={4}>
              <Typography variant="h6" gutterBottom>
                {t('pages.applications.basicInfo.permanentAddress')}
              </Typography>
              <TextInput
                type="input"
                label={t('pages.applications.basicInfo.permanentAddress.streetWithNumber')}
                value={applicationData.permanentAddress?.streetWithNumber}
                onChange={(e) =>
                  setApplicationData.permanentAddress.streetWithNumber(
                    (e.target as HTMLInputElement).value
                  )
                }
                required
              />
            </Grid>
            <Grid item sm={2}>
              <TextInput
                type="input"
                label={t('pages.applications.basicInfo.permanentAddress.postalCode')}
                value={applicationData.permanentAddress?.zip}
                onChange={(e) =>
                  setApplicationData.permanentAddress.zip((e.target as HTMLInputElement).value)
                }
                required
              />
            </Grid>
            <Grid item sm={3}>
              <TextInput
                type="input"
                label={t('pages.applications.basicInfo.permanentAddress.city')}
                value={applicationData.permanentAddress?.city}
                onChange={(e) =>
                  setApplicationData.permanentAddress.city((e.target as HTMLInputElement).value)
                }
                required
              />
            </Grid>
            <Grid item sm={3}>
              <TextInput
                type="input"
                label={t('pages.applications.basicInfo.permanentAddress.country')}
                value={applicationData.permanentAddress?.country}
                onChange={(e) =>
                  setApplicationData.permanentAddress.country((e.target as HTMLInputElement).value)
                }
                required
              />
            </Grid>
          </Grid>
        )}

        {isFieldVisible('contactAddress') && (
          <Grid container item spacing={2} alignItems="flex-end">
            <Grid item sm={4}>
              <Typography variant="h6" gutterBottom>
                {t('pages.applications.basicInfo.contactAddress')}
              </Typography>

              <TextInput
                type="input"
                label={t('pages.applications.basicInfo.contactAddress.streetWithNumber')}
                value={applicationData.contactAddress?.streetWithNumber}
                onChange={(e) =>
                  setApplicationData.contactAddress.streetWithNumber(
                    (e.target as HTMLInputElement).value
                  )
                }
                required
              />
            </Grid>
            <Grid item sm={2}>
              <TextInput
                type="input"
                label={t('pages.applications.basicInfo.contactAddress.postalCode')}
                value={applicationData.contactAddress?.zip}
                onChange={(e) =>
                  setApplicationData.contactAddress.zip((e.target as HTMLInputElement).value)
                }
                required
              />
            </Grid>
            <Grid item sm={3}>
              <TextInput
                type="input"
                label={t('pages.applications.basicInfo.contactAddress.city')}
                value={applicationData.contactAddress?.city}
                onChange={(e) =>
                  setApplicationData.contactAddress.city((e.target as HTMLInputElement).value)
                }
                required
              />
            </Grid>
            <Grid item sm={3}>
              <TextInput
                type="input"
                label={t('pages.applications.basicInfo.contactAddress.country')}
                value={applicationData.contactAddress?.country}
                onChange={(e) =>
                  setApplicationData.contactAddress.country((e.target as HTMLInputElement).value)
                }
                required
              />
            </Grid>
          </Grid>
        )}

        <Grid container item spacing={2} alignItems="flex-end">
          {isFieldVisible('email') && (
            <Grid item sm={6}>
              <Typography variant="h6" gutterBottom>
                {t('pages.applications.basicInfo.contacts')}
              </Typography>
              <TextInput
                type="email"
                label={t('pages.applications.basicInfo.contacts.email')}
                value={applicationData.email}
                onChange={(e) => {
                  setErrors(({ email, ...newErrors }) => newErrors)
                  setApplicationData.email((e.target as HTMLInputElement).value)
                }}
                required
                error={t(errors['email'])}
              />
            </Grid>
          )}
          {isFieldVisible('phone') && (
            <Grid item sm={6}>
              <TextInput
                type="tel"
                label={t('pages.applications.basicInfo.contacts.phone')}
                value={applicationData.phone}
                onChange={(e) => {
                  setErrors(({ phone, ...newErrors }) => newErrors)
                  setApplicationData.phone((e.target as HTMLInputElement).value)
                }}
                required
                error={t(errors['phone'])}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <ActionButtons>
        <Button color="secondary" variant="outlined" onClick={goToPreviousFormPage}>
          {t('pages.applications.basicInfo.backAction')}
        </Button>
        <Button
          color="primary"
          onClick={() => {
            const hasErrors = validateBasicInfo()

            if (!hasErrors) {
              goToNextFormPage()
            }
          }}
        >
          {t('pages.applications.basicInfo.nextAction')}
        </Button>
      </ActionButtons>
    </Wrapper>
  )
}

export default BasicInfo
